import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';
import {
  IReferralApplyRequestModel,
  IReferrersRequestModel,
} from 'stores/ReferralStore.d';

export class ReferralApi {
  async getReferers(
    referrersRequestModel: IReferrersRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Referrers}?lineUserID=${referrersRequestModel.lineUserId}&referralCampaignID=${referrersRequestModel.referralCampaignId}`,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });

      throw resp;
    }
  }

  async createReferrers(
    referrersRequestModel: IReferrersRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        referrersRequestModel,
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Referrers}`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getReferralCampaign(referralCampaignId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ReferralCampaigns}/${referralCampaignId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });

      throw resp;
    }
  }

  async getPublicReferralCampaign() {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ReferralCampaigns}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });

      throw resp;
    }
  }

  async applyReferral(
    referralApplyRequestModel: IReferralApplyRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        referralApplyRequestModel,
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Referral}/apply`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new ReferralApi();
