import { ApiManager } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import config from 'config';
import { Api } from 'definitions/apiName';
import { CurrentLanguage } from 'common/components/Translate';

export class ResourceApi {
  apiManager;
  fileNameTermandcondition;

  constructor() {
    this.apiManager = new ApiManager(config.api.s3, 'NONE');
    this.fileNameTermandcondition = 'termandcondition_th.json';
    const lang = CurrentLanguage();
    if (lang === 'en') {
      this.fileNameTermandcondition = 'termandcondition_en.json';
    }
  }

  async getTermAndCondition(filename?: string) {
    try {
      const res = await this.apiManager.fetch({
        m: Http.GET,
        ep: `${Api.Resources}/${filename || this.fileNameTermandcondition}`,
        h: { 'Accept-Encoding': 'br' },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }
}

export default new ResourceApi();
