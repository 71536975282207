import React, { PureComponent, Fragment, Suspense, lazy } from 'react';
import cs from 'classnames';
import css from './loader.module.scss';
import animationCrop from './crop_loading.json';
import animationRun from './KT_run.json';
const WithLottie = lazy(() => import('common/components/LazyComp/WithLottie'));

export interface ILoaderProps {
  show: boolean;
  isCrop?: boolean;
  textCrop?: string;
  textCrop2?: string;
  textCrop3?: string;
  isLoaderOpacity?: any;
}

class Loader extends PureComponent<ILoaderProps> {
  state = {
    isJumping: false,
  };

  jumping = () => {
    const { isJumping } = this.state;
    if (!isJumping) {
      this.setState({ isJumping: true });
      setTimeout(() => {
        this.setState({ isJumping: false });
      }, 1000);
    }
  };

  render() {
    const { isJumping } = this.state;
    const loaderOverlayClasses = cs({
      [css.loaderOverlay]: true,
      [css.opacity]: this.props.isLoaderOpacity,
    });

    const jumpingStyle = cs({
      [css.khunthongContainer]: true,
      [css.jumping]: isJumping,
    });
    return (
      this.props.show && (
        <div id={'kth-run-loader'} className={css.loaderContainer}>
          <div className={loaderOverlayClasses} />
          <div className={css.centered}>
            {this.props.isCrop && (
              <Fragment>
                <Suspense fallback={null}>
                  <WithLottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationCrop,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={120}
                    width={120}
                    isStopped={false}
                    isPaused={false}
                  />
                </Suspense>
                <div className={css.description}>{this.props.textCrop}</div>
                <div className={css.subDescriptionContainer}>
                  <div className={css.subDescription}>
                    {this.props.textCrop2}
                  </div>
                  <div className={css.subDescription}>
                    {this.props.textCrop3}
                  </div>
                </div>
              </Fragment>
            )}
            {!this.props.isCrop && (
              <Fragment>
                <div className={jumpingStyle} onTouchStart={this.jumping}>
                  <Suspense fallback={null}>
                    <WithLottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationRun,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={180}
                      width={180}
                      isStopped={false}
                      isPaused={false}
                    />
                  </Suspense>
                </div>
                <div className={css.descriptionContainer}>
                  <div className={css.description}>{this.props.textCrop}</div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )
    );
  }
}

export default Loader;
