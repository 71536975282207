import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import RenderHTML from 'react-render-html';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { shortMonth, shortYear, strTime } from './DateTime';
import uuid from 'uuid';
import config from 'config';
import { Environment, Language, MobileOS } from 'definitions/constant';
import { IBankModel } from 'stores/BankStore.d';
import FrontendLogApi from 'api/FrontendLogApi';

export function consoleLog(...logs: any[]) {
  // tslint:disable-next-line
  console.log(...logs);
}

export function renderStringAsHtml(obj: any) {
  return RenderHTML(obj);
}

export function renderStringAsHTMLParser(strHtml: string, transform?: any) {
  const options = {} as any;
  if (transform) {
    options.transform = transform;
  }
  return ReactHtmlParser(strHtml, options);
}

export function isObjectEmpty(obj: any) {
  return isNil(obj) || isEmpty(obj);
}

export function isArrayEmpty(obj: any) {
  return !(obj && Array.isArray(obj) && obj.length);
}

export function roundNumberUp(num: any) {
  return Math.ceil(num);
}

export function roundNumberDown(num: any) {
  return Math.floor(num);
}

export function numberFormat(
  num: number,
  fractionDigits?: number,
  round?: string,
) {
  if (round === 'up') {
    num = roundNumberUp(parseFloat(num as any).toFixed(2));
  } else if (round === 'down') {
    num = roundNumberDown(parseFloat(num as any).toFixed(2));
  }

  let result: string;
  if (fractionDigits) {
    result = num?.toLocaleString(undefined, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  } else {
    result = num?.toLocaleString(undefined, { maximumFractionDigits: 0 });
  }

  if (result === '-0') {
    result = '0';
  }
  return result;
}

export function numberFormatIntOrFloat(n: number, round?: string) {
  let roundOption = 'up';
  if (round) {
    roundOption = round;
  }

  if (isInt(n)) {
    return numberFormat(n || 0, 0, roundOption);
  } else {
    return numberFormat(n || 0, 2);
  }
}

export function isNumber(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isInt(n: number) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0;
}

export function uniqReduce(arr: any[]) {
  const s = new Set(arr);
  const it = s.values();
  return Array.from(it);
}

export function numberWithCommas(num: number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function numberDecimalWithCommas(num: number) {
  //return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return num.toLocaleString('de-DE', { minimumFractionDigits: 2 });
}

export function formatBankNoX(bankNoStr: string) {
  let no = bankNoStr.replace(/\D/g, '');
  let match = undefined;
  switch (no?.length) {
    case 10:
      match = no.match(/^(\d{1,3})(\d{0,1})(\d{0,1})(\d{0,4})(\d{0,1})$/);
      if (match) {
        no = `xxx-x-x${match[4]}-x`;
      }
      break;
    case 12:
      match = no.match(/^(\d{1,3})(\d{0,1})(\d{0,1})(\d{0,4})(\d{0,3})$/);
      if (match) {
        no = `xxx-x-x${match[4]}-xxx`;
      }
      break;
    case 13:
      match = no.match(
        /^(\d{1,1})(\d{0,4})(\d{0,4})(\d{0,1})(\d{0,2})(\d{0,1})$/,
      );
      if (match) {
        no = `x-xxxx-xxxx${match[4]}-${match[5]}-${match[6]}`;
      }
      break;
  }

  return no;
}

export function formatBankNo(val: string) {
  if (Boolean(val)) {
    const first = val.substring(0, 3) || '';
    const second = val.substring(3, 4) || '';
    const thrid = val.substring(4, 9) || '';
    const fourth = val.substring(9, val.length) || '';

    return (
      first +
      (second !== '' ? '-' + second : '') +
      (thrid !== '' ? '-' + thrid : '') +
      (fourth !== '' ? '-' + fourth : '')
    );
  }
  return 'Not found';
}

export function formatPromptPay(val: string) {
  if (val.length === 13) {
    // x-xxxx-xxxxx-xx-x
    const arr = [
      val.substring(0, 1),
      val.substring(1, 5),
      val.substring(5, 10),
      val.substring(10, 12),
      val.substring(12, 13),
    ];
    return arr.join('-');
  } else if (val.length === 10) {
    // xxx-xxx-xxxx
    const arr = [
      val.substring(0, 3),
      val.substring(3, 6),
      val.substring(6, 10),
    ];
    return arr.join('-');
  }
  return val;
}

export function formatAccountName(accName: string) {
  const names = accName.split(' ').filter((item) => item.trim() !== '');

  if (!isArrayEmpty(names) && names.length > 1) {
    let lastname = names.pop();
    lastname = lastname.length > 1 ? lastname.slice(0, 1) : lastname;
    names.push(lastname);
    accName = names.join(' ');
  }
  return accName;
}

export function formatThaiDateTime(
  datetime: Date,
  lang: string,
  noSec?: boolean,
  noYear?: boolean,
) {
  const defaultDate = '2019-01-11T10:20:30Z';
  // Date format without second
  const year = moment(datetime || defaultDate).format('YYYY');
  const month = moment(datetime || defaultDate).format('MM');
  const date = moment(datetime || defaultDate).format(
    lang === Language.EN ? 'Do' : 'DD',
  );
  // Format 24H
  let time = strTime(datetime || defaultDate);

  if (noSec) {
    time = strTime(datetime || defaultDate, noSec);
    if (noYear) {
      return date + ' ' + shortMonth(+month) + ', ' + time;
    }
    return (
      date + ' ' + shortMonth(+month) + ' ' + shortYear(+year) + ', ' + time
    );
  } else {
    return (
      date + ' ' + shortMonth(+month) + ' ' + shortYear(+year) + ', ' + time
    );
  }
}

export function formatShortThaiDate(
  datetime: string | Date,
  lang: string,
  noYear?: boolean,
) {
  const defaultDate = '2019-01-11T10:20:30Z';

  const year = moment(datetime || defaultDate).format('YYYY');
  const month = moment(datetime || defaultDate).format('MM');
  const date = moment(datetime || defaultDate).format(
    lang === Language.EN ? 'Do' : 'DD',
  );

  if (noYear) {
    return date + ' ' + shortMonth(+month);
  }
  return date + ' ' + shortMonth(+month) + ' ' + shortYear(+year);
}

export const formatAsPem = (str) => {
  let finalString = '-----BEGIN PUBLIC KEY-----\n';
  while (str.length > 0) {
    finalString += str.substring(0, 64) + '\n';
    str = str.substring(64);
  }
  finalString = finalString + '-----END PUBLIC KEY-----';
  return finalString;
};

export function str2ab(message: string) {
  const buffer = new ArrayBuffer(message.length);
  const bufferView = new Uint8Array(buffer);
  const len = message.length;
  for (let i = 0, strLen = len; i < strLen; i++) {
    bufferView[i] = message.charCodeAt(i);
  }
  return buffer;
}

export function ab2str(buf: any) {
  //@ts-ignore
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

export function arrayBufferToString(buffer: any) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return binary;
}

export function arrayBufferToHex(buffer: any) {
  let s = '';
  const h = '0123456789abcdef';
  new Uint8Array(buffer).forEach((v) => {
    // tslint:disable-next-line
    s += h[v >> 4] + h[v & 15];
  });
  return s;
}

export const bufferToHex = (buffer) => {
  let s = '';
  const h = '0123456789abcdef';
  new Uint8Array(buffer).forEach((v) => {
    // tslint:disable-next-line
    s += h[v >> 4] + h[v & 15];
  });
  return s;
};
export const hexTobuffer = (hex) => {
  return new Uint8Array(
    hex.match(/[\da-f]{2}/gi).map((h) => {
      return parseInt(h, 16);
    }),
  );
};

/**
 * Partially from
 * JavaScript Client Detection
 * (C) viazenetti GmbH (Christian Ludwig)
 */
export const getOSAndVersion = () => {
  let os;
  let osVersion;

  const nVer = navigator.appVersion;
  const nAgt = navigator.userAgent;

  // system
  let clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Chrome OS', r: /CrOS/ },
    { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];

  const filteredCs = clientStrings.find((cs) => cs.r.test(nAgt));
  const filteredOS = filteredCs ? filteredCs.s : '';
  if (filteredOS) {
    os = filteredOS;
  }

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'Mac OS':
    case 'Mac OS X':
    case 'Android':
      osVersion =
        /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
          nAgt,
        )[1];
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] || 0);
      break;
    default:
      console.log('OS NOT detected');
  }

  return { os, version: osVersion } as any;
};

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return MobileOS.Windows;
  }

  if (/android/i.test(userAgent)) {
    return MobileOS.Android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return MobileOS.IOS;
  }

  return 'unknown';
}

export function generateUUID() {
  return uuid.v4();
}

export function setLocalStoreDevice(
  onSuccess: () => void,
  onError: () => void,
) {
  if (typeof Storage !== 'undefined') {
    const storageKey = getStorageKey();
    const UUID = generateUUID();

    localStorage.setItem(storageKey, UUID);
    setCookies(storageKey, UUID, getLongestExpireDate());

    if (Boolean(onSuccess)) {
      onSuccess();
    }
  } else {
    // Sorry! No Web Storage support..
    console.error('local store error;');

    if (Boolean(onError)) {
      onError();
    }
  }
}

export function getDeviceID(
  onSuccess: (deviceId: string) => void,
  onError: () => void,
) {
  if (typeof Storage !== 'undefined') {
    const storageKey = getStorageKey();
    const deviceId = localStorage.getItem(storageKey) || getCookies(storageKey);

    if (Boolean(onSuccess)) {
      onSuccess(deviceId);
    }

    return deviceId;
  } else {
    // Sorry! No Web Storage support..
    console.error('local store error;');

    if (Boolean(onError)) {
      onError();
    }
  }
}

export function getDeviceIdAndHandleCookies(
  onSuccess: (deviceId: string) => void,
  onError: () => void,
  lineUserId: string,
) {
  const deviceId = getDeviceID((deviceId: string) => {
    onSuccess(deviceId);
  }, onError);

  // if localStorage || cookies
  if (deviceId) {
    const storageKey = getStorageKey();

    // set localStorage from cookies
    if (!localStorage.getItem(storageKey)) {
      localStorage.setItem(storageKey, deviceId);
      // need to set cookies for renew expire date
      setCookies(storageKey, deviceId, getLongestExpireDate());

      FrontendLogApi.LogFrontend({
        lineUserId: lineUserId,
        message: `set localStorage from cookies: ${Boolean(deviceId)}`,
      });
    }

    // set cookies from localStorage
    if (!getCookies(storageKey)) {
      setCookies(storageKey, deviceId, getLongestExpireDate());

      FrontendLogApi.LogFrontend({
        lineUserId: lineUserId,
        message: `set cookies from localStorage ${Boolean(deviceId)}`,
      });
    }
  }

  return deviceId;
}

export function getStorageKey() {
  return config.env === Environment.PROD
    ? `khunthong_deviceId`
    : `khunthong_deviceId_${config.env}`;
}

export function checkIdCard(id: string) {
  if (id.length !== 13) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseFloat(id.charAt(i)) * (13 - i);
  }
  if ((11 - (sum % 11)) % 10 !== parseFloat(id.charAt(12))) {
    return false;
  }
  return true;
}

function hex(value) {
  return Math.floor(value).toString(16);
}

export function objectId() {
  return (
    hex(Date.now() / 1000) +
    ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16))
  );
}

export const getIsPromptPay = (recvProxyType: string) => {
  return [
    'NATID' /* National ID */,
    'MSISDN' /* Mobile Number */,
    'EWALLETID' /* E-Wallet */,
    'EMAIL' /* E-Mail */,
    'BILLERID' /* Biller ID */,
  ].some((item) => item === recvProxyType);
};

export const getBankLogo = (bankList: IBankModel[], bankcode: string) => {
  const receiveBank =
    !isArrayEmpty(bankList) &&
    bankList.find((bank) => bank.bankCode === bankcode);
  return receiveBank ? receiveBank.logo : '';
};

// getCurrentLocation is the promise wrapper for getCurrentPosition (callback style)
export const getCurrentLocation = (
  options?: PositionOptions,
): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  );
};

export const showAccountNo = (bankNo: string, isPrompPay: boolean) => {
  return isPrompPay ? formatPromptPay(bankNo) : formatBankNo(bankNo);
};

export const setCookies = (
  cookieName: string,
  cookieValue: string,
  daysToExpire: string,
) => {
  document.cookie =
    cookieName + '=' + cookieValue + '; expires=' + daysToExpire;
};

export const getCookies = (cookieName: string) => {
  var cookiesKey = cookieName + '=';
  // Splitting cookie
  var allCookieArray = document.cookie.split(';');
  for (var i = 0; i < allCookieArray.length; i++) {
    // Extracting cookiesKey and returning the same
    var temp = allCookieArray[i].trim();
    if (temp.indexOf(cookiesKey) === 0)
      return temp.substring(cookiesKey.length, temp.length);
  }

  // Else return empty string
  return '';
};

export const getLongestExpireDate = () => {
  // max expire date on chrome is 400 days
  // but on LINE Liff don't know
  return 'Fri, 31 Dec 9999 23:59:59 GMT';
};
