import Api from 'api/RedeemLocationCodeApi';
import { DefaultRedeemRadius } from 'definitions/constant';
import { observable, toJS } from 'mobx';
import { IRedeemCodeModel, IRedeemStore } from './RedeemStore.d';

export class RedeemStore implements IRedeemStore {
  @observable redeemCode: IRedeemCodeModel;

  constructor() {
    this.redeemCode = this.initRedeemCodeSchema() as any;
  }

  initRedeemCodeSchema() {
    return {
      id: '',
      name: '',
      codes: [],
      location: {
        type: '',
        coordinates: [],
      },
      radius: DefaultRedeemRadius, // 1000 m = 1 km
      locationLink: '',
      campaignId: '',
    };
  }

  async redeemLocationCode(
    code: string,
    lat: number,
    lon: number,
    lineUserId: string,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const result = await Api.redeemLocationCode(
        code,
        lat,
        lon,
        lineUserId,
        handleRetry,
      );
      return { isSuccess: true, message: { refNo: result.data.refNo } };
    } catch (ex: any) {
      return { isSuccess: false, message: ex.message };
    } finally {
    }
  }

  async getRedeemCode(code: string, handleRetry: (tag: string) => void) {
    try {
      const response = await Api.getRedeemCodeByCode(code, handleRetry);
      this.redeemCode = response.data || this.initRedeemCodeSchema();
    } catch (error) {
      this.redeemCode = this.initRedeemCodeSchema();
    }
  }

  getRedeemCodeToJs() {
    return toJS(this.redeemCode);
  }
}

const redeemStore = new RedeemStore();
export default redeemStore;
