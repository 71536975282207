import { Trans } from 'common/components/Translate';

export const getCurrentDayOfWeek = () => {
  return getDayOfWeek(new Date());
};

export const getDayOfWeek = (date: Date) => {
  var n = date.getDay();

  switch (n) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

export const getDayOfWeekEnTh = (date: Date) => {
  const weekDaysArr = [
    Trans('date.sunday'),
    Trans('date.monday'),
    Trans('date.tuesday'),
    Trans('date.wednesday'),
    Trans('date.thursday'),
    Trans('date.friday'),
    Trans('date.saturday'),
  ];

  return weekDaysArr[date.getDay()];
};
