import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import langEn from 'lang/en';
import langTh from 'lang/th';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    fallbackLng: 'th',
    ns: ['translation', 'common'],
    debug: false,
    resources: {
      en: {
        translation: langEn,
      },
      th: {
        translation: langTh,
      },
    },
    // backend: {
    //   loadPath: '/locales/{{lng}}/{{ns}}.json',
    // },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
