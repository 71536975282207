import { DurationType } from './../definitions/constant';
import { observable, toJS } from 'mobx';
import set from 'lodash/set';
import {
  IScheduleStore,
  IScheduleBillModel,
  IScheduleConfigsModel,
  IScheduleImagesModel,
} from './ScheduleStore.d';
import ScheduleApi from 'api/ScheduleApi';
import { Trans } from 'common/components/Translate';
import { ScheduleStatus, BillType } from 'definitions/constant';
import { consoleLog } from 'utils/Common';

export class ScheduleStore implements IScheduleStore {
  @observable scheduleConfigs: IScheduleConfigsModel[];
  @observable scheduleBill: IScheduleBillModel;
  @observable scheduleImages: IScheduleImagesModel[];
  @observable isDuplicateSchedule: boolean;
  @observable schedules: IScheduleBillModel[];
  isCheckDuplicateSchedule: boolean;

  constructor() {
    this.scheduleConfigs = [];
    this.scheduleBill = this.initScheduleBill();
    this.isDuplicateSchedule = false;
    this.isCheckDuplicateSchedule = false;
    this.schedules = [];
  }

  resetAllData() {
    this.scheduleConfigs = [];
    this.scheduleBill = this.initScheduleBill();
    this.scheduleImages = [] as IScheduleImagesModel[];
    this.isDuplicateSchedule = false;
    this.isCheckDuplicateSchedule = false;
  }

  resetSchedule() {
    this.scheduleBill = this.initScheduleBill();
  }

  getIsDuplicateScheduleJS() {
    return toJS(this.isDuplicateSchedule);
  }

  getScheduleConfigsJS() {
    return toJS(this.scheduleConfigs);
  }

  getScheduleBillJS() {
    return toJS(this.scheduleBill);
  }

  getScheduleImagesJS() {
    return this.scheduleImages;
  }

  getSchedulesJS() {
    return toJS(this.schedules);
  }

  setScheduleBill(key: string, val: any) {
    const scheduleBill = this.getScheduleBillJS();
    set(scheduleBill, key, val);
    this.scheduleBill = scheduleBill;
  }

  async getScheduleBillById(scheduleId: string) {
    try {
      const response = await ScheduleApi.getScheduleBillById(scheduleId);
      this.scheduleBill = response.data || this.initScheduleBill();
    } catch (error) {
      this.scheduleBill = this.initScheduleBill();
    }
  }

  async getScheduleBillSummaryById(scheduleId: string) {
    try {
      const response = await ScheduleApi.getScheduleBillSummaryById(scheduleId);
      this.scheduleBill = response.data || this.initScheduleBill();
    } catch (error) {
      this.scheduleBill = this.initScheduleBill();
    }
  }

  async getScheduleConfigs() {
    try {
      if (this.scheduleConfigs.length <= 0) {
        const result = await ScheduleApi.GetScheduleConfigs();
        this.scheduleConfigs = result.data || [];
      }
    } catch (error) {
      consoleLog('getScheduleConfigs error == > ', error);
      this.scheduleConfigs = [];
    }
  }

  async createSchedule() {
    try {
      const schedule = this.getScheduleBillJS();
      const result = await ScheduleApi.createSchedule(schedule);
      if (result && result.data && result.data.scheduleBillId) {
        return { isSuccess: true, message: '' };
      } else {
        throw new Error(Trans('message.responseMsg.saveFailed'));
      }
    } catch (error: any) {
      consoleLog('create schedule error: ', error.message);
      return { isSuccess: false, message: error.message };
    }
  }

  async updateSchedule() {
    try {
      const schedule = this.getScheduleBillJS();
      await ScheduleApi.updateSchedule(schedule, schedule.id);
      return true;
    } catch (error: any) {
      consoleLog('update schedule error: ', error.message);
      return false;
    }
  }

  async cancelScheduleBill() {
    try {
      const scheduleBill = this.getScheduleBillJS();
      await ScheduleApi.cancelSchedule(
        { Status: ScheduleStatus.Cancel },
        scheduleBill.id,
      );
      return { isSuccess: true, message: '' };
    } catch (error: any) {
      consoleLog('cancelScheduleBill error == > ', error);
      return { isSuccess: false, message: error.message };
    }
  }

  async getScheduleImages() {
    try {
      if (!this.scheduleImages || this.scheduleImages.length === 0) {
        const result = await ScheduleApi.GetScheduleImages();
        this.scheduleImages = result.data;
      }
    } catch (error) {
      consoleLog('getScheduleImages error == > ', error);
      throw error;
    }
  }

  async verifyDuplicateSchedule(lineGroupId: string, messageId: string) {
    try {
      if (!this.isCheckDuplicateSchedule) {
        const res = await ScheduleApi.verifyDuplicateSchedule(
          lineGroupId,
          messageId,
        );
        if (res && res.data && res.data.length > 0) {
          this.isDuplicateSchedule = true;
        } else {
          this.isDuplicateSchedule = false;
        }
      }
    } catch (ex: any) {
      consoleLog('catch exeption ', ex.message);
    }
    this.isCheckDuplicateSchedule = true;
  }

  getBillType = (billType: string, durationType?: string) => {
    if (durationType === DurationType.Weekly) {
      switch (billType) {
        case BillType.W1:
        case '1':
          return Trans('scheduleSummaryPage.billTypeW1');
        case BillType.W2:
        case '2':
          return Trans('scheduleSummaryPage.billTypeWeek', { number: 2 });
        default:
          return Trans('scheduleSummaryPage.billTypeW1');
      }
    }
    switch (billType) {
      case BillType.W1:
        return Trans('scheduleSummaryPage.billTypeW1');
      case BillType.W2:
        return Trans('scheduleSummaryPage.billTypeWeek', { number: 2 });
      case BillType.M1:
      case '1':
        return Trans('scheduleSummaryPage.billTypeM1');
      case BillType.M2:
      case '2':
        return Trans('scheduleSummaryPage.billTypeMonth', { number: 2 });
      case BillType.M3:
      case '3':
        return Trans('scheduleSummaryPage.billTypeMonth', { number: 3 });
      case BillType.M6:
      case '6':
        return Trans('scheduleSummaryPage.billTypeMonth', { number: 6 });
      case BillType.M12:
      case '12':
        return Trans('scheduleSummaryPage.billTypeM12');
      default:
        return Trans('scheduleSummaryPage.billTypeM1');
    }
  };

  getSchedules = async (lineGroupId: string) => {
    try {
      const response = await ScheduleApi.getSchedules(lineGroupId);
      this.schedules = response.data || [];
    } catch (error) {
      this.schedules = [];
    }
  };

  initScheduleBill() {
    return {
      id: '',
      name: '',
      lineGroupId: '',
      members: [],
      account: {
        no: '',
        name: '',
        isAccountPromptpay: false,
        bankCode: '',
      },
      total: 0,
      type: '',
      status: '',
      ownerMemberId: '',
      createdDate: undefined,
      updatedDate: undefined,
      collectType: '',
      imageId: '',
      image1Url: '',
      image2Url: '',
      image3Url: '',
      scheduleInfo: {
        dateOfMonth: undefined,
        hour: undefined,
        duration: 0,
        repeatTime: -1, // 1, 2, 3, 4, ..., -1 forever
        startDate: undefined,
        endDate: undefined,
        durationType: '',
      },
      runtimeInfo: {
        numberOfBillCreated: 0,
        lastTimeCreateBill: undefined,
        nextTimeToCreateBill: undefined,
        numberOfNotifyTime: 0,
        latestBillId: '',
      },
      messageId: '',
      fromPage: '',
      source: '',
    };
  }
}

export default new ScheduleStore();
