import { createTheme } from '@material-ui/core/styles';
import defaultTheme, {
  primaryThemeColor,
  lightGreyColor,
  primaryColor,
} from './default';

import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

type overridesNameToClassKey = {
  [P in keyof Required<MuiPickersOverrides>]: keyof MuiPickersOverrides[P];
};

type CustomType = {
  MuiPickersBasePicker: {
    pickerView: {
      maxWidth?: string;
    };
  };
};

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey extends overridesNameToClassKey { }
  export interface ComponentNameToClassKey extends CustomType { }
}

export const inputStyle = {
  underline: {
    '&:before': {
      borderBottom: `1px solid ${lightGreyColor}`,
    },
    '&:not($disabled):not($error):not($focused):before': {
      borderBottom: `1px solid ${lightGreyColor}`,
      '@media (hover: none)': {
        borderBottom: `1px solid ${lightGreyColor}`,
      },
    },
  },
  root: {
    padding: '6px 0 1px',
  },
};

export const MuiPickers = {
  MuiTypography: {
    fontFamily: ['Kurious_looped'],
    body1: {
      fontWeight: 600,
      color: '#4d4d4d',
    },
    body2: {
      // fontSize: '14px',
      fontWeight: 600,
      color: '#4d4d4d',
    },
  },
  MuiPickersToolbar: {
    fontFamily: ['Kurious_looped'],
    toolbar: {
      backgroundColor: primaryThemeColor,
    },
  },
  MuiPickersModalDialog: {
    fontFamily: ['Kurious_looped'],
    dialogAction: {
      fontWeight: 'bold',
      fontSize: '15px',
    },
  },
  MuiPickersDay: {
    fontFamily: ['Kurious_looped'],
    day: {
      color: '#4d4d4d',
      fontWeight: 500,
    },
    daySelected: {
      backgroundColor: primaryColor,
      color: '#ffffff',
    },
    dayDisabled: {
      color: lightGreyColor,
    },
  },
};

const THEME = createTheme({
  ...defaultTheme,
  overrides: {
    MuiDialog: {
      root: {
        borderRadius: '7px',
      },
      paper: {
        margin: '16px',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'space-around',
        '&:first-child': {
          color: lightGreyColor,
        },
      },
    },
    MuiButton: {
      textPrimary: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: primaryColor,
      },
      textSecondary: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: lightGreyColor,
      },
    },
    MuiInput: inputStyle,
    ...MuiPickers,
  },
});

export default THEME;
