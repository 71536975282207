import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { decryptAESJSONMessage, encryptAESMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';
import { IRequestGetExperimentPayload } from 'stores/AbTesting/AbTestingStore.d';
import { ILogUserActionsRequestBody } from 'stores/AbTesting/AbTestingStore.d';

export class AbTestingApi {
  async getExperimentById(
    id: string,
    timeout: number,
    data: IRequestGetExperimentPayload,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.AbTesting}/experiments/${id}/users`,
        b: dataEncrypt,
        timeout: timeout,
        ignoreError: true,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async logUserActions(
    expId: string,
    expVer: string,
    body: ILogUserActionsRequestBody,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(body, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.AbTesting}/eventlog/experiments/${expId}/version/${expVer}`,
        b: dataEncrypt,
        ignoreError: true,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new AbTestingApi();
