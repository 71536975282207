import React from 'react';
export const PrevLocationContext = React.createContext({
  prevLocation: [],
});

export default Component => (props: any) => (
  <PrevLocationContext.Consumer>
    {({ prevLocation }) => {
      return <Component {...props} prevLocation={prevLocation} />;
    }}
  </PrevLocationContext.Consumer>
);