import React, { Fragment } from 'react';
import cs from 'classnames';
import Button from '@material-ui/core/Button';
import css from './bottomBtn.module.scss';

export interface IBottonProp {
  btnIcon?: any;
  btnText?: string;
  btnStyle?: string; // primary , secondary ,disabled
  handleClick?: any;
  handleTouchEnd?: any;
  refer?: any;
}

interface IBottomBtnProp {
  backBtn?: IBottonProp;
  nextBtn?: IBottonProp;
  leftBtn?: IBottonProp;
  rightBtn?: IBottonProp;
  mainBtn?: IBottonProp;
  iconOnTheLeft?: boolean;
  classes?: any;
  rightImage?: any;
  stickyContent?: any;
  disableRipple?: boolean;
}

interface IBottomBtnState {}

export class BottomBtn extends React.Component<
  IBottomBtnProp,
  IBottomBtnState
> {
  renderDataInBtn = (btnInfo, type) => {
    if (type === 'nextButton' || type === 'mainButton') {
      if (this.props.iconOnTheLeft) {
        return (
          <Fragment>
            {btnInfo.btnIcon && btnInfo.btnIcon} &nbsp;{' '}
            {btnInfo.btnText && btnInfo.btnText}
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {btnInfo.btnText && btnInfo.btnText} &nbsp;
            {btnInfo.btnIcon && btnInfo.btnIcon}
          </Fragment>
        );
      }
    } else if (type === 'backButton') {
      return (
        <Fragment>
          {btnInfo.btnIcon && btnInfo.btnIcon} &nbsp;
          {btnInfo.btnText && btnInfo.btnText}
        </Fragment>
      );
    } else if (type === 'rightButton') {
      return (
        <Fragment>
          {btnInfo.btnIcon && btnInfo.btnIcon} &nbsp;
          {btnInfo.btnText && btnInfo.btnText}
        </Fragment>
      );
    } else if (type === 'leftButton') {
      return (
        <Fragment>
          {btnInfo.btnIcon && btnInfo.btnIcon} &nbsp;
          {btnInfo.btnText && btnInfo.btnText}
        </Fragment>
      );
    }
  };

  convertTypeToElementId = (type) => {
    switch (type) {
      case 'mainButton':
        return 'button-main';
      case 'nextButton':
        return 'button-next';
      case 'backButton':
        return 'button-back';
      case 'rightButton':
        return 'button-right';
      case 'leftButton':
        return 'button-left';
      default:
        return type;
    }
  };

  renderBtn = (btnInfo, type) => {
    const { btnStyle, handleClick, handleTouchEnd, refer } = btnInfo;
    const csBtnType = cs(type, {
      [css.mainButton]: type === 'mainButton',
      [css.nextButton]: type === 'nextButton',
      [css.backButton]: type === 'backButton',
      [css.rightButton]: type === 'rightButton',
      [css.leftButton]: type === 'leftButton',
    });

    const csBtnStyle = cs(btnStyle, {
      [css.default]: btnStyle === undefined,
      [css.primary]: btnStyle === 'primary',
      [css.primaryOutlined]: btnStyle === 'primaryOutlined',
      [css.secondary]: btnStyle === 'secondary',
      [css.disabled]: btnStyle === 'disabled',
      [css.disabled]: btnStyle === 'disabledCanClick',
      [css.noBankAccount]: btnStyle === 'noBankAccount',
    });

    const elementId = this.convertTypeToElementId(type);
    const dataInbtn = this.renderDataInBtn(btnInfo, type);

    return (
      <div ref={refer}>
        <Button
          id={elementId}
          variant="contained"
          className={`${css.button} ${csBtnType} ${csBtnStyle}`}
          disabled={btnStyle === 'disabled'}
          onTouchStart={handleClick && (() => handleClick())}
          onTouchEnd={(e) => {
            handleTouchEnd && handleTouchEnd();
            e.preventDefault();
          }}
          disableRipple={this.props.disableRipple}
        >
          {dataInbtn}
        </Button>
      </div>
    );
  };

  renderImage(Image: any) {
    return <div className={css.rightImage}>{Image}</div>;
  }

  render() {
    const {
      backBtn,
      nextBtn,
      mainBtn,
      classes,
      rightImage,
      leftBtn,
      rightBtn,
      stickyContent,
    } = this.props;
    return (
      <div className={`${css.bottomBtn} ${classes && classes}`}>
        {stickyContent && <div className={css.stickyWrap}>{stickyContent}</div>}
        <div className={css.bottonWrap}>
          {backBtn && nextBtn && (
            <React.Fragment>
              {this.renderBtn(backBtn, 'backButton')}
              {this.renderBtn(nextBtn, 'nextButton')}
            </React.Fragment>
          )}

          {leftBtn && rightBtn && (
            <React.Fragment>
              {this.renderBtn(leftBtn, 'leftButton')}
              {this.renderBtn(rightBtn, 'rightButton')}
            </React.Fragment>
          )}

          {backBtn && rightImage && (
            <React.Fragment>
              {this.renderBtn(backBtn, 'backButton')}
              {this.renderImage(rightImage)}
            </React.Fragment>
          )}

          {backBtn && !nextBtn && !mainBtn && (
            <React.Fragment>
              {this.renderBtn(backBtn, 'backButton')}
            </React.Fragment>
          )}

          {mainBtn && this.renderBtn(mainBtn, 'mainButton')}
        </div>
      </div>
    );
  }
}

export default BottomBtn;
