import { observable, toJS } from 'mobx';
import Api from 'api/MemberApi';
import KPlusApi from 'api/KPlusApi';
import set from 'lodash/set';
import {
  IMemberStore,
  IMemberModel,
  IMemberUpdateModel,
  IMemberValidateReceivingAccount,
  IConsents,
} from './MemberStore.d';
import lineHelper from 'utils/LineHelper';

export class MemberStore implements IMemberStore {
  @observable memberInfo: IMemberModel;
  @observable isLoading: boolean;
  @observable isAll: string;
  @observable isCheckLocalStore: boolean;
  @observable memberValidateRecAcc: IMemberValidateReceivingAccount;
  @observable memberShortDetail: any;

  constructor() {
    this.memberInfo = this.initSchema() as any;
    this.isLoading = false;
    this.isAll = undefined;
    this.isCheckLocalStore = false;
    this.memberValidateRecAcc = this.initSchemaValidateRecAcc() as any;
  }

  resetInfo() {
    this.memberInfo = {} as any;
    this.isLoading = false;
    this.isAll = undefined;
  }

  resetAllInfo() {
    this.memberInfo = this.initSchema() as any;
    this.isLoading = false;
    this.isAll = undefined;
    this.isCheckLocalStore = false;
    this.memberValidateRecAcc = this.initSchemaValidateRecAcc() as any;
    this.memberShortDetail = {};
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsAll(isAll: string) {
    this.isAll = isAll;
  }

  setIsCheckLocalStore(isCheck: boolean) {
    this.isCheckLocalStore = isCheck;
  }

  setInfo(key: string, val: any) {
    const info = this.getMemberJs();
    set(info, key, val);
    this.memberInfo = info;
  }

  getLoading() {
    return toJS(this.isLoading);
  }

  getIsAll() {
    return toJS(this.isAll);
  }

  getIsCheckLocalStore() {
    return toJS(this.isCheckLocalStore);
  }

  getMemberJs() {
    return this.memberInfo;
  }

  initSchema() {
    return {
      id: '',
      insertedId: '',
      name: '',
      lineUserId: '',
      image: '',
      isPvtUser: false,
      accounts: [],
      kplusInfo: {},
      isAcceptedConsents: true,
      consents: {
        analysis: false,
        marketing: false,
        thirdParty: false,
      },
    };
  }

  initSchemaValidateRecAcc() {
    return {
      isDifferent: false,
      memberId: '',
      accountNo: '',
      accountName: '',
      bankCode: '',
      isSecondTime: false,
    };
  }

  async getMember(id: string, handleRetry: (tag: string) => void) {
    this.isLoading = true;
    try {
      const result = await Api.getMember(id, handleRetry);
      this.memberInfo = result.data || this.initSchema();
    } catch (error) {
      this.memberInfo = this.initSchema();
    } finally {
      this.isLoading = false;
    }
  }

  async getMemberShortDetail(memberId: string, lineGroupId: string) {
    try {
      if (
        !this.memberShortDetail ||
        (this.memberShortDetail && this.memberShortDetail.iD !== memberId)
      ) {
        const result = await Api.getMemberShortDetail(memberId, lineGroupId);
        this.memberShortDetail = result.data;
        return result.data || this.initSchema();
      } else {
        return this.memberShortDetail;
      }
    } catch (error) {
      return this.initSchema();
    }
  }

  async getMemberByLineId(
    lineUserId: string,
    handleRetry: (tag: string) => void,
    forceGet?: boolean,
  ) {
    this.isLoading = true;
    try {
      if (
        forceGet ||
        !this.memberInfo.id ||
        this.memberInfo.lineUserId !== lineUserId
      ) {
        const result = await Api.getMemberByLineUserId(lineUserId, handleRetry);
        this.memberInfo = result.data || this.initSchema();
      }
    } catch (error) {
      this.memberInfo = this.initSchema();
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async saveMember(
    isUpdateReceivingAccount: boolean,
    isUpdateKPlus: boolean,
    handleRetry: (tag: string) => void,
  ) {
    this.isLoading = true;
    try {
      const info = this.getMemberJs();
      if (info.id) {
        const memUpdate: IMemberUpdateModel = {
          member: info,
          isUpdateKplusInfo: isUpdateKPlus,
          isUpdateAccount: isUpdateReceivingAccount,
        };
        await Api.updateMember(memUpdate, info.id);
      } else {
        const lineProfile = await lineHelper.getProfile();
        info.image = lineProfile.pictureUrl;
        info.lineUserId = lineProfile.userId;
        info.name = lineProfile.displayName;
        info.isAcceptedOcr = false;
        await Api.createMember(info);
        await this.getMemberByLineId(lineProfile.userId, handleRetry);
      }
      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async createMember() {
    this.isLoading = true;
    try {
      const info = this.getMemberJs();
      const lineProfile = await lineHelper.getProfile();
      info.image = lineProfile.pictureUrl;
      info.lineUserId = lineProfile.userId;
      info.name = lineProfile.displayName;
      info.isAcceptedOcr = false;

      await Api.createMember(info);
      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async verifyKplusAccount(accountNo: string) {
    this.isLoading = true;
    try {
      const result = await KPlusApi.inquiryName(accountNo);
      return toJS(result.data);
    } catch (err) {
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteMemberKPlusAccount(handleRetry: (tag: string) => void) {
    this.isLoading = true;
    try {
      const memberInfo = this.getMemberJs();
      const response = await Api.deleteMemberKPlusAccount(
        memberInfo.lineUserId,
        handleRetry,
      );
      if (!response && response.data) {
        return 0;
      }
      return true;
    } catch (error) {
      // this.errorMsg = error.message;
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async saveMemberDeviceId(
    isClearKpls: boolean = false,
    handleRetry: (tag: string) => void,
  ) {
    this.isLoading = true;
    try {
      const info = this.getMemberJs();

      if (info.id) {
        await Api.updateMemberDeviceId(info.id, info.deviceId, isClearKpls);
      } else {
        const lineProfile = await lineHelper.getProfile();
        info.image = lineProfile.pictureUrl;
        info.lineUserId = lineProfile.userId;
        info.name = lineProfile.displayName;
        info.isAcceptedOcr = false;

        const result = await Api.createMember(info);
        if (result && result.insertedId) {
          this.setInfo('id', result.insertedId);
        }
        await this.getMemberByLineId(lineProfile.userId, handleRetry);
      }
      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async validateReceivingAccount() {
    this.isLoading = true;
    try {
      const lineProfile = await lineHelper.getProfile();

      const result = await KPlusApi.validateReceivingAccount({
        lineUserId: lineProfile.userId,
      });
      this.memberValidateRecAcc = result.data;
      this.memberValidateRecAcc.isSecondTime = false;
      // this.memberValidateRecAcc = {
    } catch (error) {
      this.memberValidateRecAcc = this.initSchemaValidateRecAcc();
      // return toJS(this.memberValidateRecAcc);
    } finally {
      this.isLoading = false;
    }
  }

  getValidateRecAcc() {
    return toJS(this.memberValidateRecAcc);
  }

  setValidateInfo(key: string, val: any) {
    const info = this.getValidateRecAcc();
    set(info, key, val);
    this.memberValidateRecAcc = info;
  }

  async updateConsents(
    memberId: string,
    consents: IConsents,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const result = await Api.updateConsentsByMemberId(
        memberId,
        consents,
        handleRetry,
      );
      this.memberInfo = result.data || this.initSchema();
    } catch (error) {
      this.memberInfo = this.initSchema();
    }
  }

  async updateMemberSource(source: string) {
    try {
      console.log('updateMemberSource');
      const result = await Api.updateMemberSource(source);
      console.log('result.data ===>', result.data);
      return toJS(result.data);
    } catch (err) {
      console.log('err ===>', err);
      return false;
    }
  }
}

export default new MemberStore();
