import { getCookies, getLongestExpireDate, getStorageKey } from 'utils/Common';

const initStorageDebugger = function () {
  (window as any).sd = {
    getStorage: function () {
      function getDeviceIdFromCookies(key?: string) {
        return getCookies(key || getStorageKey());
      }

      function getDeviceIdFromLocalStorage(key?: string) {
        return window.localStorage.getItem(key || getStorageKey());
      }

      return {
        cookies: getDeviceIdFromCookies(),
        localStorage: getDeviceIdFromLocalStorage(),
      };
    },

    setCookies: function (cookieValue: any) {
      document.cookie =
        getStorageKey() +
        '=' +
        cookieValue +
        '; expires=' +
        getLongestExpireDate();
      return this.getStorage();
    },

    clearCookies: function () {
      document.cookie =
        getStorageKey() + '= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      return this.getStorage();
    },

    setLocalStorage: function (value: string) {
      localStorage.setItem(getStorageKey(), value);
      return this.getStorage();
    },

    clearLocalStorage: function () {
      localStorage.clear();
      return this.getStorage();
    },
  };
};

export default initStorageDebugger;
