import { Trans } from 'common/components/Translate';
import { BillItemType } from 'definitions/constant';
import Swal from 'common/components/Swal';
import { IBillModel } from 'stores/BillStore.d';
import { IGroupStore } from 'stores/GroupStore.d';
import { PageName } from 'definitions/pageName';

export const getBillItemType = (type: string) => {
  if (BillItemType.VAT === type) {
    return Trans('createBillPage.vat');
  } else if (BillItemType.Discount === type) {
    return Trans('createBillPage.discount');
  } else if (BillItemType.ServiceCharge === type) {
    return Trans('createBillPage.serviceCharge');
  } else {
    return '';
  }
};

export const getBillTotal = (billData: IBillModel) => {
  let newBillTotal = 0;

  billData.items.forEach((item) => {
    if (item.type === BillItemType.Discount) {
      newBillTotal -= isNaN(item.price) ? 0 : item.price;
    } else {
      newBillTotal += isNaN(item.price) ? 0 : item.price;
    }
  });

  return newBillTotal;
};

export const handleBillAlreadyExist = async (
  group: IGroupStore,
  maxBillsInGroup: number,
  handleReload: () => void,
) => {
  const groupinfo = group.getGroupJs();

  //  if (groupinfo.isMultiBillEnable) {
  await Swal.error({
    showIconKhunThong: true,
    showIconKey: 'KhunThongErrIcon',
    text: Trans('message.responseMsg.maxBillsInGroup', {
      maxBillsInGroup: maxBillsInGroup,
    }),
    confirmButtonText: Trans('message.common.btnOkay'),
  });
  // } else {
  //   const result = await Swal.question({
  //     showIconKhunThong: true,
  //     isConfirmDelete: false,
  //     showIconKey: 'KhunThongInfoIcon',
  //     text: Trans('createBillPage.alreadyHaveOneBillDoYouWantMore'),
  //     cancelButtonText: Trans('message.common.btnNo'),
  //     confirmButtonText: Trans('createBillPage.createMoreBill'),
  //   });

  //   if (result && result.value) {
  //     try {
  //       await group.enableMultipleBills(groupinfo.lineGroupId);
  //       handleReload();
  //     } catch {
  //       throw new Error(Trans('message.responseMsg.saveFailed'));
  //     }
  //   }
  // }
};

export const isFromMutipleBillFlow = (prevLocation: any, redirect: string) => {
  if (prevLocation && prevLocation.length > 0) {
    const len = prevLocation.length;
    const prevPage = prevLocation[len - 1];
    if (
      prevPage.pathname === PageName.BillSummary &&
      redirect !== PageName.BillSummary
    ) {
      return true;
    } else if (prevPage.pathname === PageName.CancelInfo) {
      return true;
    } else if (prevPage.pathname === PageName.BillCenter) {
      return true;
    }
  }
  return false;
};
