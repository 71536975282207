import localhost from './config.localhost';
import sit from './config.sit';
import uat from './config.uat';
import production from './config.production';

const setupConfig = (host: string) => {
  switch (true) {
    case host.includes('uatkhunthong.kasikornbank.com'):
      return uat;

    case host.includes('sitkhunthong.kasikornbank.com'):
      return sit;

    case host.includes('khunthong.kasikornbank.com'):
      return production;

    default:
      return localhost;
  }
};

export const config = setupConfig(window.location.host);

export default {
  ...config,
  publicUrl: process.env.PUBLIC_URL,
};
