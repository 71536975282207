import React, { Component, lazy } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import qs from 'qs';
import storage from 'store';
import { isObjectEmpty } from 'utils/Common';
import Page from './pages';
import { ChangeLanguage } from 'common/components/Translate';
import 'common/styles/global.scss';
import 'sweetalert2/src/sweetalert2.scss';
import { config } from 'config';
import { isCypressMode } from 'utils/Cypress';
import history from './utils/History';
import 'utils/VConsole';
import 'utils/StorageDebugger';
import { getErrorStatus } from 'utils/Session';
import MaintenanceApi from './api/MaintenanceApi';
import { PrevLocationContext } from 'common/hocs/withPrevLocation';
import { PageName } from 'definitions/pageName';
import Swal from 'common/components/Swal';
import { Environment } from 'definitions/constant';
import initStorageDebugger from 'utils/StorageDebugger';

interface IAppProps {
  location: any;
}

interface IAppState {
  prevLocation: any;
  isLoading: any;
  isInitFinished: boolean;
}

class App extends Component<IAppProps, IAppState> {
  state = {
    prevLocation: [],
    isLoading: false,
    isInitFinished: false,
  };

  ExamplePage = () => lazy(() => import('./pages/Example'));
  BillAssignPage = () => lazy(() => import('./pages/BillAssign'));
  CreateBillPage = () => lazy(() => import('./pages/CreateBill'));
  CreateBillOCRPage = () => lazy(() => import('./pages/CreateBillOCR'));
  CropImagePage = () => lazy(() => import('./pages/CropImage'));
  CropImageTutorialPage = () => lazy(() => import('./pages/CropImageTutorial'));
  InviteFriendPage = () => lazy(() => import('./pages/InviteFriend'));
  InviteMoreFriendPage = () => lazy(() => import('./pages/InviteMoreFriend'));
  PayTypePage = () => lazy(() => import('./pages/PayType'));
  PayTypeSelectedPage = () => lazy(() => import('./pages/PayTypeSelected'));
  AddBankAccountPage = () => lazy(() => import('./pages/AddBankAccount'));
  ConfirmAddAccountPage = () =>
    lazy(() => import('./pages/AddBankAccountConfirmation'));
  IFrameToKplusPage = () => lazy(() => import('./pages/IFrameToKplus'));
  LinkKplusCompletePage = () => lazy(() => import('./pages/LinkKplusComplete'));
  SelectBankPage = () => lazy(() => import('./pages/SelectBank'));
  BillInfoPage = () => lazy(() => import('./pages/BillInfo'));
  PaymentSuccessPage = () => lazy(() => import('./pages/PaymentSuccess'));
  PaymentSuccessSlipPage = () =>
    lazy(() => import('./pages/PaymentSuccessSlip'));
  AccountManagementPage = () => lazy(() => import('./pages/AccountManagement'));
  VerifyKPlusPage = () => lazy(() => import('./pages/VerifyKPlus'));
  VerifyKPlusRegisterPage = () =>
    lazy(() => import('./pages/VerifyKPlusRegister'));
  ErrorPage = () => lazy(() => import('./pages/Error'));
  TabletPage = () => lazy(() => import('./pages/Tablet'));
  MedalPage = () => lazy(() => import('./pages/Medal'));
  ImagePage = () => lazy(() => import('./pages/Images'));
  SuccessPage = () => lazy(() => import('./pages/Success'));

  _demoSlipPage = () => lazy(() => import('./pages/_demoSlip'));

  DonationPage = () => lazy(() => import('./pages/Donation'));
  DonationDestinationInfoPage = () =>
    lazy(() => import('./pages/DonationDestinationInfo'));
  DonationPaymentQRPage = () => lazy(() => import('./pages/DonationPaymentQR'));
  DonationPaymentKplusPage = () =>
    lazy(() => import('./pages/DonationPaymentKplus'));

  CampaignActivityPage = () => lazy(() => import('./pages/CampaignActivity'));
  CampaignInfoPage = () => lazy(() => import('./pages/CampaignInfo'));
  CampaignRewardMsgPage = () => lazy(() => import('./pages/CampaignRewardMsg'));
  RewardInfoPage = () => lazy(() => import('./pages/RewardInfo'));
  CampaignRewardCode = () => lazy(() => import('./pages/CampaignRewardCode'));

  RollcallPage = () => lazy(() => import('./pages/Rollcall'));

  ScheduleCreatePage = () =>
    lazy(() => import('./pages/Schedule/ScheduleCreate'));
  ScheduleSettingPage = () =>
    lazy(() => import('./pages/Schedule/BillTimeSetting'));
  ScheduleAssignPage = () =>
    lazy(() => import('./pages/Schedule/ScheduleAssign'));
  ScheduleSummaryPage = () =>
    lazy(() => import('./pages/Schedule/ScheduleSummary'));

  MultipleBillSummaryPage = () =>
    lazy(() => import('./pages/MultipleBill/BillSummary'));
  MultiplePaymentSummaryPage = () =>
    lazy(() => import('./pages/MultipleBill/PaymentSummary'));
  MultipleBillPaymentBySlipPage = () =>
    lazy(() => import('./pages/MultipleBill/PaymentBySlip'));
  MultipleCancelInfoPage = () =>
    lazy(() => import('./pages/MultipleBill/CancelInfo'));

  TransferSlipPage = () => lazy(() => import('./pages/TransferSlip'));
  TuTorialPage = () => lazy(() => import('./pages/Tutorial'));
  PDPAPage = () => lazy(() => import('./pages/PDPA'));
  RedeemLocationCodePage = () =>
    lazy(() => import('./pages/RedeemLocationCode'));

  ReferralInstructionPage = () =>
    lazy(() => import('./pages/Referral/Instruction/ReferralInstuctionPage'));
  ReferralSuccessPage = () =>
    lazy(() => import('./pages/Referral/Success/ReferralSuccessPage'));
  ReferralSharePreviewPage = () =>
    lazy(
      () => import('./pages/Referral/SharePreview/ReferralSharePreviewPage'),
    );
  ReferralAcceptPage = () => lazy(() => import('./pages/Referral/Accept'));

  CreateBillTemplatePage = () =>
    lazy(() => import('./pages/CreateBillTemplate/CreateBillTemplate'));

  BillCenterPage = () =>
    lazy(() => import('./pages/PrivateRequest/BillCenter/BillCenter'));
  CreatePrivateBillPage = () =>
    lazy(
      () =>
        import('./pages/PrivateRequest/CreatePrivateBill/CreatePrivateBill'),
    );
  PreviewPrivateBillPage = () =>
    lazy(
      () =>
        import('./pages/PrivateRequest/PreviewPrivateBill/PreviewPrivateBill'),
    );
  PrivateBillSummaryPage = () =>
    lazy(
      () =>
        import('./pages/PrivateRequest/PrivateBillSummary/PrivateBillSummary'),
    );
  GroupListPage = () =>
    lazy(() => import('./pages/PrivateRequest/GroupList/GroupList'));
  PreviewCreateBillPage = () =>
    lazy(
      () =>
        import('./pages/PrivateRequest/PreviewCreateBill/PreviewCreateBill'),
    );
  PrivateBillPaymentSuccessPage = () =>
    lazy(
      () =>
        import(
          './pages/PrivateRequest/PrivateBillPaymentSuccess/PrivateBillPaymentSuccess'
        ),
    );
  DeepLinkCreateBillPage = () =>
    lazy(
      () =>
        import('./pages/PrivateRequest/DeepLinkCreateBill/DeepLinkCreateBill'),
    );

  InitLiffFullPage = () =>
    lazy(() => import('./pages/InitLiff/InitLiffFull/InitLiffFull'));
  InitLiffTallPage = () =>
    lazy(() => import('./pages/InitLiff/InitLiffTall/InitLiffTall'));

  RobotLoginPage = () => lazy(() => import('./pages/RobotLogin/RobotLogin'));

  // TestCachePage = () => lazy(() => import('./pages/TestCache'));

  async componentDidMount() {

    if (window.location.href.indexOf('maintenance') > -1) {
      this.setState({ isInitFinished: true });
      return
    }

    let isChangedLanguage = false;
    const { location } = this.props as any;
    if (!isObjectEmpty(location) && location.search) {
      let query = qs.parse(location.search, { ignoreQueryPrefix: true }) as any;

      // Try to parse from liff.state
      if (query['liff.state']) {
        const liffQuery = qs.parse(query['liff.state'] as string, {
          ignoreQueryPrefix: true,
        });
        query = liffQuery;
      }

      if (!isObjectEmpty(query)) {
        if (query.is_automate === 'true') {
          storage.set('is_automate', true);
        }

        if (query.lang) {
          isChangedLanguage = true;
          if (
            query.lang.toLowerCase() === 'th' ||
            query.lang.toLowerCase() === 'en'
          ) {
            ChangeLanguage(query.lang.toLowerCase());
          } else {
            ChangeLanguage('th');
          }
        }
      }
    }

    if (!isChangedLanguage) {
      ChangeLanguage('th');
    }

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent,
      );
    const isGalaxyFold = /sm-f/.test(userAgent);
    const isSurfaceDuo = /Surface Duo/.test(userAgent);
    const isAllowedDevice = !isTablet || isGalaxyFold || isSurfaceDuo;

    if (
      !(isAllowedDevice || isCypressMode) &&
      history &&
      history.location &&
      history.location.pathname !== PageName.Tablet
    ) {
      history.replace(PageName.Tablet);
    }

    if (config.env !== Environment.PROD) {
      initStorageDebugger();
    }

    this.setState({ isInitFinished: true });
  }

  componentWillReceiveProps(nextProps: IAppProps) {
    if (nextProps.location !== this.props.location) {
      const { prevLocation } = this.state;
      prevLocation.push(this.props.location);
      this.setState({ prevLocation });
      Swal.close();
    }
  }

  render() {
    const prevLocationValue = {
      prevLocation: this.state.prevLocation,
    };

    if (getErrorStatus() === 'error') {
      history.goForward();
    }

    const switchOption = {
      basename: config.publicUrl,
    };

    if (!this.state.isInitFinished) {
      return <div></div>;
    }

    return (
      // <Loader show={this.state.isLoading} />
      // @ts-ignore
      <PrevLocationContext.Provider value={prevLocationValue}>
        <Switch {...switchOption}>
          <Route
            exact
            path={`${config.publicUrl}${PageName.Tablet}`}
            component={this.TabletPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.Example}`}
            component={this.ExamplePage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.BillAssign}`}
            component={this.BillAssignPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.CreateBill}`}
            component={this.CreateBillPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.CreateBillOCR}`}
            component={this.CreateBillOCRPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.CropImage}`}
            component={this.CropImagePage()}
          />

          <Route
            exact
            path={`${config.publicUrl}${PageName.CropImageTutorial}`}
            component={this.CropImageTutorialPage()}
          />

          <Route
            exact
            path={`${config.publicUrl}${PageName.InviteFriend}`}
            component={this.InviteFriendPage()}
          />

          <Route
            exact
            path={`${config.publicUrl}${PageName.InviteMoreFriend}`}
            component={this.InviteMoreFriendPage()}
          />

          <Route
            exact
            path={`${config.publicUrl}${PageName.PayType}`}
            component={this.PayTypePage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.PayTypeSelected}`}
            component={this.PayTypeSelectedPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.BillInfo}`}
            component={this.BillInfoPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.AddBankAccount}`}
            component={this.AddBankAccountPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.ConfirmAddAccount}`}
            component={this.ConfirmAddAccountPage()}
          />
          <Route
            exact
            path={`${config.publicUrl}${PageName.SelectBank}`}
            component={this.SelectBankPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.Bill}`}
            component={this.BillInfoPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.LinkKplusComplete}`}
            component={this.LinkKplusCompletePage()}
          />
          <Route
            path={`${config.publicUrl}${PageName.IFrameToKplus}`}
            component={this.IFrameToKplusPage()}
          />
          <Route
            path={`${config.publicUrl}${PageName.PaymentSuccess}`}
            component={this.PaymentSuccessPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.PaymentSuccessSlip}`}
            component={this.PaymentSuccessSlipPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.AccountManagement}`}
            component={this.AccountManagementPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.VerifyKPlus}`}
            component={this.VerifyKPlusPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.VerifyKPlusRegister}`}
            component={this.VerifyKPlusRegisterPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.Error}`}
            component={this.ErrorPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.Medal}`}
            component={this.MedalPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.Image}/:path/:name`}
            component={this.ImagePage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.SharedFBSuccess}`}
            component={this.SuccessPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName._demoSlip}`}
            component={this._demoSlipPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.Donation}`}
            component={this.DonationPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.DonationDestinationInfo}`}
            component={this.DonationDestinationInfoPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.DonationPaymentQR}`}
            component={this.DonationPaymentQRPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.DonationPaymentKplus}`}
            component={this.DonationPaymentKplusPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.CampaignActivity}`}
            component={this.CampaignActivityPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.CampaignInfo}`}
            component={this.CampaignInfoPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.CampaignRewardMsg}`}
            component={this.CampaignRewardMsgPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.RewardInfo}`}
            component={this.RewardInfoPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.CampaignRewardCode}`}
            component={this.CampaignRewardCode()}
          />

          <Route
            path={`${config.publicUrl}${PageName.Rollcall}`}
            component={this.RollcallPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ScheduleCreate}`}
            component={this.ScheduleCreatePage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ScheduleSetting}`}
            component={this.ScheduleSettingPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ScheduleAssign}`}
            component={this.ScheduleAssignPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ScheduleSummary}`}
            component={this.ScheduleSummaryPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.BillSummary}`}
            component={this.MultipleBillSummaryPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.PaymentSummary}`}
            component={this.MultiplePaymentSummaryPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.MultipleBillPaymentBySlip}`}
            component={this.MultipleBillPaymentBySlipPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.CancelInfo}`}
            component={this.MultipleCancelInfoPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.TransferSlip}`}
            component={this.TransferSlipPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.Tutorial}`}
            component={this.TuTorialPage()}
          />

          {/* <Route
            path={`${config.publicUrl}${PageName.PDPA}`}
            component={this.PDPAPage()}
          /> */}

          <Route
            path={`${config.publicUrl}${PageName.RedeemLocationCode}`}
            component={this.RedeemLocationCodePage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ReferralInstruction}`}
            component={this.ReferralInstructionPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ReferralSuccess}`}
            component={this.ReferralSuccessPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ReferralSharePreview}`}
            component={this.ReferralSharePreviewPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.ReferralAccept}`}
            component={this.ReferralAcceptPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.CreateBillTemplate}`}
            component={this.CreateBillTemplatePage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.BillCenter}`}
            component={this.BillCenterPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.CreatePrivateBill}`}
            component={this.CreatePrivateBillPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.PreviewPrivateBill}`}
            component={this.PreviewPrivateBillPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.PrivateBillSummary}`}
            component={this.PrivateBillSummaryPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.GroupList}`}
            component={this.GroupListPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.PreviewCreateBill}`}
            component={this.PreviewCreateBillPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.PrivateBillPaymentSucces}`}
            component={this.PrivateBillPaymentSuccessPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.DeepLinkCreateBill}`}
            component={this.DeepLinkCreateBillPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.InitLiffFull}`}
            component={this.InitLiffFullPage()}
          />

          <Route
            path={`${config.publicUrl}${PageName.InitLiffTall}`}
            component={this.InitLiffTallPage()}
          />

          {config.development.allowedRobot && (
            <Route
              path={`${config.publicUrl}${PageName.RobotLogin}`}
              exact
              component={this.RobotLoginPage()}
            />
          )}

          <Route path={`${config.publicUrl}/`} component={Page} />

          {/* <Route
          path={`${config.publicUrl}/cache`}
          component={this.TestCachePage()}
        /> */}
        </Switch>
      </PrevLocationContext.Provider>
    );
  }
}

export default withRouter(App as any);
