import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import {  decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class BillSummaryApi {
  async getBillsSummary(lineGroupId, memberId, postbackEventID) {
    const token = getLineToken();

    let postbackEventIDParams = '';
    if (!!postbackEventID) {
      postbackEventIDParams = `&postbackEventID=${postbackEventID}`;
    }

    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.BillsSummary}?lineGroupId=${lineGroupId}&memberId=${memberId}${postbackEventIDParams}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async remindBill(billId) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${billId}/remind`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getAllBillInBillSummary(
    lineGroupId: string,
    lineUserId: string,
    action: string,
  ) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.BillPriorities}?lineGroupId=${lineGroupId}&lineUserId=${lineUserId}&action=${action}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getBillOCRStatusNew(lineGroupId: string, lineUserId: string) {
    const token = getLineToken();

    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.BillsNewOCR}?lineGroupId=${lineGroupId}&lineUserId=${lineUserId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new BillSummaryApi();
