import { observable, toJS } from 'mobx';
import set from 'lodash/set';
import { IRewardStore, IRewardModel, IRewardCodeModel } from './RewardStore.d';
import CampaignApi from 'api/CampaignApi';
import RewardApi from 'api/RewardApi';

export class RewardStore implements IRewardStore {
  @observable rewardInfo: IRewardModel;
  @observable rewardList: IRewardModel[];
  @observable rewardActiveList: IRewardModel[];
  @observable rewardInActiveList: IRewardModel[];
  @observable rewardCodeInfo: IRewardCodeModel;
  @observable campaignRewardList: IRewardModel[];

  constructor() {
    this.rewardInfo = this.initRewardSchema() as any;
    this.rewardList = this.initRewardListSchema() as any;
    this.rewardCodeInfo = this.initRewardCodeSchema() as any;
    this.rewardActiveList = this.initRewardListSchema() as any;
    this.rewardInActiveList = this.initRewardListSchema() as any;
    this.campaignRewardList = this.initRewardListSchema() as any;
  }

  initRewardSchema() {
    return {
      id: '',
      type: '',
      brand: '',
      title: '',
      couponTitle: '',
      shortDescription: '',
      description: '',
      condition: '',
      startDate: '',
      endDate: '',
      logo1Url: '',
      logo2Url: '',
      bgCouponUrl: '',
      bgRewardUrl: '',
      bgPrimaryColor: '',
      bgSecondaryColor: '',
      fontColor: '',
      rewardValue: '',
      rewardCodes: [
        {
          id: '',
          brand: '',
          fileName: '',
          campaignId: '',
          rewardId: '',
          code: '',
          type: [],
          status: '',
        },
      ],
    };
  }

  initRewardCodeSchema() {
    return {
      id: '',
      brand: '',
      fileName: '',
      campaignId: '',
      rewardId: '',
      code: '',
      type: [],
      status: '',
    };
  }

  initRewardListSchema() {
    return [this.initRewardSchema()];
  }

  setRewardInfo(key: string, val: any) {
    const info = this.getRewardJs();
    set(info, key, val);
    this.rewardInfo = info;
  }

  getRewardJs() {
    return toJS(this.rewardInfo);
  }

  async getReward(rewardId: string) {
    try {
      const response = await RewardApi.GetRewardById(rewardId);
      this.rewardInfo = response.data || this.initRewardSchema();
    } catch (error) {
      this.rewardInfo = this.initRewardSchema();
    }
  }

  setRewardCodeInfo(key: string, val: any) {
    const info = this.getRewardCodeJs();
    set(info, key, val);
    this.rewardCodeInfo = info;
  }

  setRewardList(rewardList?: IRewardModel[]) {
    this.rewardList = rewardList;
  }

  getRewardListToJs() {
    return toJS(this.rewardList);
  }

  getRewardCodeJs() {
    return toJS(this.rewardCodeInfo);
  }

  async getRewardCode(rewardCodeId: string, lineUserId: string) {
    try {
      const response = await RewardApi.GetRewardCodeByIdAndLineUserId(rewardCodeId, lineUserId);
      this.rewardCodeInfo = response.data || this.initRewardCodeSchema();
    } catch (error) {
      this.rewardCodeInfo = this.initRewardCodeSchema();
    }
  }

  async getInActiveRewards(lineUserId: string) {
    try {
      const response = await CampaignApi.GetInActiveRewardsByLineUserID(
        lineUserId,
      );
      this.rewardInActiveList = response.data || [];
    } catch (error) {
      this.rewardInActiveList = this.initRewardListSchema();
    }
  }

  async getActiveRewards(lineUserId: string) {
    try {
      const response = await CampaignApi.GetActiveRewardsByLineUserID(
        lineUserId,
      );
      this.rewardActiveList = response.data || [];
    } catch (error) {
      this.rewardActiveList = this.initRewardListSchema();
    }
  }

  getInActiveRewardsJs() {
    return toJS(this.rewardInActiveList);
  }

  getActiveRewardsJs() {
    return toJS(this.rewardActiveList);
  }

  async getRewardsByCampaignID(campaignId: string) {
    try {
      const response = await RewardApi.GetCampaignRewardByID(campaignId);
      this.campaignRewardList = response.data || this.initRewardListSchema();
    } catch (error) {
      this.campaignRewardList = this.initRewardListSchema();
    }
  }

  getCampaignRewardByIdJs(rewardId: string) {
    if (this.campaignRewardList) {
      const reward = this.campaignRewardList.find((r) => r.id === rewardId);
      if (reward) {
        return toJS(reward);
      }
    }
    return toJS(this.initRewardSchema());
  }
}

export default new RewardStore();
