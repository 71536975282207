import { observable, toJS } from 'mobx';
import Api from 'api/GroupApi';
import set from 'lodash/set';
import { IGroupStore, IGroupModel } from './GroupStore.d';

export class GroupStore implements IGroupStore {
  @observable groupInfo: IGroupModel;
  @observable isLoading: boolean;

  constructor() {
    this.groupInfo = {} as any;
    this.isLoading = false;
  }

  resetInfo() {
    this.groupInfo = {} as any;
  }

  getGroupJs() {
    return toJS(this.groupInfo);
  }

  setInfo(key: string, val: any) {
    const info = this.getGroupJs();
    set(info, key, val);
    this.groupInfo = info;
  }

  async getGroupMemberByLineGroupId(
    lineGroupId: string,
    skipLineApi?: boolean,
    onSuccess?: (info: any) => any,
  ) {
    try {
      this.isLoading = true;
      const result = await Api.getGroupMemberByLineGroupId(
        lineGroupId,
        skipLineApi,
      );
      const members: any = result.data.members;
      for (const member of members) {
        member.lineId = (member.profile && member.profile.lineUserId) || '';
        member.name = member.profile.name;
        member.image = member.profile.image;
      }
      this.groupInfo = result.data;
      if (typeof onSuccess === 'function') {
        onSuccess(this.getGroupJs());
      }
    } catch (error) {
      this.groupInfo = {} as any;
    } finally {
      this.isLoading = false;
    }
  }

  async enableMultipleBills(lineGroupId: string) {
    this.isLoading = true;
    try {
      return await Api.enableMultipleBills(lineGroupId);
    } catch (ex) {
      console.log('Enable multiple bill error: ', ex);
      return false;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new GroupStore();
