const config = {
  env: 'sit',
  publicUrl: '',
  publicWeb: 'https://sitkhunthong.kasikornbank.com',
  api: {
    ocr: 'https://sitkhunthong.kasikornbank.com/ocr/api',
    socialbot: 'https://sitkhunthong.kasikornbank.com/api',
    s3: 'https://uatkhunthong.kasikornbank.com',
  },
  kplus: {
    url: 'https://appuat.kplus.link',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDg_CRn3AmgVfBceAeZg4qXxVaqqPtvWLY',
    authDomain: 'social-banking-uat-bed69.firebaseapp.com',
    databaseURL: 'https://social-banking-uat-bed69.firebaseio.com',
    projectId: 'social-banking-uat-bed69',
    storageBucket: 'social-banking-uat-bed69.appspot.com',
    messagingSenderId: '889414426551',
  },
  liff: {
    demo_slip: '',
    crop_image: '1660653335-m8P5qK2Q',
    pay_type: '1660653335-0KeQJEY4',
    bill_assign: '1660653335-65reR3Pd',
    add_account: '1660653335-y0P28Kdb',
    summary: '1660653335-J8V8NKom',
    invite_friend: '1660653335-g8byXRrZ',
    account_management: '1660653335-7K9R0dry',
    medal: '1660653335-49Xj7QJ9',
    donation: '1660653335-j8vX79x4',
    donation_info: '1660653335-YPA0VzJn',
    rollcall: '1660653335-36aXZoOK',
    campaign_landing: '1660653335-XJOqbv9R',
    campaign_activity: '1660653335-GzjLkPg3',
    schedule_create: '1660653335-dVlWrX1P',
    schedule_summary: '1660653335-79qpJnPM',
    bill_summary: '1660653335-ypdmNQVP',
    cancel_info: '1660653335-4MEaQ8Kb',
    multiple_payment_slip: '1660653335-MEDAQr2Y',
    tutorial: '1660653335-YbPdMy9j',
    reward_info: '1660653335-BqAoE80z',
    redeem_location_code: '1660653335-AKvq1WgO',
    referrer: '1660653335-8GyRBA3l',
    referree: '1660653335-JLBGQ90A',
    create_bill_template: '1660653335-zZ4LNV50',
    bill_center: '1660653335-5Qx9Zb3K',
    private_bill_summary: '1660653335-lQd2DozO',
    init_liff_full: '1660653335-z43omZJa',
    init_liff_tall: '1660653335-7ey65ARl',
    robot_login: '1660653335-LyWxaENQ',
  },
  vconsole: {
    enabled: true,
  },
  cypress: {
    enabled: true,
  },
  development: {
    allowedDesktop: true,
    allowedRobot: true,
  },
  publickeyBot: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApASIFXNq7Bmjhbtmh2Qe
  87tN/Kl/hKIJ1FdGB841n0mzXSWcfJLLCqPc0LNgHHmRLxe47WQbMCrxXoUOVK+r
  rUXwYdzQ10yVVU7ODgZW5XoaImf5NJTkgJPjFV4iW4fSqW1tsUbGwbJ3zlBmEo6F
  UfdzChMcGS0UFlUIQczDfCnZVXiTtveeeB2ikCxJj+JQGNtxwC5YHl5BpH2DGcyK
  V4DW/UvuyhfMqDwfqf/vf2cjmDyB/BqOb59xWIhdGbP789K5kQ5uvQKxypRM3A67
  G3l8KBrXyXzVwlziNGr6B6K94MyUDiC0nbgKYAszTrwrBmEUP/l6QjxI/n4QRkN/
  nQIDAQAB`,
  publickeyOcr: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4gMgWdQl/J2Kq3qH3Wpw
  L79EUnoTJvfPvMDS/5XTkGLt+UDBJ5Yz3l++w0DSBZKKXeS1Lg0zhVFp/2NYD/bs
  0SOelE8kOVJgsguB+SDVioSrXKF6YaP5P84QMHhx//MwxK8pIqKT8FbDvZYjyDDK
  /Vqt4IwO3tDpYeTKoR1QKJs27AdxLBzzMbEr+Gn/pmIPUHzRDecNlSkbEDO+YJUI
  rgSbnAFxg46xhfzjWyjdkRJLr82icV5hxJB86E/62X3kqtXBb76Tpt4wGU3DNdGB
  j4CRxQj3oxRZfYe7n/LIIYPJuggdqrdTfki3SFCN3N4OxG0BQgVHQq0/6g66W4n6
  YwIDAQAB`,
  authentication: {
    enabled: true,
    encrypt: false,
  },
  gtm: {
    id: 'GTM-WRJ3LVF',
  },
  fb: {
    appId: '2489175271362409',
    version: 'v3.0',
  },
  line: {
    botBasicId: '~@944isdly',
    botBasicIdBypass: '@944isdly',
  },
  bill: {
    defaultExpiredDuration: 7,
    defaultExtendedDuration: 1,
  },
  privateBill: {
    noteExceedLimit: 200,
  },
};

export default config;
