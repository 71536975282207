export const Api = {
  Bills: 'bills',
  Members: 'members',
  Groups: 'groups',
  KPlus: 'kplus',
  INP: 'inp',
  PaymentTrans: 'payment-transaction',
  Banks: 'banks',
  Session: 'session',
  Images: 'images',
  Resources: 'resources',
  FeatureStatus: 'feature-status',
  Medals: 'medals',
  MedalsMember: 'member_medals',
  Config: 'config',
  KPlusTransfer: 'kplus_transfer',
  Firebase: 'firebase',
  MaintenanceMode: 'maintenance-mode',
  DonateDestination: 'donate_destinations',
  KPlusTransferDonate: 'kplus_transfer_donate',
  DonateDestinationFlex: 'donate_destination_flex',
  LineUserActiveRewards: 'line_user_active_rewards',
  LineUserInactiveRewards: 'line_user_in_active_rewards',
  CampaignAllActives: 'campaign_all_actives',
  SegmentedCampaigns: 'segmented_campaigns',
  Rollcall: 'rollcall',
  EndRollcall: 'rollcall/end',
  Reward: 'reward',
  Rewards: 'rewards',
  RewardCode: 'reward_code',
  CampaignRewards: 'campaign_rewards',
  Campaigns: 'campaigns',
  ScheduleConfigs: 'schedule_configs',
  ScheduleBill: 'schedule_bills',
  ScheduleImages: 'schedule_images',
  PayWitchCash: 'pay_with_cash',
  BillsSummary: 'bills_summary',
  BillPriorities: 'bills_priorities',
  PayBySlip: 'pay_by_slip',
  SlipAmount: 'slip_amount',
  PostBackEvent: 'postback_event',
  BillsNewOCR: 'bills_new_ocr',
  UploadSlipt: 'pay_by_slip/upload',
  RedeemLocationCode: 'redeem_codes',
  Referrers: 'referrers',
  ReferralCampaigns: 'referral_campaigns',
  Referral: 'referrals',
  AbTesting: 'ab-testing',
  LogFrontend: 'log-frontend',
  GroupBill: 'group-bill',
  PrivateBill: 'private-bill',
  DeepLinkGen: 'deeplink',
};
