import PrivateBillApi from 'api/PrivateBillApi';
import { observable, toJS } from 'mobx';
import KPlusApi from 'api/KPlusApi';
import BillApi from 'api/BillApi';
import set from 'lodash/set';
import { Trans } from 'common/components/Translate';
import {
  CommonError,
  DonationPaymentMethods,
  PaymentMethod,
} from 'definitions/constant';
import {
  IPayTypeStore,
  IPaymentMethod,
  IDonateRequestModel,
  IPaymentRequestModel,
  IDeepLinkRequestModel,
} from './PayTypeStore.d';
import { IFeatureStatusModel } from './FeatureStatusStore.d';
import { formatAccountName, isObjectEmpty } from 'utils/Common';
import { IABTestingDeeplink } from './AbTesting/AbTestingStore.d';

export class PayTypeStore implements IPayTypeStore {
  @observable info: any;
  @observable errorMsg: string;
  @observable errorCode: string;
  @observable donateInfo: IDonateRequestModel;
  @observable verifyKplus: boolean;
  isVerfiyKplus: boolean;

  constructor() {
    this.info = this.initSchema() as any;
    this.errorMsg = '';
    this.errorCode = '';
    this.donateInfo = this.initDonateRequestSchema() as any;
    this.isVerfiyKplus = false;
  }

  setInfo(key: string, val: any) {
    const info = this.getInfoJs();
    set(info, key, val);
    this.info = info;
  }

  getInfoJs() {
    return toJS(this.info);
  }

  setDonateInfo(key: string, val: any) {
    const donateInfo = this.getDonateInfoJs();
    set(donateInfo, key, val);
    this.donateInfo = donateInfo;
  }

  getDonateInfoJs() {
    return toJS(this.donateInfo);
  }

  getErrorMsg() {
    return this.errorMsg;
  }

  getErrorCode() {
    return this.errorCode;
  }

  initSchema() {
    return {
      payType: '',
      billIds: [],
      memberId: '',
      groupId: '',
      amount: 0,
      billName: '',
      ownerAccount: {},
      kPlusInfo: {},
      deepLinkInfo: {},
      errorMsg: '',
      errorCode: '',
      transferToken: '',
      phoneNo: '',
      idCard: '',
      tokenizeTxnId: '',
      transferDeeplink: {},
    };
  }

  initDonateRequestSchema() {
    return {
      groupId: '',
      donateDestId: '',
      memberId: '',
      amount: 0,
      osPlatform: '',
    };
  }

  async payCashConfirm(billId: string, memberId: string) {
    try {
      const resp = await BillApi.payWithCashConfirm(billId, memberId);
      return toJS({
        success: true,
        message: '',
        paymentTranId: resp.data.paymentTranId,
      });
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  }

  async payCashReject(billId: string, memberId: string) {
    try {
      const resp = await BillApi.payWithCashReject(billId, memberId);
      return toJS({
        success: true,
        message: '',
        paymentTranId: resp.data.paymentTranId,
      });
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  }

  async payCashByOwner(memberIDs: string[], billId: string) {
    try {
      const data = {
        member_ids: memberIDs,
      };
      const resp = await BillApi.payWithCashByOwner(data, billId);
      return toJS({
        success: true,
        message: '',
        paymentTranId: resp.data.paymentTranId,
      });
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  }

  async payCashWaiting() {
    try {
      const info = this.getInfoJs();
      const data: IPaymentRequestModel = {
        billIds: info.billIds,
        memberId: info.memberId,
      };
      const resp = await BillApi.PayWithCash(data);
      return {
        success: true,
        message: '',
        paymentTranId: resp.data.paymentTranId, // Cash have not data in payment_transaction yet
      };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  }

  async getDeepLinkKplus(
    type: string,
    deviceId: string,
    referralCampaignId: string,
    abTestingDeeplink: IABTestingDeeplink,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const info = this.getInfoJs();
      const response = await KPlusApi.getDeepLink(
        info.groupId,
        info.phoneNo,
        info.idCard,
        type,
        deviceId,
        referralCampaignId,
        abTestingDeeplink,
        handleRetry,
      );
      if (!response) {
        return false;
      } else if (response === CommonError.FailToFetch) {
        throw new Error(Trans('message.responseMsg.internalServerError'));
      }
      this.setInfo('deepLinkInfo', response.data);
      return true;
    } catch (error: any) {
      this.errorMsg = error.message;
      this.errorCode = '';
      return false;
    }
  }

  async verifyKplusToken() {
    try {
      if (!this.isVerfiyKplus) {
        const info = this.getInfoJs();
        const response = await KPlusApi.verifyKplusToken(info.memberId);
        if (!response) {
          this.verifyKplus = false;
        }
        this.verifyKplus = true;
      }
    } catch (error) {
      this.verifyKplus = false;
    } finally {
      this.isVerfiyKplus = true;
      return this.verifyKplus;
    }
  }

  async transferInquiry(
    isPrivateBill: boolean,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const info = this.getInfoJs();
      const data: IPaymentRequestModel = {
        billIds: info.billIds,
        memberId: info.memberId,
      };

      const response = isPrivateBill
        ? await PrivateBillApi.transferInquiry(data, handleRetry)
        : await KPlusApi.transferInquiry(data, handleRetry);

      if (response === CommonError.FailToFetch) {
        throw new Error(Trans('message.responseMsg.internalServerError'));
      } else if (response && response.transferToken) {
        this.setInfo('transferToken', response.transferToken);
        this.setInfo('kPlusInfo', {
          accountName: formatAccountName(response.fromNameTh),
          accountNo: response.fromAccNo,
        });
        this.setInfo('ownerAccount', {
          name: response.toNameTh,
          no: response.toAccNo,
        });
        this.info.tokenizeTxnId = response.tokenizeTxnId;
      }
      return true;
    } catch (error: any) {
      this.errorMsg = error.message;
      this.errorCode = error.errorCode || '';
      return false;
    }
  }

  async transferConfirm(isPrivateBill: boolean) {
    try {
      const info = this.getInfoJs();
      const data: IPaymentRequestModel = {
        billIds: info.billIds,
        memberId: info.memberId,
        transferToken: info.transferToken,
        tokenizeTxnId: info.tokenizeTxnId,
      };

      const response = isPrivateBill
        ? await PrivateBillApi.transferConfirm(data)
        : await KPlusApi.transferConfirm(data);

      if (response === CommonError.FailToFetch) {
        throw new Error(Trans('message.responseMsg.internalServerError'));
      } else if (response) {
        this.setInfo('kPlusInfo', {
          accountName: formatAccountName(response.fromNameTh),
          accountNo: response.fromAccNo,
        });
        this.setInfo('ownerAccount', {
          name: response.toNameTh,
          no: response.toAccNo,
        });
      }
      return { success: true, paymentTranId: response.paymentTranId };
    } catch (error: any) {
      this.errorMsg = error.message;
      this.errorCode = error.errorCode || '';
      return { success: false };
    }
  }

  async deeplinkRequest(data: IDeepLinkRequestModel, isPrivateBill: boolean) {
    try {
      const response = isPrivateBill
        ? await PrivateBillApi.deeplinkRequest(data)
        : await KPlusApi.deeplinkRequest(data);

      if (!response) {
        return false;
      } else if (response === CommonError.FailToFetch) {
        throw new Error(Trans('message.responseMsg.internalServerError'));
      } else {
        this.setInfo('kplusDeepLink', {
          link: response.data.deepLink,
          error: response.data.error,
        });
        return true;
      }
    } catch (error: any) {
      this.errorMsg = error.message;
      this.errorCode = '';
      return false;
    }
  }

  getPaylerPaymentMethods(
    featureStatus?: IFeatureStatusModel,
    isReceiverAccPromptpay?: boolean,
    isPvtUser?: boolean,
    kPlusInfo?,
  ): IPaymentMethod[] {
    const comingSoon = Trans('payTypeStore.comingSoon');

    let invisibleKplus = true;
    let invisibleDescKplus = '';
    let disableKplus = false;

    let invisibleKhunThong = false;
    const invisibleDescKhunThong = Trans('payTypeStore.comingSoon');
    let disableKhunThong = false;

    if (isPvtUser) {
      if (isReceiverAccPromptpay) {
        invisibleKplus = true;
        invisibleDescKplus = `(${Trans(
          'payTypeStore.transferToBankAccountOnly',
        )})`;
      } else {
        invisibleKplus = false;
      }
      disableKplus = false;
      disableKhunThong = false;
    } else if (!isPvtUser) {
      if (isReceiverAccPromptpay) {
        invisibleKplus = true;
        invisibleDescKplus = `(${Trans(
          'payTypeStore.transferToBankAccountOnly',
        )})`;
      } else {
        invisibleKplus = false;
      }

      disableKplus =
        (!isObjectEmpty(featureStatus) && featureStatus.payWithKplus === 1) ||
        false;
      disableKhunThong =
        (!isObjectEmpty(featureStatus) &&
          featureStatus.linkAccountKplus === 1) ||
        false;

      if (disableKplus) {
        invisibleKplus = false;
      }
      if (disableKhunThong) {
        invisibleKhunThong = false;
      }
    }

    return [
      {
        order: !isObjectEmpty(kPlusInfo) ? 1 : 2,
        key: PaymentMethod.KhunThong,
        disable: disableKhunThong,
        displayHtml: '',
        invisibleDesc: invisibleDescKhunThong,
        invisible: invisibleKhunThong,
      },
      {
        order: !isObjectEmpty(kPlusInfo) ? 2 : 1,
        key: PaymentMethod.KPlus,
        disable: disableKplus,
        displayHtml: '',
        invisibleDesc: invisibleDescKplus,
        invisible: invisibleKplus,
      },
      {
        order: 3,
        key: PaymentMethod.Transfer,
        disable:
          (!isObjectEmpty(featureStatus) && featureStatus.transferSlip === 1) ||
          false,
        displayHtml: '',
        invisibleDesc: comingSoon,
        invisible: false,
      },
      {
        order: 4,
        key: PaymentMethod.Cash,
        disable:
          (!isObjectEmpty(featureStatus) && featureStatus.payWithCash === 1) ||
          false,
        displayHtml: '',
        invisibleDesc: comingSoon,
        invisible: false,
      },
    ];
  }

  getDonationPaymentMethods(
    qrImageUrl?: string,
    accountNo?: string,
    featureStatus?: IFeatureStatusModel,
    isPvtUser?: boolean,
  ) {
    const invisibleDescKplus = Trans('payTypeStore.comingSoon');
    const invisibleKplus =
      (!isObjectEmpty(featureStatus) && featureStatus.donateWithKplus === 1) ||
      false;

    const PaymentMethodKPlus = {
      order: 1,
      key: DonationPaymentMethods.KPlus,
      disable: false,
      displayHtml: '',
      invisibleDesc: invisibleDescKplus,
      invisible: invisibleKplus,
    };

    const PaymentMethodQRCode = {
      order: 2,
      key: DonationPaymentMethods.QRCode,
      disable: false,
      displayHtml: '',
      invisibleDesc: '',
      invisible: false,
    };

    if (qrImageUrl && accountNo) {
      return [
        {
          ...PaymentMethodKPlus,
        },
        {
          ...PaymentMethodQRCode,
        },
      ];
    } else if (accountNo) {
      return [
        {
          ...PaymentMethodKPlus,
        },
      ];
    } else if (qrImageUrl) {
      return [
        {
          ...PaymentMethodQRCode,
        },
      ];
    }
  }

  async donateDeeplink() {
    try {
      const donateInfo = this.getDonateInfoJs();
      if (typeof donateInfo.amount === 'string') {
        donateInfo.amount = parseFloat(donateInfo.amount);
      }
      const response = await KPlusApi.donateDeeplinkRequest(
        donateInfo.groupId,
        donateInfo.donateDestId,
        donateInfo.memberId,
        donateInfo.osPlatform,
        donateInfo.amount,
      );
      if (!response) {
        return false;
      } else if (response === CommonError.FailToFetch) {
        throw new Error(Trans('message.responseMsg.internalServerError'));
      } else {
        this.setInfo('transferDeeplink', {
          link: response.data.deepLink,
          error: response.data.error,
        });
        return true;
      }
    } catch (error: any) {
      this.errorMsg = error.message;
      this.errorCode = '';
      return false;
    }
  }
}

export default new PayTypeStore();
