import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class CampaignApi {
  async GetActiveRewardsByLineUserID(lineUserId?: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.LineUserActiveRewards}/${lineUserId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async GetInActiveRewardsByLineUserID(lineUserId?: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.LineUserInactiveRewards}/${lineUserId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async GetAllActiveCampaigns() {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.CampaignAllActives}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async GetAllSegmentedCampaigns(handleRetry: (tag: string) => void) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.SegmentedCampaigns}`,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async GetCampaignByID(
    campaignId: string,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Campaigns}/${campaignId}`,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async GetCampaignReward(lineUserId: string, refno: string) {
    const token = getLineToken();

    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `reward?lineuserid=${lineUserId}&refno=${refno}`,
        //ep: `reward?lineuserid=U07bb54f2b6d16e4b26e6442021cb9e5c&refno=${refno}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new CampaignApi();
