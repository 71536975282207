import PrivateBillApi from 'api/PrivateBillApi';
import {
  IPrivateBillStore,
  IPrivateBillModel,
  IPrivateGroupModel,
  IPrivateBillCreateModel,
  BillCenterTab,
  IGetPrivateBillsCondition,
  IActionPrivateBillMember,
  CreatePrivateBillTab,
  IBillHistory,
  IGroupBill,
} from './PrivateBillStore.d';
import { observable } from 'mobx';
import PrivateRequestApi from 'api/PrivateRequestApi';

export class PrivateBillStore implements IPrivateBillStore {
  @observable
  currentTab: BillCenterTab;

  @observable
  createBillTab: CreatePrivateBillTab;

  @observable
  privateBillsConfirmed: IPrivateBillModel[];

  @observable
  privateBillsOwner: IPrivateBillModel[];

  @observable
  privateBillsPaid: IPrivateBillModel[];

  @observable
  privateBill: IPrivateBillModel;

  @observable
  privateBillCreate: IPrivateBillModel;

  @observable
  groups: IPrivateGroupModel[];

  @observable
  selectedGroup: IPrivateGroupModel;

  @observable
  billsInGroup: IGroupBill;

  completeBillsInGroup: IBillHistory[];
  lastBillsInGroupPaidId: string;
  lastPrivateBillsPaidId: string;
  privateBillCreateSaved: {
    numberOfPayer: number;
    isIncludeOwner: boolean;
  };

  constructor() {
    this.currentTab = undefined;
    this.createBillTab = CreatePrivateBillTab.RequestOne;
    this.privateBill = this.initPrivateBillSchema();
    this.privateBillCreate = this.initPrivateBillSchema();
    this.billsInGroup = this.initGroupBill();
    this.privateBillsConfirmed = [];
    this.privateBillsOwner = [];
    this.privateBillsPaid = [];
    this.groups = [];
    this.selectedGroup = undefined;
    this.privateBillCreateSaved = {
      numberOfPayer: 2,
      isIncludeOwner: false,
    };
  }

  async getPrivateBillsConfirmed(data: IGetPrivateBillsCondition) {
    try {
      const result = await PrivateBillApi.getPrivateBills(data);
      this.privateBillsConfirmed = result.data || [];
    } catch (error) {
      this.privateBillsConfirmed = [];
    }
  }

  async getPrivateBillsOwner(data: IGetPrivateBillsCondition) {
    try {
      const result = await PrivateBillApi.getPrivateBills(data);
      this.privateBillsOwner = result.data || [];
    } catch (error) {
      this.privateBillsOwner = [];
    }
  }

  async getPrivateBillsPaid(data: IGetPrivateBillsCondition) {
    try {
      const result = await PrivateBillApi.getPrivateBills({
        ...data,
        lastId: this.lastPrivateBillsPaidId,
      });
      if (result?.data?.length > 0) {
        if (this.privateBillsPaid?.length > 0) {
          this.privateBillsPaid = [
            ...this.privateBillsPaid,
            ...(result.data || []),
          ];
        } else {
          this.privateBillsPaid = result.data || [];
        }
        this.lastPrivateBillsPaidId = result.data[result.data.length - 1].id;
      }
    } catch (error) {
      this.privateBillsPaid = [];
    }
  }

  async getPrivateBillById(billId: string, lineUserId: string) {
    try {
      const result = await PrivateBillApi.getPrivateBillById({
        billId,
        lineUserId,
      });
      this.privateBill = result.data || this.initPrivateBillSchema();
    } catch (error) {
      this.privateBill = this.initPrivateBillSchema();
    }
  }

  async getGroups() {
    try {
      const result = await PrivateRequestApi.getGroupsByLineUserId();
      this.groups = result.data || [];
    } catch (error) {
      this.groups = [];
    }
  }

  async getBillsInGroup() {
    try {
      const result = await PrivateRequestApi.getBillsInGroup();
      this.billsInGroup = result.data || this.initGroupBill();
    } catch (error) {
      this.billsInGroup = this.initGroupBill();
    }
  }

  async getCompleteBillsInGroup(limit: number) {
    try {
      const result = await PrivateRequestApi.getCompleteBillsInGroup(
        limit,
        this.lastBillsInGroupPaidId,
      );

      if (result?.data?.length > 0) {
        if (this.completeBillsInGroup?.length > 0) {
          this.completeBillsInGroup = [
            ...this.completeBillsInGroup,
            ...(result.data || []),
          ];
        } else {
          this.completeBillsInGroup = result.data || [];
        }
        this.lastBillsInGroupPaidId = result.data[result.data.length - 1].id;
      }
    } catch (error) {
      this.completeBillsInGroup = [];
    }
  }

  async createPrivateBill() {
    try {
      const {
        name,
        billImage,
        total,
        numberOfPayer,
        isIncludeOwner,
        ownerLineUserId,
        expireDate,
        account,
        note,
        amountForEachPayer,
        source,
        deeplinkId,
      } = this.getPrivateBillCreateJs();
      const payload = {
        name,
        billImage,
        total,
        numberOfPayer,
        isIncludeOwner,
        ownerLineUserId,
        expireDate,
        account,
        note,
        amountForEachPayer,
        source,
        deeplinkId,
      } as IPrivateBillCreateModel;

      const result = await PrivateBillApi.createPrivateBill(payload);
      return { isSuccess: true, billId: result.data?.id };
    } catch (e) {
      const error = e as Error;
      console.log('create private bill error: ', error.message);
      return { isSuccess: false, message: error.message };
    }
  }

  async addPrivateBillMember(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.addPrivateBillMember(data);
      this.privateBill = result.data || this.initPrivateBillSchema();
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async acknowledgeJoinBill(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.acknowledgeJoinBill(data);
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async removePrivateBillMember(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.removePrivateBillMember(data);
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async leavePrivateBillMember(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.leavePrivateBillMember(data);
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async confirmCash(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.confirmCash(data);
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async rejectCash(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.rejectCash(data);
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async markAsPaid(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.markAsPaid(data);
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async cancelPrivateBill(data: IActionPrivateBillMember) {
    try {
      const result = await PrivateBillApi.cancelPrivateBill(data);
      if (result.data) {
        return { isSuccess: true };
      }
    } catch (e) {
      const error = e as Error;
      return { isSuccess: false, message: error.message };
    }
  }

  async payCashPrivateBill(data: IActionPrivateBillMember) {
    try {
      await PrivateBillApi.payCashPrivateBill(data);
      return {
        success: true,
      };
    } catch (e) {
      const error = e as Error;
      return { success: false, message: error.message };
    }
  }

  setPrivateBillCreate(key: string, value: any) {
    this.privateBillCreate[key] = value;
  }

  resetPrivateBillCreate() {
    this.privateBillCreate = this.initPrivateBillSchema();
  }

  setSelectedGroup(value: IPrivateGroupModel) {
    this.selectedGroup = value;
  }

  getBillsInGroupJs() {
    return this.billsInGroup;
  }

  getGroupsJs() {
    return this.groups;
  }

  getPrivateBillsConfirmedJs() {
    return this.privateBillsConfirmed;
  }

  getPrivateBillsOwnerJs() {
    return this.privateBillsOwner;
  }

  getPrivateBillsPaidJs() {
    return this.privateBillsPaid;
  }

  getPrivateBillJs() {
    return this.privateBill;
  }

  getPrivateBillCreateJs() {
    return this.privateBillCreate;
  }

  getSelectedGroupJs() {
    return this.selectedGroup;
  }

  getCompleteBillsInGroupJs() {
    return this.completeBillsInGroup;
  }

  resetPrivateBill() {
    this.privateBill = this.initPrivateBillSchema();
  }

  resetPagination() {
    this.completeBillsInGroup = [];
    this.lastBillsInGroupPaidId = '';

    this.privateBillsPaid = [];
    this.lastPrivateBillsPaidId = '';
  }

  saveNumberOfPayer(numberOfPayer: number) {
    this.privateBillCreateSaved.numberOfPayer = numberOfPayer;
  }

  saveIsIncludeOwner(isIncludeOwner: boolean) {
    this.privateBillCreateSaved.isIncludeOwner = isIncludeOwner;
  }

  initPrivateBillSchema() {
    return {
      id: '',
      name: '',
      account: {
        no: '',
        name: '',
        isAccountPromptpay: false,
        bankCode: '',
      },
      ownerMember: {
        name: '',
        image: '',
        id: '',
      },
      members: [],
      total: 0,
      status: '',
      createdDate: undefined,
      billImage: '',
      numberOfPayer: 0,
      isIncludeOwner: false,
      ownerMemberId: '6267b2324222d4473156768e',
      expireDate: '',
      note: '',
      ownerLineUserId: '',
      amountForEachPayer: 0,
      source: '',
    };
  }

  initGroupBill() {
    return {
      billOwners: [],
      billPayers: [],
    };
  }
}

const privateBillStore = new PrivateBillStore();
export default privateBillStore;
