import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class RedeemLocationCodeApi {
  async redeemLocationCode(
    code: string,
    lat: number,
    lon: number,
    lineUserId: string,
    handleRetry: (tag: string) => void,
  ) {
    // api call
    const token = getLineToken();
    const data = { lat, lon };
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PATCH,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.RedeemLocationCode}/${code}?lineUserId=${lineUserId}`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getRedeemCodeByCode(code: string, handleRetry: (tag: string) => void) {
    // api call
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.RedeemLocationCode}/${code}`,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new RedeemLocationCodeApi();
