import React from 'react';
import { observable, toJS } from 'mobx';
import { Trans } from 'common/components/Translate';
import {
  ICampaignModel,
  ICampaignStore,
  ICampaignRewardModel,
} from './CampaignStore.d';
import CampaignApi from 'api/CampaignApi';
import DateTime from 'common/components/DateTime/DateTime';

export class CampaignStore implements ICampaignStore {
  @observable campaignInfo: ICampaignModel;
  @observable campaignList: ICampaignModel[];
  @observable campaignSegmentedList: ICampaignModel[];

  constructor() {
    this.campaignInfo = this.initCampaignSchema() as any;
    this.campaignList = this.initCampaignListSchema() as any;
    this.campaignSegmentedList = this.initCampaignListSchema() as any;
  }

  initCampaignSchema() {
    return {
      id: '',
      title: '',
      flexUrl: '',
      ImageUrl: '',
      logoUrl: '',
      bannerUrl: '',
      shortDescription: '',
      description: '',
      condition: '',
      status: '',
      rewards: [
        {
          rewardId: '5e9cfcff105989494d0ce10e',
          rewardName: '',
          quota: 0,
          remainingQuota: 10,
          quotaType: 'weekly',
          periodQuota: 0,
          remainingPeriodQuota: 0,
          resetDate: null,
        },
      ],
      triggerEventType: '',
      triggerEventName: '',
      assignRewardTo: '',
      hideCampaign: false,
      startDate: '',
      endDate: '',
      createdBy: '',
      createdDate: '',
      updatedBy: '',
      updatedDate: '',
    };
  }

  initCampaignListSchema() {
    return [
      {
        id: '',
        title: '',
        flexUrl: '',
        ImageUrl: '',
        logoUrl: '',
        bannerUrl: '',
        shortDescription: '',
        description: '',
        condition: '',
        status: '',
        rewards: [
          {
            rewardId: '5e9cfcff105989494d0ce10e',
            rewardName: '',
            quota: 0,
            remainingQuota: 10,
            quotaType: 'weekly',
            periodQuota: 0,
            remainingPeriodQuota: 0,
            resetDate: null,
          },
        ],
        triggerEventType: '',
        triggerEventName: '',
        assignRewardTo: '',
        hideCampaign: false,
        startDate: '',
        endDate: '',
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: '',
      },
    ];
  }

  getDescPeriodQuota(reward: ICampaignRewardModel) {
    const periodQuotaType = reward.quotaType;
    let displayType = '';

    switch (periodQuotaType) {
      case 'DAILY':
        displayType = Trans('campaignActivityPage.daily');
        break;
      case 'MONTHLY':
        displayType = Trans('campaignActivityPage.monthly');
        break;
      case 'WEEKLY':
        displayType = Trans('campaignActivityPage.weekly');
        break;
      default:
        //NORMAL
        displayType = Trans('campaignActivityPage.normal');
        break;
    }
    let isDone = false;
    let numReward = (reward.remainingQuota / reward.quota) * 100;
    let remindLeft = reward.remainingQuota;
    let fullDesc = '' as string | JSX.Element;
    let fullDescCampaingInfo = '' as string | JSX.Element;
    fullDesc = Trans('campaignActivityPage.messageDesc', {
      displayType: displayType,
      remindLeft: remindLeft,
    });
    fullDescCampaingInfo = Trans('campaignInfoPage.messageCouponLeft', {
      displayType: displayType,
      remindLeft: remindLeft,
    });

    if (reward.remainingQuota === 0) {
      fullDesc = Trans('campaignActivityPage.messageCouponDone');
      if (reward.resetDate !== null) {
        fullDesc = (
          <>
            {Trans('campaignActivityPage.messageCouponNotDone')}
            <DateTime datetime={reward.resetDate} />
          </>
        );
      }
      isDone = true;
    }

    if (periodQuotaType !== '') {
      numReward = (reward.remainingPeriodQuota / reward.periodQuota) * 100;
      remindLeft = reward.remainingPeriodQuota;
      fullDesc = Trans('campaignActivityPage.messageDesc', {
        displayType: displayType,
        remindLeft: remindLeft,
      });
      if (reward.remainingPeriodQuota === 0) {
        fullDesc = Trans('campaignActivityPage.messageCouponDone');
        if (reward.resetDate !== null) {
          fullDesc = (
            <>
              {Trans('campaignActivityPage.messageCouponNotDone')}{' '}
              <DateTime datetime={reward.resetDate} />
            </>
          );
        }
        isDone = true;
      }
    }

    //CampaingInfoPage
    if (reward.remainingQuota === 0) {
      fullDescCampaingInfo = Trans('campaignInfoPage.messageCouponDone');
      if (reward.resetDate !== null) {
        fullDescCampaingInfo = (
          <>
            {Trans('campaignActivityPage.messageCouponNotDone')}{' '}
            <DateTime datetime={reward.resetDate} />
          </>
        );
      }
    }
    if (periodQuotaType !== '') {
      fullDescCampaingInfo = Trans('campaignInfoPage.messageCouponLeft', {
        displayType: displayType,
        remindLeft: remindLeft,
      });
      if (reward.remainingPeriodQuota === 0) {
        fullDescCampaingInfo = Trans('campaignInfoPage.messageCouponDone');
        if (reward.resetDate !== null) {
          fullDescCampaingInfo = (
            <>
              {Trans('campaignInfoPage.messageCouponNotDoneWithType', {
                displayType: displayType,
              })}{' '}
              <DateTime datetime={reward.resetDate} />
            </>
          );
        }
      }
    }

    if (numReward > 100) numReward = 100;

    return {
      numReward,
      remindLeft,
      displayType,
      fullDesc,
      isDone,
      fullDescCampaingInfo,
    };
  }

  getCampaign() {}

  async getCampaigns() {
    try {
      const response = await CampaignApi.GetAllActiveCampaigns();

      this.campaignList = response.data || [];
    } catch (error) {
      this.campaignList = this.initCampaignListSchema();
    }
  }

  async getSegmentedCampaigns(handleRetry: (tag: string) => void) {
    try {
      const response = await CampaignApi.GetAllSegmentedCampaigns(handleRetry);

      this.campaignSegmentedList = response.data || [];
    } catch (error) {
      this.campaignSegmentedList = this.initCampaignListSchema();
    }
  }

  async getCampaignByID(
    campaingId: string,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const response = await CampaignApi.GetCampaignByID(
        campaingId,
        handleRetry,
      );

      this.campaignInfo = response.data || this.initCampaignSchema();
    } catch (error) {
      this.campaignInfo = this.initCampaignSchema();
      throw error;
    }
  }

  getCampaignJs() {
    return toJS(this.campaignInfo);
  }

  getCampaignsJs() {
    return toJS(this.campaignList);
  }

  getSegmentedCampaignsJs() {
    return toJS(this.campaignSegmentedList);
  }
}

export default new CampaignStore();
