import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themes from './themes';
import history from './utils/History';
import mobxStore from './stores';
import App from './App';
import Facebook from 'utils/Facebook';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import { consoleLog } from 'utils/Common';
import TagManager from 'react-gtm-module';
import Loader from 'common/components/Loader';
import { config } from 'config';

i18n.on('initialized', () => {
  consoleLog('i18n initialized');
});
Facebook.initFacebook();

const tagManagerArgs = {
  gtmId: config.gtm.id,
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

const rootNode = document.getElementById('root');
ReactDOM.render(
  <MuiThemeProvider theme={themes}>
    <Provider {...mobxStore}>
      <Router history={history}>
        <Suspense fallback={<Loader show={true} />}>
          <App />
        </Suspense>
      </Router>
    </Provider>
  </MuiThemeProvider>,
  rootNode,
);

// Initialize Firebase
(window as any).firebase.initializeApp(config.firebaseConfig);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
