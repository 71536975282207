const config = {
  env: 'production',
  publicUrl: '',
  publicWeb: 'https://khunthong.kasikornbank.com',
  api: {
    ocr: 'https://khunthong.kasikornbank.com/ocr/api',
    socialbot: 'https://khunthong.kasikornbank.com/api',
    s3: 'https://khunthong.kasikornbank.com',
  },
  kplus: {
    url: 'https://kplus.kasikornbank.com',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDXY9gCD-HljMjrkNpodRJ8ReaLvItgcpE',
    authDomain: 'social-banking-productio-3c455.firebaseapp.com',
    databaseURL: 'https://social-banking-productio-3c455.firebaseio.com',
    projectId: 'social-banking-productio-3c455',
    storageBucket: 'social-banking-productio-3c455.appspot.com',
    messagingSenderId: '371048918218',
  },
  liff: {
    demo_slip: '1653842378-Y7djdRyp',
    crop_image: '1653842378-2N717kOE',
    pay_type: '1653842378-R2lmlJAk',
    bill_assign: '1653842378-0JWKWkaQ',
    add_account: '1653842378-Gdm6mazB',
    summary: '1653842378-VxpYp7xJ',
    invite_friend: '1653842378-rVJEJAxP',
    account_management: '1653842378-W9lwlxr8',
    medal: '1653842378-8d4g4L5Z',
    donation: '1653842378-gwe4eXaB',
    donation_info: '1653842378-Qw3b31qo',
    rollcall: '1653842378-ygRJRzG4',
    campaign_landing: '1653842378-PBDwDjvG',
    campaign_activity: '1653842378-glmnm9qG',
    schedule_create: '1653842378-W21p1j0D',
    schedule_summary: '1653842378-aqdgd4VX',
    bill_summary: '1653842378-lg6R6GpO',
    cancel_info: '1653842378-J3mMmE82',
    multiple_payment_slip: '1653842378-akZWZwNp',
    tutorial: '1653842378-1YJ7JODN',
    reward_info: '1653842378-9x2j2bk3',
    redeem_location_code: '1653842378-9zxqxGLZ',
    referrer: '1653842378-M5qWq238',
    referree: '1653842378-YgqrqV0l',
    create_bill_template: '1653842378-k7KpKOVq',
    bill_center: '1653842378-RqgXgDno',
    private_bill_summary: '1653842378-J63A3oWO',
    init_liff_full: '1653842378-GEyWyNZo',
    init_liff_tall: '1653842378-MnDLD9a7',
    robot_login: '',
  },
  vconsole: {
    enabled: false,
  },
  cypress: {
    enabled: false,
  },
  development: {
    allowedDesktop: false,
    allowedRobot: false,
  },
  publickeyBot: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApASIFXNq7Bmjhbtmh2Qe
  87tN/Kl/hKIJ1FdGB841n0mzXSWcfJLLCqPc0LNgHHmRLxe47WQbMCrxXoUOVK+r
  rUXwYdzQ10yVVU7ODgZW5XoaImf5NJTkgJPjFV4iW4fSqW1tsUbGwbJ3zlBmEo6F
  UfdzChMcGS0UFlUIQczDfCnZVXiTtveeeB2ikCxJj+JQGNtxwC5YHl5BpH2DGcyK
  V4DW/UvuyhfMqDwfqf/vf2cjmDyB/BqOb59xWIhdGbP789K5kQ5uvQKxypRM3A67
  G3l8KBrXyXzVwlziNGr6B6K94MyUDiC0nbgKYAszTrwrBmEUP/l6QjxI/n4QRkN/
  nQIDAQAB`,
  publickeyOcr: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4gMgWdQl/J2Kq3qH3Wpw
  L79EUnoTJvfPvMDS/5XTkGLt+UDBJ5Yz3l++w0DSBZKKXeS1Lg0zhVFp/2NYD/bs
  0SOelE8kOVJgsguB+SDVioSrXKF6YaP5P84QMHhx//MwxK8pIqKT8FbDvZYjyDDK
  /Vqt4IwO3tDpYeTKoR1QKJs27AdxLBzzMbEr+Gn/pmIPUHzRDecNlSkbEDO+YJUI
  rgSbnAFxg46xhfzjWyjdkRJLr82icV5hxJB86E/62X3kqtXBb76Tpt4wGU3DNdGB
  j4CRxQj3oxRZfYe7n/LIIYPJuggdqrdTfki3SFCN3N4OxG0BQgVHQq0/6g66W4n6
  YwIDAQAB`,
  authentication: {
    enabled: true,
    encrypt: true,
  },
  gtm: {
    id: 'GTM-KDQ9WVK',
  },
  fb: {
    appId: '538196053678725',
    version: 'v2.9',
  },
  line: {
    botBasicId: '~@KhunThong',
    botBasicIdBypass: '@KhunThong',
  },
  bill: {
    defaultExpiredDuration: 7,
    defaultExtendedDuration: 1,
  },
  privateBill: {
    noteExceedLimit: 200,
  },
};

export default config;
