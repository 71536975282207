export const AbTestingConst = {
  GetVariantTimeout: 1000,
};

export const ElementId = {
  ButtonOrder: 'button_order',
  KplusAccount: 'k_plus_account',
  ReceivingAccount: 'receiving_account',
  VerifyKplusRegisterNextButton: 'verify_k_plus_register_next_button',
  VerifyKplusRegisterBackButton: 'verify_k_plus_register_back_button',
  ForUserNoKplus: 'for_user_no_kplus',
  VerifyKplusNextButton: 'verify_k_plus_next_button',
  VerifyKplusBackButton: 'verify_k_plus_back_button',
  AddBankAccountNextButton: 'add_bank_account_next_button',
  AddBankAccountBackButton: 'add_bank_account_back_button',
  AddBankAccountConfirmBacktButton: 'add_bank_account_confirm_back_button',
  TermRejectButton: 'term_reject_button',
  TermAcceptButton: 'term_accept_button',
};

export const FlagName = {
  UserCreatedDate: 'user_created_date',
  HaveKplusAccount: 'have_k_plus_account',
  HaveReceivingAccount: 'have_receiving_account',
};

export const RequestGetExperimentFieldName = {
  Os: 'os',
  UserAgent: 'user_agent',
  Day: 'day',
  PhoneSize: 'phone_size',
};

export const PathUrl = {
  KplusAccount: 'k_plus_account',
  ReceivingAccount: 'receiving_account',
};

// From, also refer to PathUrl, but from the external flow
export const From = {
  RichMenu: 'rich_menu',
  StartRegister: 'start_register',
};

export const PageUrl = {
  TermAndCondition: '/termandcondition',
};

export const ActionKey = {
  State: 'state',
  PathUrl: 'path_url',
  BankCode: 'bank_code',
  ErrorCode: 'error_code',
  ErrorMessage: 'error_message',
};

export const ActionValue = {
  Clicked: 'clicked',
  Success: 'success',
  Enter: 'enter',
  Leave: 'leave',
  Next: 'next',
  Error: 'error',

  PromptPay: 'prompt_pay',
};

export const ActionType = {
  Start: 'START',
  Enter: 'ENTER',
  Next: 'NEXT',
  Leave: 'LEAVE',
  Complete: 'COMPLETE',
  Error: 'ERROR',
};
