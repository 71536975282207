import ApiManager from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';

export class DonateDestinationApi {
  async getDestinations() {
    // const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        // h: {
        //   authorization: `Bearer ${token}`,
        // },
        ep: `${Api.DonateDestination}`,
      });
      // const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      // return resp;
      return result;
    } catch (error) {
      // const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      // throw resp;
      throw error;
    }
  }

  async getDestination(id: string) {
    // const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        // h: {
        //   authorization: `Bearer ${token}`,
        // },
        ep: `${Api.DonateDestination}/${id}`,
      });
      // const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      // return resp;
      return result;
    } catch (error) {
      // const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      // throw resp;
      throw error;
    }
  }

  async getDestinationFlex() {
    // const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        // h: {
        //   authorization: `Bearer ${token}`,
        // },
        ep: `${Api.DonateDestinationFlex}`,
      });
      // const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      // return resp;
      return result;
    } catch (error) {
      // const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      // throw resp;
      throw error;
    }
  }
}

export default new DonateDestinationApi();
