import { observable, toJS } from 'mobx';
import sumBy from 'lodash/sumBy';
import Api from 'api/BillApi';
import OCRApi from 'api/OCRApi';
import {
  consoleLog,
  isArrayEmpty,
  isNumber,
  numberWithCommas,
} from 'utils/Common';
import {
  IBillStore,
  IBillModel,
  IBillMemberModel,
  IBillItemModel,
  IBillTotalOCR,
} from './BillStore.d';
import {
  BillStatus,
  PaymentMethod,
  BillItemType,
  BillMemberStatus,
} from 'definitions/constant';
import { Trans } from 'common/components/Translate';
import sortBy from 'lodash/sortBy';
import { createSessionOCR } from 'utils/Session';
import { getBillTotal } from 'common/functions/BillCommon';

export class BillStore implements IBillStore {
  @observable
  billData: IBillModel;
  @observable
  billDataForUpdate: IBillModel;
  @observable
  messageId: string;
  @observable
  isLoading: boolean;
  @observable
  errorCode: string;
  @observable
  statusStage: string;
  @observable
  firebaseToken: string;
  @observable
  billTotalOCR: IBillTotalOCR;
  isCheckDuplicate: boolean;
  @observable
  billImage: string;
  isInitDefaultBillItem: boolean;
  isAlreadyPopupOCRError: boolean;
  isAlreadyShowNotiAccChange: boolean;

  constructor() {
    this.resetAllData();
    this.isAlreadyPopupOCRError = false;
    this.isAlreadyShowNotiAccChange = false;
  }

  resetData() {
    this.billData = this.initBillSchema();
    this.messageId = '';
    this.isLoading = false;
    this.errorCode = '';
  }

  resetAllData() {
    this.billData = this.initBillSchema();
    this.messageId = '';
    this.isLoading = false;
    this.errorCode = '';
    this.statusStage = '';
    this.firebaseToken = '';
    this.billTotalOCR = {
      isLoading: false,
      total: 0,
    };
    this.isCheckDuplicate = false;
    this.isInitDefaultBillItem = false;
  }

  setBillData(key: string, value: any) {
    this.billData[key] = value;
  }

  setMessageId(messageId: string) {
    this.messageId = messageId;
  }

  setErrorCode(errorCode: string) {
    this.errorCode = errorCode;
  }

  getMessageIdJS() {
    return toJS(this.messageId);
  }

  setStatusStage(status: string) {
    this.statusStage = status;
  }

  getStatusStage() {
    return toJS(this.statusStage);
  }

  getBillDataJS() {
    return toJS(this.billData);
  }

  getBillDataForUpdateJS() {
    return toJS(this.billDataForUpdate);
  }

  setFirebaseToken(token: string) {
    this.firebaseToken = token;
  }

  getFirebaseToken() {
    return toJS(this.firebaseToken);
  }

  async getBillData(id: string) {
    this.isLoading = true;
    try {
      const result = await Api.getBillById(id);
      this.billData = result.data || this.initBillSchema();
      this.convertFromAPI();
    } catch (error) {
      this.billData = this.initBillSchema();
    } finally {
      this.isLoading = false;
    }
  }

  async getBillNotIncludeItemMember(id: string) {
    this.isLoading = true;
    try {
      await this.getBillData(id);
      this.billData.items.forEach((item) => (item.members = []));
    } catch (error) {
      this.billData = this.initBillSchema();
    } finally {
      this.isLoading = false;
    }
  }

  async getOriginalImageBill(msgId: string) {
    this.isLoading = true;
    let orgImgBase64 = '';
    try {
      const res = await Api.getOriginalImageBill(msgId);
      orgImgBase64 = res.data;
    } catch (error: any) {
      consoleLog('get original image error: ', error.message);
    } finally {
      this.isLoading = false;
    }
    return orgImgBase64;
  }

  async getItemsFromImage(data: any) {
    this.isLoading = true;
    this.errorCode = '';
    try {
      const res = await OCRApi.getItemsFromImage(data);
      if (res && res.data) {
        if (res.data.error) {
          this.errorCode = res.data.error.code;
        }
        if (res.data.output && !isArrayEmpty(res.data.output.items)) {
          const items = [];
          let total = 0;
          let count = 1;
          res.data.output.items.map((item) => {
            item.id = count++;
            if (!isNumber(item.price)) {
              item.price = 0;
            } else {
              item.price = +item.price;
            }
            total += item.price;
            items.push(item);
          });
          this.setBillData('items', items);
          this.setBillData('total', total);
        }
      }
    } catch (ex: any) {
      consoleLog('get items from image error: ', ex.message);
    } finally {
      this.isLoading = false;
    }
  }

  setBillMembersStatus(status: string, ownerStatus?: string) {
    const billData = this.getBillDataJS();

    billData.members.forEach((member) => {
      member.status = status;
      if (billData.ownerMemberId === member.memberId && ownerStatus) {
        member.status = ownerStatus;
        member.paymentMethod = PaymentMethod.Owner;
        member.paid = member.total;
      }
    });
    this.setBillData('members', billData.members);
  }

  setItemIDtoUndefined() {
    const billData = this.getBillDataJS();
    billData.items.forEach((item) => {
      item.id = undefined;
    });
    this.setBillData('items', billData.items);
  }

  async createBill() {
    this.isLoading = true;
    try {
      const billData1 = this.getBillDataJS();
      console.log('createBill()', billData1);

      await this.convertUIToAPI();

      const billData = this.getBillDataForUpdateJS();
      console.log('createBill ===>', billData);
      const result = await Api.createBill(billData);
      if (result && result.data) {
        console.log('create bill', result.data);
        this.billData = result.data;
        await this.convertFromAPI();
      }
      return { isSuccess: true, message: '' };
    } catch (ex: any) {
      consoleLog('create bill error: ', ex.message);
      return { isSuccess: false, message: ex.message };
    } finally {
      this.isLoading = false;
    }
  }

  async updateBill() {
    this.isLoading = true;
    try {
      await this.convertUIToAPI();
      const billData = this.getBillDataForUpdateJS();
      console.log('update bill data ==========>', billData);
      return await Api.updateBill(billData, billData.id);
    } catch (ex: any) {
      consoleLog('update bill error: ', ex);
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async cancelBill() {
    this.isLoading = true;
    try {
      const billData = this.getBillDataJS();
      await Api.updateStatusBill({ Status: BillStatus.Cancel }, billData.id);
      return true;
    } catch (ex: any) {
      consoleLog('update bill error: ', ex.message);
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async updateBillItem(itemId: string, billItem: IBillItemModel) {
    this.isLoading = true;
    try {
      const billData = this.getBillDataJS();
      const result = await Api.updateBillItem(billData.id, itemId, billItem);
      return result;
    } catch (ex) {
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async updateBillItemsMembers() {
    this.isLoading = true;
    try {
      const rawBillData = this.getBillDataJS();
      console.log('rawBillData:', rawBillData);

      const cleanedItems = rawBillData.items.map((item) => {
        // Remove item id for the new items
        if (item.id && item.id.length >= 4) {
          const code = item.id.substring(0, 4);
          if (code === 'temp') {
            item.id = undefined;
          }
        }

        if (Boolean(item.id)) {
          if (
            item.id.includes(BillItemType.ServiceCharge) ||
            item.id.includes(BillItemType.VAT) ||
            item.id.includes(BillItemType.Discount) ||
            item.id.includes(BillItemType.VAT_SERVICE_DISCOUNT)
          ) {
            item.id = undefined;
          }
        }

        // Remove members under the items
        item.members = [];

        return item;
      });

      this.setBillData('items', cleanedItems);

      const cleanedBillData = this.getBillDataJS();
      console.log('cleanedBillData:', cleanedBillData);

      await this.convertUIToAPI();
      const billData = this.getBillDataForUpdateJS();

      // Recalculate total from items here
      billData.total = getBillTotal(billData);

      const result = await Api.updateBillItemsMembers(billData.id, billData);
      return !!result && !!result.data ? result.data : result;
    } catch (ex: any) {
      throw !!ex && !!ex.data ? ex.data : ex;
    } finally {
      this.isLoading = false;
    }
  }

  async verifyDuplicateBill(lineGroupId: string, messageId: string) {
    this.isLoading = true;
    let isDuplicate = false;
    try {
      if (!this.isCheckDuplicate) {
        const res = await Api.verifyDuplicateBill(lineGroupId, messageId);
        if (res && res.data && res.data.length > 0) {
          isDuplicate = true;
        }
      }
    } catch (ex: any) {
      consoleLog('catch exeption ', ex.message);
    } finally {
      this.isLoading = false;
      this.isCheckDuplicate = true;
    }
    return isDuplicate;
  }

  findOwnerBankAccount() {
    const bill = this.getBillDataJS();
    const accountsBillOwner = bill.members.map((m) => {
      if (m.memberId === bill.ownerMemberId) {
        return m.profile;
      }
    })[0];
    return accountsBillOwner;
  }

  getValDiff(
    editList: string[],
    memberID: string,
    members: IBillMemberModel[],
    val: number,
  ) {
    let valDiff = 0.0 as number;
    members.forEach((member, index) => {
      const list = editList.filter((str) => str === member.memberId);
      if (member.memberId !== memberID && list.length > 0) {
        if (typeof valDiff === 'string') {
          valDiff = parseFloat(valDiff);
        }
        if (typeof member.total === 'string') {
          member.total = parseFloat(member.total);
        }
        valDiff = (valDiff + member.total) as number;
      }
    });
    return (valDiff * 1 + val * 1) as number;
  }

  getTotalPerMember(outstandingTotal: number, totalMember: number) {
    return Math.ceil(outstandingTotal / totalMember);
  }

  getActualTotal(members: IBillMemberModel[]) {
    return sumBy(members, (member) => {
      if (!member.isDummy) {
        return +member.total;
      }
    });
  }

  getTotalPriceOnMembers(members: IBillMemberModel[], memberIdList: string[]) {
    return sumBy(members, (member) => {
      if (memberIdList.includes(member.memberId)) {
        return +member.total;
      }
    });
  }

  getTotalPriceByItems() {
    const totalPrice = sumBy(this.billData.items, (item) => {
      const price = item.price || 0;
      return item.type && item.type === BillItemType.Discount ? -price : price;
    });

    return totalPrice;
  }

  getHelperTextChangedTotal(
    changedTotal: number,
    actualTotal: number,
    showActualTotal?: boolean,
  ) {
    if (
      actualTotal !== 0 &&
      changedTotal !== 0 &&
      changedTotal !== actualTotal
    ) {
      if (showActualTotal) {
        return Trans('message.Remark.actualTotal', {
          total: numberWithCommas(actualTotal),
        });
      } else {
        return Trans('message.Remark.changedTotal', {
          total: numberWithCommas(changedTotal),
        });
      }
    }
    return '';
  }

  initBillSchema() {
    return {
      id: '',
      name: '',
      lineGroupId: '',
      items: [],
      members: [],
      account: {
        no: '',
        name: '',
        isAccountPromptpay: false,
        bankCode: '',
      },
      total: 0,
      source: '',
      type: '', // Adjust , American
      status: '',
      ownerMemberId: '',
      createdDate: undefined,
      updatedDate: undefined,
      onSubmitted: false,
      splittingType: '',
    };
  }

  getMembers(bill: IBillModel) {
    const membersAssign = [];
    const membersWaiting = [];
    const membersWaitingCash = [];
    let membersPaid = [];
    let membersPaidByCash = [];
    let membersPaidNotFull = [];

    if (bill && bill.members) {
      bill.members.forEach((member) => {
        if (
          member.status === BillMemberStatus.Assigned ||
          (member.status === BillMemberStatus.Waiting &&
            member.paymentMethod === PaymentMethod.KPlus)
        ) {
          membersAssign.push(member);
        } else if (
          member.status === BillMemberStatus.Paid &&
          member.paymentMethod !== PaymentMethod.Cash
        ) {
          membersPaid.push(member);
        } else if (
          member.status === BillMemberStatus.Paid &&
          member.paymentMethod === PaymentMethod.Cash
        ) {
          membersPaidByCash.push(member);
        } else if (
          member.status === BillMemberStatus.Waiting &&
          (member.paymentMethod === PaymentMethod.Cash ||
            member.paymentMethod === PaymentMethod.Transfer)
        ) {
          membersWaiting.push(member);
        }
        if (member.status !== BillMemberStatus.Paid && member.paid > 0) {
          membersPaidNotFull.push(member);
        }
        if (
          member.status === BillMemberStatus.Waiting &&
          member.paymentMethod === PaymentMethod.Cash
        ) {
          membersWaitingCash.push(member);
        }
      });
    }

    membersPaid = sortBy(membersPaid, 'updatedDate');
    membersPaidByCash = sortBy(membersPaidByCash, 'updatedDate');
    membersPaidNotFull = sortBy(membersPaidNotFull, 'updatedDate');
    membersPaid = [...membersPaid, ...membersPaidByCash, ...membersPaidNotFull];

    return {
      membersPaid,
      membersPaidByCash,
      membersPaidNotFull,
      membersAssign,
      membersWaiting,
      membersWaitingCash,
    };
  }

  getCurrentParent(id: string) {
    const { members } = this.getBillDataJS();
    return members.find((m) => m.memberId === id);
  }

  async convertFromAPI() {
    const billDataClone = JSON.parse(JSON.stringify(this.billData));
    await this.billData.members.map((member) => {
      const dummiesLength = (!!member.dummies && member.dummies.length) || 0;

      if (member.dummies && dummiesLength > 0) {
        member.dummies.forEach((dummy, indexDum) => {
          const parent = this.getCurrentParent(dummy.parentMemberId);
          const currentParentName = parent.profile
            ? parent.profile.name
            : parent.name;

          let statusDummy = parent.status;
          if (parent.paymentMethod === PaymentMethod.Owner) {
            statusDummy = BillMemberStatus.Paid;
          }

          let lastDummy = false;
          if (dummiesLength === indexDum + 1) {
            lastDummy = true;
          }

          const dumMember = {
            id: dummy.id,
            memberId: dummy.id,
            lastDummy,
            total: dummy.total,
            name: dummy.dummyName,
            paymentMethod: parent.paymentMethod,
            status: statusDummy,
            displayName:
              dummy.dummyName +
              `${Trans('InviteMoreFriendPage.comeWith', {
                name: currentParentName,
              })} `,
            image: dummy.dummyImageUrl,
            parentMemberId: dummy.parentMemberId,
            parentMemberInfo: parent,
            isDummy: true,
            profile: {
              id: dummy.id,
              image: dummy.dummyImageUrl,
              name: dummy.dummyName,
            },
            updatedDate: parent.updatedDate,
          };
          let i = billDataClone.members.findIndex(
            (d) => d.memberId === member.memberId,
          );
          billDataClone.members.splice(i + 1 + indexDum, 0, dumMember);
        });
      }
    });
    this.billData = billDataClone;
  }

  async convertUIToAPI() {
    const billDataClone = this.getBillDataJS();
    billDataClone.members.forEach((member) => {
      member.dummies = [];
    });

    await this.billData.members.forEach((member) => {
      if (member.isDummy !== undefined && member.isDummy === true) {
        const dumMember = {
          id: member.id,
          total: member.total,
          dummyName: member.name,
          dummyImageUrl: member.image,
          parentMemberId: member.parentMemberId,
        };

        let i = billDataClone.members.findIndex(
          (d) => d.memberId === member.parentMemberId,
        );
        billDataClone.members[i].dummies.push(dumMember);
        billDataClone.members[i].total += member.total;
        i = billDataClone.members.findIndex((d) => d.memberId === member.id);
        billDataClone.members.splice(i, 1);
      }
    });

    this.billDataForUpdate = billDataClone;
  }

  async confrimStillUsed(billId: string, handleRetry: (tag: string) => void) {
    try {
      const res = await Api.confrimStillUsed(billId, handleRetry);

      console.log('STORE confrimStillUsed result', res);
      if (res) {
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  setBillTotalOCRData(key: string, value: any) {
    this.billTotalOCR[key] = value;
  }

  getBillTotalOCR = async (messageId: string) => {
    if (messageId) {
      this.setBillTotalOCRData('isLoading', true);

      const resultOcr = await createSessionOCR();
      if (resultOcr !== '') {
        this.setBillTotalOCRData('isLoading', false);
        consoleLog('Create session OCR error in loading bill total');
        return;
      }

      const originalImage = await this.getOriginalImageBill(messageId);

      if (!originalImage) {
        this.setBillTotalOCRData('isLoading', false);
        return;
      }

      try {
        const res = await OCRApi.getTotalFromImage({
          full_image: originalImage,
        });

        if (res && res.data) {
          // The API always returns total in string, and might be NOT a number
          const totalNum =
            !isNaN(res.data.total) && parseInt(res.data.total, 10);
          const total = totalNum && totalNum > 0 ? totalNum : 0;
          this.setBillTotalOCRData('total', total);
        }
      } catch (ex: any) {
        consoleLog('get items from image error: ', ex.message);
        console.log(ex);
      } finally {
        this.setBillTotalOCRData('isLoading', false);
      }
    }
  };

  getBillTotalOCRJs = (): IBillTotalOCR => {
    return toJS(this.billTotalOCR);
  };

  setBillImage = (image: string) => {
    this.billImage = image;
  };

  getBillImage = () => {
    return toJS(this.billImage);
  };

  getIsAlreadyPopupOCRError = () => {
    return this.isAlreadyPopupOCRError;
  };

  setIsAlreadyPopupOCRError = (isAlreadyPopup: boolean) => {
    this.isAlreadyPopupOCRError = isAlreadyPopup;
  };
}

const billStore = new BillStore();
export default billStore;
