import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class ImageApi {
  async processAndSendSlip(
    dataBase64: string,
    lineUserId: string,
    groupId: string,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        {
          data: dataBase64,
          lineUserId,
          groupId,
        },
        ServiceName.bot,
      );

      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Images}/slip`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async uploadMedalImage(
    dataBase64: string,
    lineUserId: string,
    groupId: string,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        {
          data: dataBase64,
          lineUserId,
          groupId,
        },
        ServiceName.bot,
      );

      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Images}/medal`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new ImageApi();
