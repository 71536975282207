import { observable, toJS } from 'mobx';
import PostBackEventApi from 'api/PostBackEventApi';
import set from 'lodash/set';
import { IPostBackVerifySlipStore } from './PostBackVerifySlipStore.d';
import { IBillsPriority } from './MultipleBill/BillSummary.d';
import BillApi from 'api/BillApi';
import { BillPrioritiesAction } from 'definitions/constant';

export class PostBackVerifySlipStore implements IPostBackVerifySlipStore {
  @observable
  info: IBillsPriority[];

  @observable
  privateBillVerifySlip: IBillsPriority[];

  @observable
  isLoading: boolean;

  constructor() {
    this.info = [] as IBillsPriority[];
    this.isLoading = false;
  }

  setInfo(key: string, val: any) {
    const info = this.getInfoJs();
    set(info, key, val);
    this.info = info;
  }

  getInfoJs() {
    return toJS(this.info);
  }

  initSchema() {
    return {
      bill: {
        id: '',
        name: '',
        lineGroupId: '',
        items: [],
        members: [],
        account: {
          no: '',
          name: '',
          isAccountPromptpay: false,
          bankCode: '',
        },
        total: 0,
        source: '',
        type: '', // Adjust , American
        status: '',
        ownerMemberId: '',
        createdDate: undefined,
        updatedDate: undefined,
        onSubmitted: false,
        splittingType: '',
      },
      type: '',
      postBackId: '',
      imageId: '',
      slipImg: '',
      billInfo: [{ billName: '', billId: '', amount: 0 }],
      payerInfo: { memberId: '' },
      verifySlipInfo: {
        amt: 0,
        payAccNo: '',
        recvAccName: '',
        recvAccNo: '',
        recvBankCd: '',
        recvProxyType: '',
        recvProxyNo: '',
        slipQrCode: '',
      },
    };
  }

  async getSlipImages(priorityBills: IBillsPriority[]) {
    await Promise.all(
      priorityBills.map(async (bill) => {
        if (bill.type === BillPrioritiesAction.SlipFail) {
          if (bill.imageUrl) {
            bill.slipImg = bill.imageUrl;
          } else if (bill.imageId) {
            const res: any = await BillApi.getOriginalImageBill(bill.imageId);
            if (res.data) {
              bill.slipImg = res.data;
            }
          }
        }
      }),
    );
  }

  async getPostBackInfoByBillId(billId: string) {
    this.isLoading = true;
    try {
      const result = await PostBackEventApi.getPostBackInfoByBillId(billId);
      await this.getSlipImages(result.data);
      this.info = result.data || [];
    } catch (error) {
      this.info = [];
    } finally {
      this.isLoading = false;
    }
  }

  async getPostBackInfoByLineUserId(lineUserId: string, lineGroupId: string) {
    this.isLoading = true;
    try {
      const result = await PostBackEventApi.getPostBackInfoByLineUserId(
        lineUserId,
        lineGroupId,
      );
      await this.getSlipImages(result.data);
      this.info = result.data || [];
    } catch (error) {
      this.info = [];
    } finally {
      this.isLoading = false;
    }
  }

  async getPostBackInfoByPrivateBillId(privateBillId: string) {
    this.isLoading = true;
    try {
      const result = await PostBackEventApi.getPostBackInfoByPrivateBillId(
        privateBillId,
      );
      await this.getSlipImages(result.data);
      this.privateBillVerifySlip = result.data || [];
    } catch (error) {
      this.privateBillVerifySlip = [];
    } finally {
      this.isLoading = false;
    }
  }

  async getPostBackInfoPrivateByLineUserId() {
    this.isLoading = true;
    try {
      const result =
        await PostBackEventApi.getPostBackInfoPrivateByLineUserId();
      await this.getSlipImages(result.data);
      this.privateBillVerifySlip = result.data || [];
    } catch (error) {
      this.privateBillVerifySlip = [];
    } finally {
      this.isLoading = false;
    }
  }

  getPrivateBillVerifySlipJs() {
    return this.privateBillVerifySlip;
  }
}

export default new PostBackVerifySlipStore();
