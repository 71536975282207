import { IGetDeepLinkGenReq } from 'stores/DeepLinkGenStore.d';
import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class DeepLinkGenApi {
  async getDeepLinkGenByRefId(data: IGetDeepLinkGenReq) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.DeepLinkGen}/data?kth_ref=${data.refId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new DeepLinkGenApi();
