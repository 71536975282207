import {
  str2ab,
  ab2str,
  arrayBufferToString,
  arrayBufferToHex,
} from 'utils/Common';
import config from 'config';
import humps from 'humps';
import { ServiceName, CommonError } from 'definitions/constant';

const crypto = window.crypto;
const subtle = crypto.subtle;
let myKeyPair;
let myKeyPairOCR;
let serverMessage;
let serverMessageOcr;

export function setSharedKey(message, service: string) {
  switch (service) {
    case ServiceName.bot:
      serverMessage = message;
      break;
    case ServiceName.ocr:
      serverMessageOcr = message;
      break;
    default:
      break;
  }
}

export async function getSharedKey(service: string) {
  let servMessage;
  let decrypted;
  switch (service) {
    case ServiceName.bot:
      servMessage = serverMessage;
      decrypted = await decrypt(myKeyPair.privateKey, servMessage);
      break;
    case ServiceName.ocr:
      servMessage = serverMessageOcr;
      decrypted = await decrypt(myKeyPairOCR.privateKey, servMessage);
      break;
    default:
      break;
  }
  return JSON.parse(ab2str(decrypted));
}

export async function exchangeKeypair(service: string) {
  let publicKeyServer = '';
  switch (service) {
    case ServiceName.bot:
      publicKeyServer = config.publickeyBot;
      break;
    case ServiceName.ocr:
      publicKeyServer = config.publickeyOcr;
      break;
    default:
      break;
  }
  const clientSalt = await genKey32Bytes();
  const clientShKey = await genKey32Bytes();
  const keyPair = await generateKey();
  const pk = await exportPublicKey(keyPair.publicKey);
  // const sk = await exportPrivateKey(keyPair.privateKey);
  const aesKey = await importAESKey(clientShKey);
  const aesEncrypted = await encryptAES(aesKey, pk);
  const hash = await hashHMAC(clientSalt, aesEncrypted);
  const reqData = `${hash}${aesEncrypted}`;
  const base64 = btoa(reqData);
  const serverPKObj = await importPKPem(publicKeyServer);
  const token = await encrypt(
    serverPKObj,
    JSON.stringify({
      shareKey: clientShKey,
      salt: clientSalt,
    }),
  );
  switch (service) {
    case ServiceName.bot:
      myKeyPair = keyPair;
      break;
    case ServiceName.ocr:
      myKeyPairOCR = keyPair;
      break;
    default:
      break;
  }

  return {
    keyPair,
    token,
    dataAESEncrypted: base64,
  };
}

export async function encryptAESMessage(data: any, service: string) {
  if (config.authentication.encrypt) {
    data = humps.decamelizeKeys(data);
    const dataJsonEncode = encodeURIComponent(JSON.stringify(data));
    const security = await getSharedKey(service);
    const aesKey = await importAESKey(security.shareKey);
    const aesEncrypted = await encryptAES(aesKey, dataJsonEncode);
    const hash = await hashHMAC(security.salt, aesEncrypted);
    const reqData = `${hash}${aesEncrypted}`;
    const base64 = btoa(reqData);

    return { data: base64 };
  } else {
    return data;
  }
}

export async function decryptAESJSONMessage(
  data,
  service: string,
  options?: {
    isEncoded64: boolean;
  },
) {
  if (config.authentication.encrypt) {
    let messageEncrypted = data.data ? data.data : data;
    let iv = data.iv;

    if (iv === CommonError.FailToFetch) {
      return CommonError.FailToFetch;
    }

    if (options && options.isEncoded64) {
      messageEncrypted = window.atob(messageEncrypted);
      iv = window.atob(iv);
    }

    const output = messageEncrypted.substring(64, messageEncrypted.length);
    // const ivRes = output.slice(0, 24).match(/.{2}/g).map(byte => parseInt(byte, 16));
    const msg = output.substring(12, output.length);
    // const msg = atob(output.slice(24));
    const security = await getSharedKey(service);
    const aesKey = await importAESKey(security.shareKey);
    const decrypted = await decryptAES(aesKey, msg, iv);
    let json = JSON.parse(decodeURIComponent(decrypted));
    json = humps.camelizeKeys(json);
    return json;
  } else {
    return data;
  }
}

export async function generateKey() {
  try {
    return await subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt'],
    );
  } catch (err) {
    console.log('error generateKey', err);
  }
}

export async function importPKPem(keyPem) {
  //   const pemHeader = "-----BEGIN PUBLIC KEY-----";
  //   const pemFooter = "-----END PUBLIC KEY-----";
  //   const pemContents = keyPem.substring(
  //     pemHeader.length,
  //     keyPem.length - pemFooter.length
  //   );
  try {
    const pemContents = keyPem;
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);

    return await subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
  } catch (err) {
    console.log('error :', err);
  }
}

export async function encrypt(pk, data) {
  const encrypted = await subtle.encrypt(
    {
      name: pk.algorithm.name || 'RSA-OAEP',
    },
    pk,
    str2ab(data),
  );

  // consoleLog('RSA ENCRYPTED =----->', arrayBufferToString(encrypted));
  // consoleLog(encrypted);

  return encrypted;
}

export async function decrypt(sk, data) {
  let decryptKey = sk;
  if (!sk) {
    decryptKey = myKeyPair.privateKey;
  }

  const decrypted = await subtle.decrypt(
    {
      name: decryptKey.algorithm.name || 'RSA-OAEP',
    },
    decryptKey,
    str2ab(data),
  );

  // consoleLog('RSA DECRYPTED =----->', arrayBufferToString(decrypted));
  // consoleLog(decrypted);

  return decrypted;
}

export async function exportPublicKey(key) {
  const exported = await subtle.exportKey('spki', key);
  const exportedAsString = ab2str(exported);
  const exportedAsBase64 = window.btoa(exportedAsString);
  const pemExported = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;

  // return exportedAsBase64;
  return pemExported;
}

export async function exportPrivateKey(key) {
  const exported = await subtle.exportKey('pkcs8', key);
  const exportedAsString = ab2str(exported);
  const exportedAsBase64 = window.btoa(exportedAsString);
  const pemExported = `-----BEGIN PRIVATE KEY-----\n${exportedAsBase64}\n-----END PRIVATE KEY-----`;

  return pemExported;
}

export async function importAESKey(key) {
  // @ts-ignore
  return await window.crypto.subtle.importKey(
    'raw',
    str2ab(key),
    // @ts-ignore
    {
      name: 'AES-GCM',
    },
    true,
    ['encrypt', 'decrypt'],
  );
}

export async function importSaltKey(key) {
  return await subtle.importKey(
    'raw',
    str2ab(key),
    {
      name: 'HMAC',
      hash: { name: 'SHA-256' },
    },
    false,
    ['sign', 'verify'],
  );
}

export async function hashHMAC(keyText, data) {
  const key = await importSaltKey(keyText);
  const signature = await subtle.sign('HMAC', key, str2ab(data));

  return arrayBufferToHex(signature);
}

export async function genKey32Bytes() {
  const ran = (await crypto.getRandomValues(new Uint8Array(32))) as any;
  const ranStr = String.fromCharCode.apply(null, ran);

  return window.btoa(ranStr).substring(0, 32);
}

export async function encryptAES(key, message) {
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const ivStr = String.fromCharCode.apply(null, iv as any);

  //   const ivStr = new TextDecoder("utf-8").decode(iv);

  const encrypted = await subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    key,
    str2ab(message),
  );

  return `${ivStr}${arrayBufferToString(encrypted)}`;
}

export async function decryptAES(key, ciphertext, iv) {
  try {
    const decrypted = await subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: str2ab(iv),
      },
      key,
      str2ab(ciphertext),
    );
    return arrayBufferToString(decrypted);
  } catch (error) {
    console.log('error decryptAES', error);
  }
}
