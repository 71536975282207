import React, { Component } from 'react';
import { Trans } from 'common/components/Translate';
import { withTranslation } from 'react-i18next';
import css from './home.module.scss';
import ErrorPage from 'common/components/ErrorPage';
import config from 'config';

export class HomePage extends Component {
  render() {
    return (
      <ErrorPage
        errorStyle={css.displaySuccessText}
        imageComponent={
          <div className={css.flexImage}>
            <img
              className={css.logokhunthong}
              src={`${config.api.s3}/static/images/khunthong.png`}
            />
          </div>
        }
        message={Trans('homePage.titleText')}
      />
    );
  }
}

export default withTranslation()(HomePage as any) as any;
