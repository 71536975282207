import { observable, toJS } from 'mobx';
import set from 'lodash/set';
import { IExampleStore, IExampleModel } from './ExampleStore.d';
import ExampleApi from 'api/ExampleApi';

export class ExampleStore implements IExampleStore {
  @observable info: IExampleModel;

  constructor() {
    this.info = {
      title: '',
      items: [{ id: 1, text: 'Item A' }],
    };
  }

  async getInfo() {
    this.info = await ExampleApi.getInfo();
  }

  async updateInfo(info: IExampleModel) {
    await ExampleApi.updateInfo(info);
  }

  getInfoJs() {
    return toJS(this.info);
  }

  setInfo(key: string, val: any) {
    const info = this.getInfoJs();
    set(info, key, val);
    this.info = info;
  }

  logInfo() {}
}

export default new ExampleStore();
