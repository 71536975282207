import { IScheduleInfoModel } from './ScheduleStore.d';
import { DurationType } from './../definitions/constant';
import { toJS } from 'mobx';
import { IScheduleInfoStore } from './ScheduleInfoStore.d';
import { Trans } from 'common/components/Translate';

export class ScheduleInfoStore implements IScheduleInfoStore {
  getDurationListJS() {
    return toJS([
      {
        key: 0,
        value: 1,
        type: DurationType.Weekly,
        icon: 'MdLoop',
        name: {
          th: Trans('durationSelectList.monthUnit', {
            number: 1,
            time: Trans('durationSelectList.week'),
          }),
          en: Trans('durationSelectList.monthUnit', {
            number: 1,
            time: Trans('durationSelectList.week'),
          }),
        },
      },
      {
        key: 1,
        value: 2,
        type: DurationType.Weekly,
        icon: 'MdLoop',
        name: {
          th: Trans('durationSelectList.monthUnit', {
            number: 2,
            time: Trans('durationSelectList.week'),
          }),
          en: Trans('durationSelectList.monthUnit', {
            number: 2,
            time: Trans('durationSelectList.week'),
          }),
        },
      },
      {
        key: 2,
        value: 1,
        type: DurationType.Monthly,
        icon: 'MdLoop',
        name: {
          th: Trans('durationSelectList.monthUnit', {
            number: 1,
            time: Trans('durationSelectList.month'),
          }),
          en: Trans('durationSelectList.monthUnit', {
            number: 1,
            time: Trans('durationSelectList.month'),
          }),
        },
      },
      {
        key: 3,
        value: 2,
        type: DurationType.Monthly,
        icon: 'MdLoop',
        name: {
          th: Trans('durationSelectList.monthUnit', {
            number: 2,
            time: Trans('durationSelectList.month'),
          }),
          en: Trans('durationSelectList.monthUnit', {
            number: 2,
            time: Trans('durationSelectList.month'),
          }),
        },
      },
      {
        key: 4,
        value: 3,
        type: DurationType.Monthly,
        icon: 'MdLoop',
        name: {
          th: Trans('durationSelectList.monthUnit', {
            number: 3,
            time: Trans('durationSelectList.month'),
          }),
          en: Trans('durationSelectList.monthUnit', {
            number: 3,
            time: Trans('durationSelectList.month'),
          }),
        },
      },
      {
        key: 5,
        value: 6,
        type: DurationType.Monthly,
        icon: 'MdLoop',
        name: {
          th: Trans('durationSelectList.monthUnit', {
            number: 6,
            time: Trans('durationSelectList.month'),
          }),
          en: Trans('durationSelectList.monthUnit', {
            number: 6,
            time: Trans('durationSelectList.month'),
          }),
        },
      },
      {
        key: 6,
        value: 12,
        type: DurationType.Monthly,
        icon: 'MdLoop',
        name: {
          th: Trans('durationSelectList.monthUnit', {
            number: 12,
            time: Trans('durationSelectList.month'),
          }),
          en: Trans('durationSelectList.monthUnit', {
            number: 12,
            time: Trans('durationSelectList.month'),
          }),
        },
      },
    ]);
  }

  getRepeatTimeListJS() {
    return toJS([
      {
        key: -1,
        icon: 'MdAllInclusive',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeNoUnit'),
          en: Trans('repeatTimeSelectList.repeatTimeNoUnit'),
        },
      },
      {
        key: 1,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 1,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 1,
          }),
        },
      },
      {
        key: 2,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 2,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 2,
          }),
        },
      },
      {
        key: 3,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 3,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 3,
          }),
        },
      },
      {
        key: 4,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 4,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 4,
          }),
        },
      },
      {
        key: 5,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 5,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 5,
          }),
        },
      },
      {
        key: 6,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 6,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 6,
          }),
        },
      },
      {
        key: 7,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 7,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 7,
          }),
        },
      },
      {
        key: 8,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 8,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 8,
          }),
        },
      },
      {
        key: 9,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 9,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 9,
          }),
        },
      },
      {
        key: 10,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 10,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 10,
          }),
        },
      },
      {
        key: 11,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 11,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 11,
          }),
        },
      },
      {
        key: 12,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 12,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 12,
          }),
        },
      },
      {
        key: 13,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 13,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 13,
          }),
        },
      },
      {
        key: 14,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 14,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 14,
          }),
        },
      },
      {
        key: 15,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 15,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 15,
          }),
        },
      },
      {
        key: 16,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 16,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 16,
          }),
        },
      },
      {
        key: 17,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 17,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 17,
          }),
        },
      },
      {
        key: 18,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 18,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 18,
          }),
        },
      },
      {
        key: 24,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 24,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 24,
          }),
        },
      },
      {
        key: 36,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 36,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 36,
          }),
        },
      },
      {
        key: 48,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 48,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 48,
          }),
        },
      },
      {
        key: 60,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 60,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 60,
          }),
        },
      },
      {
        key: 72,
        icon: 'MdKeyboardTab',
        name: {
          th: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 72,
          }),
          en: Trans('repeatTimeSelectList.repeatTimeUnit', {
            number: 72,
          }),
        },
      },
    ]);
  }

  getRemindTimeListJS() {
    //6, 9, 12, 15, 18
    return toJS([
      {
        key: 0,
        icon: 'MdLoop',
        name: {
          th: 'ตอนนี้',
          en: 'Now',
        },
      },
      {
        key: 6,
        icon: 'MdLoop',
        name: {
          th: '6:00',
          en: '6:00',
        },
      },
      {
        key: 9,
        icon: 'MdLoop',
        name: {
          th: '9:00',
          en: '9:00',
        },
      },
      {
        key: 12,
        icon: 'MdLoop',
        name: {
          th: '12:00',
          en: '12:00',
        },
      },
      {
        key: 15,
        icon: 'MdLoop',
        name: {
          th: '15:00',
          en: '15:00',
        },
      },
      {
        key: 18,
        icon: 'MdLoop',
        name: {
          th: '18:00',
          en: '18:00',
        },
      },
    ]);
  }

  getDurationByScheduleInfo(scheduleInfo: IScheduleInfoModel) {
    const durationList = this.getDurationListJS();
    const duration = durationList.find((data) => {
      if (data.value === scheduleInfo.duration) {
        //if no durationType return only type Month
        return (
          (!scheduleInfo.durationType && data.type === DurationType.Monthly) ||
          (scheduleInfo.durationType && scheduleInfo.durationType === data.type)
        );
      }
      return false;
    });

    let durationIndex;
    // can't use (duration && duration.key) ===> if duration.key = 0 will be false
    if (duration) {
      durationIndex = durationList.indexOf(duration);
    } else {
      durationIndex = 0;
    }

    return { durationIndex, duration };
  }
}

export default new ScheduleInfoStore();
