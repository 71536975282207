import { ApiManager, getSessionOcr, getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import config from 'config';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class OCRApi {
  apiManager;

  constructor() {
    this.apiManager = new ApiManager(config.api.ocr, 'v1');
  }

  async getItemsFromImage(data: any) {
    try {
      const token = getLineToken();
      const dataEncrypt = await encryptAESMessage(data, ServiceName.ocr);
      dataEncrypt.session_id = getSessionOcr();
      const result = await this.apiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `/`,
        b: dataEncrypt,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.ocr, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      throw error;
    }
  }

  async getTotalFromImage(data: any) {
    try {
      const token = getLineToken();
      const dataEncrypt = await encryptAESMessage(data, ServiceName.ocr);
      dataEncrypt.session_id = getSessionOcr();
      const result = await this.apiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `/total`,
        b: dataEncrypt,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.ocr, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      throw error;
    }
  }

  async createSessionOcr(lineToken: string, data: string, token: string) {
    return this.apiManager.fetch({
      m: Http.POST,
      h: {
        authorization: `Bearer ${lineToken}`,
      },
      ep: `${Api.Session}`,
      b: {
        data,
        token,
      },
    });
  }
}

export default new OCRApi();
