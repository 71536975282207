import { observable, toJS } from 'mobx';
import ImageApi from 'api/ImageApi';
import { ICroppedImageModel, IImageModel, IImageStore } from './ImageStore.d';

export class ImageStore implements IImageStore {
  @observable image: IImageModel;
  @observable croppedImage: ICroppedImageModel;

  getImageJs() {
    return toJS(this.image);
  }

  resetAllData() {
    this.croppedImage = null;
    this.image = null;
  }

  async processAndSendSlip(data: string, lineUserId: string, groupId: string) {
    try {
      const result = await ImageApi.processAndSendSlip(
        data,
        lineUserId,
        groupId,
      );
      this.image = {
        data: result.data,
      };
    } catch (error) {
      console.log('processAndSendSlip error == > ', error);
    }
  }

  async medalImage(data: string, lineUserId: string, groupId: string) {
    try {
      const result = await ImageApi.uploadMedalImage(data, lineUserId, groupId);
      this.image = {
        data: result.data,
        filename: result.filename,
        path: result.path,
      };
    } catch (error) {
      console.log('uploadMedalImage error == > ', error);
    }
  }

  setCroppedImage = (image: string) => {
    const model: ICroppedImageModel = {
      image,
    };
    this.croppedImage = model;
  };

  getCroppedImage = () => this.croppedImage;
}

export default new ImageStore();
