import DeepLinkGenApi from 'api/DeepLinkGen';
import {
  IDeepLinkGenModel,
  IDeepLinkGenStore,
  IGetDeepLinkGenReq,
} from './DeepLinkGenStore.d';
import { observable } from 'mobx';

export class DeepLinkGenStore implements IDeepLinkGenStore {
  @observable
  deepLinkGen: IDeepLinkGenModel;

  constructor() {
    this.deepLinkGen = this.initDeepLinkGenSchema();
  }

  async getDeepLinkGenByRefId(data: IGetDeepLinkGenReq) {
    try {
      const result = await DeepLinkGenApi.getDeepLinkGenByRefId(data);
      this.deepLinkGen = result.data || this.initDeepLinkGenSchema();
      this.deepLinkGen.refId = data.refId;
    } catch (error) {
      this.deepLinkGen = this.initDeepLinkGenSchema();
    }
  }

  getDeepLinkGenJs() {
    return this.deepLinkGen;
  }

  initDeepLinkGenSchema() {
    return {
      id: '',
      amount: 0,
      source: undefined,
      refId: '',
      // amount: 1000000,
      // source: 'KBANK_LIVE' as DeepLinkGenSource,
    };
  }
}

const deepLinkGenStore = new DeepLinkGenStore();
export default deepLinkGenStore;
