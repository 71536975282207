import { observable, toJS } from 'mobx';
import forEach from 'lodash/forEach';
import remove from 'lodash/remove';
import { ICalculateStore, ICalcBillMemberModel } from './CalculateStore.d';
import { isObjectEmpty, isArrayEmpty } from 'utils/Common';

export class CalculateStore implements ICalculateStore {
  @observable
  isTotalEdited: boolean;
  @observable
  isAllMemberEdited: boolean;
  @observable
  originalTotal: number;
  @observable
  total: number;
  @observable
  members: ICalcBillMemberModel[];

  @observable
  deepLinkCreateBillTotal: number;

  resetAllInfo() {
    this.isTotalEdited = false;
    this.isAllMemberEdited = false;
    this.originalTotal = 0;
    this.total = 0;
    this.members = [];
  }

  initCreateBill(members: ICalcBillMemberModel[], total?: number) {
    // Store previous member in hash
    const prevMemberHash = new Map<string, ICalcBillMemberModel>();
    if (Boolean(this.members)) {
      this.members.forEach((member) => {
        prevMemberHash.set(member.memberId, member);
      });
    }

    // Spread members
    this.members = [...members];

    // Restore data from the previous member
    this.members.forEach((member) => {
      if (prevMemberHash.has(member.memberId)) {
        const prevMember = prevMemberHash.get(member.memberId);
        if (prevMember) {
          member.isEdit = prevMember.isEdit;
          member.total = prevMember.total;
        }
      }
    });

    // Set member total to zero is isEdit !== true
    this.members.forEach((member) => {
      if (!member.isEdit) {
        member.total = 0;
      }
    });

    // Never edited total, means total might changed by members
    const editedMember = this.members.filter((m) => m.isEdit);
    if (!this.isTotalEdited && !!editedMember && editedMember.length > 0) {
      const newTotal = editedMember.map((m) => m.total).reduce((a, b) => a + b);
      this.total = newTotal;
      this.originalTotal = newTotal;
    }

    this.isAllMemberEdited = !this.members.some((member) => !member.isEdit);
    this.calcCreateBill();
  }

  getOriginalTotalJs() {
    return +(toJS(this.originalTotal) || 0);
  }

  getTotalJs() {
    return +(toJS(this.total) || 0);
  }

  getMembersJs() {
    return toJS(this.members) || [];
  }

  getActualTotal(onlyEdited?: boolean) {
    const members: any = this.getMembersJs().filter((member) => {
      if (onlyEdited === null || onlyEdited === undefined) {
        return true;
      } else {
        return member.isEdit === onlyEdited;
      }
    });
    if (!isArrayEmpty(members)) {
      const result = members.reduce((prev, curr) => {
        return {
          total: prev.total + curr.total,
        };
      });

      return result.total;
    }
    return 0;
  }

  setTotal(total: number) {
    const totalNum = +total;
    const totalVal = totalNum < 0 ? 0 : totalNum || 0;

    // Compare total change
    const prevTotal = Boolean(this.total) ? this.total : 0;
    const newTotal = Boolean(totalVal) ? totalVal : 0;
    const isChanged = prevTotal !== newTotal;
    console.log('prevTotal ===>', prevTotal);
    console.log('newTotal ===>', newTotal);

    if (!isChanged) {
      console.log('setTotal with NO changing => Early return');
      return;
    }

    this.isTotalEdited = true;
    this.isAllMemberEdited = false;
    this.total = totalVal;
    this.originalTotal = totalVal;

    console.log('original1 ===>', this.originalTotal);

    const members = this.getMembersJs();
    forEach(members, (member) => {
      member.isEdit = false;
      member.limited = totalVal;
    });
    console.log('original2 ===>', this.originalTotal);

    this.members = members;
    this.calcCreateBill();
    console.log('original3 ===>', this.originalTotal);
  }

  setMember(memberId: string, val: number) {
    const members = this.getMembersJs();
    const member = members.find((m) => m.memberId === memberId);
    const prevValue = Boolean(member.total) ? member.total : 0;
    let newValue = Boolean(val) ? val : 0;
    const isChanged = prevValue !== newValue;

    if (!isChanged) {
      console.log('setMember with NO changing => Early return');
      return false;
    }

    const otherFixMembersTotal = members.reduce(
      (a, m) => (m.memberId !== memberId && m.isEdit ? a + m.total : a),
      0,
    );
    const billTotal = this.getOriginalTotalJs();
    const totalLeft = billTotal - otherFixMembersTotal;
    let isSetGreaterThanBillTotal = false;

    this.isAllMemberEdited = !members
      .filter((m) => m.memberId !== memberId)
      .some((m) => !m.isEdit);

    if (
      !isObjectEmpty(member) &&
      (!member.disabled || this.isAllMemberEdited)
    ) {
      if (billTotal <= 0) {
        this.isAllMemberEdited = true;
        this.isTotalEdited = false;
        forEach(members, (m) => {
          m.limited = 0;
        });
      }

      isSetGreaterThanBillTotal =
        this.isTotalEdited && !this.isAllMemberEdited && val > totalLeft;

      if (this.isTotalEdited && !this.isAllMemberEdited && val > totalLeft) {
        newValue = totalLeft;
        member.limited = totalLeft;
      }

      member.limited = 0;
      member.isEdit = true;
      member.total = newValue < 0 ? 0 : newValue || 0;
      this.members = members;
      this.calcCreateBill();
    }

    return isSetGreaterThanBillTotal;
  }

  validateDeleteMemberHaveDummy(memberId: string) {
    const member = this.getMembersJs();
    const dummyMember = member.filter((m) => m.parentMemberId === memberId);
    if (dummyMember && dummyMember.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  deleteMember(memberId: string) {
    const members = this.getMembersJs();
    if (members.length > 1) {
      const removeMembers = remove(members, (m) => m.memberId === memberId);
      if (this.validateDeleteMemberHaveDummy(memberId)) {
        remove(members, (m) => m.parentMemberId === memberId);
      }
      if (members.length === 0) {
        throw new Error();
      }
      if (!isArrayEmpty(removeMembers)) {
        this.members = members;
        this.calcCreateBill();
      }
    } else {
      throw new Error();
    }
  }

  setAllMemberEdited() {
    forEach(this.members, (member) => {
      member.isEdit = true;
    });
    this.isAllMemberEdited = true;
    this.isTotalEdited = false;
  }

  calcCreateBill() {
    const total = this.getTotalJs();
    if (this.isTotalEdited) {
      const allMember = this.getMembersJs();
      const members = allMember.filter((member) => !member.isEdit);
      const countMembers = members.length;
      if (countMembers > 0) {
        const actualTotal = this.getActualTotal(true);
        let outstanding = total - actualTotal;
        outstanding = outstanding < 0 ? 0 : outstanding;
        const val = Math.ceil(outstanding / countMembers) || 0;
        forEach(allMember, (member) => {
          if (!member.isEdit) {
            member.total = val;
            if (total > 0) {
              member.disabled = val <= 0;
            } else {
              member.disabled = false;
            }
          } else {
            member.disabled = false;
          }
        });
        this.members = allMember;
      } else {
        forEach(allMember, (member) => {
          member.disabled = false;
          member.limited = 0;
        });
        this.members = allMember;
        this.isTotalEdited = false;
        this.isAllMemberEdited = true;
      }
    }
    console.log('1 ===>', this.originalTotal);

    if (this.isAllMemberEdited) {
      const totalVal = this.getActualTotal();
      this.total = totalVal;
      this.originalTotal = totalVal;
    }
    console.log('2 ===>', this.originalTotal);

    const hasJustEdit = this.isTotalEdited || this.isAllMemberEdited;

    if (!hasJustEdit) {
      let totalEdited = 0;
      const allMember = this.getMembersJs();
      forEach(allMember, (member) => {
        totalEdited += member.total;
      });
      this.total = totalEdited;
      this.originalTotal = totalEdited;
    }
    console.log('3 ===>', this.originalTotal);
  }

  setDeepLinkCreateBillTotal(total: number) {
    this.deepLinkCreateBillTotal = total;
  }
}

export default new CalculateStore();
