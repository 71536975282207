import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class PostBackEventApi {
  async getPostBackInfoByBillId(billId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PostBackEvent}/verify_slip?billID=${billId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getPostBackInfoByLineUserId(lineUserId: string, lineGroupId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PostBackEvent}/verify_slip?lineUserID=${lineUserId}&lineGroupID=${lineGroupId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getPostBackDialogflowInfoById(id: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PostBackEvent}/dialogflow/${id}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async deletePostBackDialogflowInfoById(id: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.DELETE,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PostBackEvent}/${id}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getPostBackInfoByPrivateBillId(privateBillId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PrivateBill}/${Api.PostBackEvent}/verify_slip/${privateBillId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getPostBackInfoPrivateByLineUserId() {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PrivateBill}/${Api.PostBackEvent}/verify_slip`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new PostBackEventApi();
