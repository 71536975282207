import ApiManager from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';

export class SessionApi {
  async createSession(
    lineToken: string,
    data: string,
    token: string,
    handleRetry: (tag: string) => void,
  ) {
    return ApiManager.fetch({
      m: Http.POST,
      h: {
        authorization: `Bearer ${lineToken}`,
      },
      ep: `${Api.Session}`,
      b: {
        data,
        token,
      },
      handleRetry,
    });
  }
}

export default new SessionApi();
