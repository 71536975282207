import { CurrentLanguage } from 'common/components/Translate';
import moment from 'moment';

export function thaiYear(year: number) {
  if (CurrentLanguage() === 'th') {
    return year + 543;
  }
  return year;
}

export function shortYear(year: number) {
  return thaiYear(year).toString().substring(2, 4); // 62
}

export function shortMonth(month: number) {
  let strMonth = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (CurrentLanguage() === 'th') {
    strMonth = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ];
  }
  return strMonth[month - 1];
}

export function strTime(
  datetime: any,
  noSec?: boolean,
  custormSeparator?: string,
) {
  let suffix = '';
  let s = custormSeparator || ':';
  if (CurrentLanguage() === 'th') {
    suffix = ' น.';
  }
  let timeFormat = moment(datetime).format(`HH${s}mm${s}ss` + suffix);
  if (noSec) {
    timeFormat = moment(datetime).format(`H${s}mm` + suffix);
  }
  return timeFormat;
}

export function getDateSuffix(date: string | number) {
  let dateSuffix = '';
  switch (date) {
    case '1':
    case '21':
    case '31':
    case 1:
    case 21:
    case 31:
      dateSuffix = 'st';
      break;
    case '2':
    case '22':
    case 2:
    case 22:
      dateSuffix = 'nd';
      break;
    case '3':
    case '23':
    case 3:
    case 23:
      dateSuffix = 'rd';
      break;
    default:
      dateSuffix = 'th';
  }
  return dateSuffix;
}
