import ApiManager from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';

export class ConfigurationApi {
  async getConfiguration() {
    return ApiManager.fetch({
      m: Http.GET,
      ep: `${Api.Config}`,
    });
  }
}

export default new ConfigurationApi();
