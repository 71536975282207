import swal from 'sweetalert2';
import iconWarning from '../../../images/IconKhunthong/warning.png';
import iconKhunthong from '../../../images/IconKhunthong/iconKhunthong.png';
import iconKhunthongInfo from '../../../images/IconKhunthong/iconKhunthongInfo.png';
import iconKhunthongErr from '../../../images/IconKhunthong/iconKhunthongErr.png';
import iconGotoKPlus from '../../../images/IconKhunthong/iconKhunthongGotoKplus.png';
import iconMoney from '../../../images/IconKhunthong/iconMoney.png';
import iconBack from '../../../images/IconKhunthong/iconBack.png';
import iconKhunthongLocation from '../../../images/IconKhunthong/iconKhunthongLocation.png';
import iconKhunthongSchedule from '../../../images/IconKhunthong/iconKhunthongSchedule.png';
import iconKplusAuth from '../../../images/IconKhunthong/iconKplusAuth.svg';

import { Trans } from 'common/components/Translate';
import './swal.scss';

export interface ISwalOptions {
  title?: string;
  text?: string;
  footer?: string;
  html?: string;
  customClass?: any;
  showIconKhunThong?: boolean;
  animation?: boolean;
  showCancelButton?: boolean;
  disableCancelButton?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  allowOutsideClick?: boolean;
  isConfirmDelete?: boolean;
  showIconKey?: string;
}

const imgHeight = 72;

export class Swal {
  toastObj: any = swal.mixin({
    position: 'top-end',
    cancelButtonText: Trans('message.common.cancel'),
    confirmButtonText: Trans('message.common.confirm'),
    showConfirmButton: false,
    reverseButtons: true,
    timer: 3000,
    toast: true,
  });

  swalObj: any = swal.mixin({
    customClass: 'swalBot',
    cancelButtonClass: 'swalBtn buttonCancel',
    confirmButtonClass: 'swalBtn buttonConfirm',
    confirmButtonText: Trans('message.common.confirm'),
    cancelButtonText: Trans('message.common.cancel'),
    reverseButtons: true,
    buttonsStyling: false,
    heightAuto: false,
  });

  getIconImage(showIconKey?: string) {
    switch (showIconKey) {
      case 'KhunThongIcon':
        return iconKhunthong;
      case 'KhunThongInfoIcon':
        return iconKhunthongInfo;
      case 'KhunThongErrIcon':
        return iconKhunthongErr;
      case 'KhunthongGotoKPlus':
        return iconGotoKPlus;
      case 'KhunThongMoneyIcon':
        return iconMoney;
      case 'KhunThongBackIcon':
        return iconBack;
      case 'KhunThongLocationIcon':
        return iconKhunthongLocation;
      case 'KhunThongKplusAuth':
        return iconKplusAuth;
      case 'KhunthongSchedule':
        return iconKhunthongSchedule;
      default:
        return iconWarning;
    }
  }

  info = (options?: ISwalOptions) => {
    this.swalObj.fire({
      type: 'info',
      ...options,
    });
  };

  success = (options?: ISwalOptions) => {
    return this.swalObj.fire({
      type: 'success',
      ...options,
    });
  };

  error = (options?: ISwalOptions) => {
    const url = options.showIconKhunThong
      ? this.getIconImage(options.showIconKey)
      : iconWarning;
    return this.swalObj.fire({
      id: 'swal2-content',
      // type: 'error',
      imageUrl: url,
      imageHeight: imgHeight,
      ...options,
    });
  };

  warning = (options?: ISwalOptions) => {
    const url = options.showIconKhunThong
      ? this.getIconImage(options.showIconKey)
      : iconWarning;

    this.swalObj.fire({
      // type: 'warning',
      imageUrl: url,
      imageHeight: imgHeight,
      ...options,
    });
  };

  question = (options?: ISwalOptions) => {
    const url = options.showIconKhunThong
      ? this.getIconImage(options.showIconKey)
      : iconWarning;

    const btnConfirmStyles = options.isConfirmDelete
      ? 'swalBtn2 buttonConfirmDelete'
      : 'swalBtn2 buttonConfirm';

    return this.swalObj.fire({
      // type: 'question',
      id: 'confirm-button',
      ...options,
      imageUrl: url,
      imageHeight: imgHeight,
      showCancelButton: !options.disableCancelButton && true,
      showConfirmButton: true,
      cancelButtonClass: 'swalBtn2 buttonCancel',
      confirmButtonClass: btnConfirmStyles,
    });
  };

  close = () => {
    swal.close();
  };

  toastError = (options?: ISwalOptions) => {
    this.toastObj.fire({
      type: 'error',
      ...options,
    });
  };

  toastSuccess = (options?: ISwalOptions) => {
    return this.toastObj.fire({
      type: 'success',
      ...options,
    });
  };

  toastQuestion = (options?: ISwalOptions) => {
    this.toastObj.fire({
      type: 'question',
      ...options,
    });
  };

  toastInfo = (options?: ISwalOptions) => {
    this.toastObj.fire({
      type: 'info',
      ...options,
    });
  };
}

export default new Swal();
