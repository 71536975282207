import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './Home';

interface IPageProps {}

interface IPageState {
  isLoading: boolean;
  isRegistered?: boolean;
}

class Page extends Component<IPageProps, IPageState> {
  _isMounted = false;
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      isLoading: false,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // if (this.state.isLoading) {
    //   return <Loader show />;
    // }

    // if (!this.state.isRegistered) {
    //   return (
    //     <Switch>
    //       <Redirect to="/register" />
    //     </Switch>
    //   );
    // }

    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
      </Switch>
    );
  }
}

export default Page;
