import { config } from 'config';

// (window as any).Cypress = {};

export const isCypressMode =
  config.cypress.enabled && Boolean((window as any).Cypress);

if (isCypressMode) {
  (window as any).liff = (window as any).Cypress.liffMock;
  // For static profile
  // let lineProfile = {
  //   displayName: "TKB Automate",
  //   userId: "U48a4050ba7013235537c4faa4c8e19d5",
  //   pictureUrl: "https://ourfunnylittlesite.com/wp-content/uploads/2018/07/1-4-696x696.jpg",
  //   statusMessage: 'liff is controlled by Cypress'
  // };
  // (window as any).liff = {
  //   init: async ({ liffId }, onSuccess = () => {}) => {
  //       await onSuccess();
  //       Promise.resolve();
  //   },
  //   isLoggedIn: () => true,
  //   getProfile: () => Promise.resolve(lineProfile),
  //   isInClient: () => true,
  //   getOS: () => 'web',
  //   getLanguage: () => navigator.language,
  //   getVersion: () => '9999',
  //   getFriendship: () => Promise.resolve({
  //     friendFlag: true
  //   }),
  //   openWindow: ({ url = '' }) => {
  //     console.log("Liff: Open Window", url);
  //   },
  //   sendMessages: ({ messages = [] }) => {
  //     console.log("Liff: Send Messages", messages);
  //   },
  //   getAccessToken: () => 'MOCK_ACCESS_TOKEN',
  //   getDecodedIDToken: () => Promise.resolve({
  //     "iss": "https://access.line.me",
  //     "sub": "U1234567890abcdef1234567890abcdef ",
  //     "aud": "1234567890",
  //     "exp": 1504169092,
  //     "iat": 1504263657,
  //     "nonce": "0987654asdf",
  //     "amr": [
  //         "pwd"
  //       ],
  //     "name": "Taro Line",
  //     "picture": "https://sample_line.me/aBcdefg123456"
  //   })
}