import { observable, toJS } from 'mobx';
import MedalApi from 'api/MedalApi';
import { IMedalMember, IMedalStore } from './MedalStore.d';

export class MedalStore implements IMedalStore {
  @observable medalMembers: IMedalMember[];
  @observable medalMember: IMedalMember;

  constructor() {
    this.medalMembers = [this.initMedalMemberSchema()] as any;
    this.medalMember = this.initMedalMemberSchema() as any;
  }

  getMedalMembersJs() {
    return toJS(this.medalMembers);
  }

  getMedalMemberJs() {
    return toJS(this.medalMember);
  }

  initMedalMemberSchema() {
    return {
      id: '',
      seasonId: '',
      lineUserId: '',
      lineGroupId: '',
      memberId: '',
      crown: 0,
      gold: 0,
      silver: 0,
      bronze: 0,
      none: 0,
    };
  }

  async getMedalMember(lineUserId: string) {
    try {
      const result = await MedalApi.getMedalMember(lineUserId);
      this.medalMember = result.data;
    } catch (error) {
      console.log('getMedalMember error == > ', error);
    }
  }

  async getMedalMembers(lineGroupId: string) {
    try {
      const result = await MedalApi.getMedalMembers(lineGroupId);
      this.medalMembers = result.data;
    } catch (error) {
      console.log('getMedalMembers error == > ', error);
    }
  }
}

export default new MedalStore();
