import { consoleLog } from './Common';
import config from 'config';

export class FacebookApi {
  ua = '';
  initFacebook = () => {
    try {
      // @ts-ignore
      window.fbAsyncInit = () => {
        // @ts-ignore
        FB.init({
          appId: config.fb.appId,
          cookie: true,
          xfbml: true,
          version: config.fb.version,
        });

        // @ts-ignore
        FB.AppEvents.logPageView();
      };

      const init = (d, s, id) => {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      };
      init(document, 'script', 'facebook-jssdk');
      console.log('Facebook initialized');
    } catch (error) {
      console.log('Facebook initialized error =======>', error);
    }
  };

  getLoginStatus = () => {
    // @ts-ignore
    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        // do something
      }
    });
  };

  getFacebookProfilePicture = (userId) => {
    // @ts-ignore
    window.FB.api(`/${userId}/picture?type=large`, (response) => {
      consoleLog('getFacebookProfilePicture =---->', response);
    });
  };

  shareFacebook = (info) => {
    // @ts-ignore
    window.FB.ui(
      {
        method: 'share',
        href: info.url,
        hashtag: info.hashtag,
        app_id: config.fb.appId,
        fallback_redirect_uri: `${config.publicWeb}/success`,
        // action_properties: JSON.stringify({
        //   object: {
        //     'fb:app_id': config.fb.appId,
        //     'og:url': info.url,
        //     'og:title': info.title,
        //     'og:description': info.description,
        //     'og:image': info.photoDefault,
        //     'og:image:secure_url': info.photoDefault,
        //     'og:image:url': info.photoDefault,
        //     'og:image:alt': info.photoAlt,
        //     // 'og:image:type': info.type,
        //   },
        // }),
      },
      (response) => {
        consoleLog('shareFacebook response =====> ', response);
        if (response && response.post_id) {
          consoleLog('success, post_id:', response.post_id);
        } else {
          consoleLog('error, shared', response);
        }
      },
    );
  };
}

export const facebook = new FacebookApi();
export default facebook;
