import { observable } from 'mobx';
import { IUIHandlerStore } from './UIHandlerStore.d';

export class UIHandlerStore implements IUIHandlerStore {
  @observable
  everOpenedBillAssign: boolean;

  constructor() {
    this.resetAllData();
  }

  resetAllData = () => {
    this.everOpenedBillAssign = false;
  };

  setEverOpenedBillAssign = (flag: boolean) => {
    this.everOpenedBillAssign = flag;
  };
  getEverOpenedBillAssign = () => this.everOpenedBillAssign;
}

export default new UIHandlerStore();
