const config = {
  env: 'localhost',
  publicUrl: '',
  publicWeb: 'https://gobotweb.k2a.fun',
  api: {
    ocr: 'http://127.0.0.1:9999/ocr/',
    socialbot: 'https://gobotapi.k2a.fun/api',
    s3: 'https://uatkhunthong.kasikornbank.com',
  },
  kplus: {
    url: 'https://appuat.kplus.link',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDg_CRn3AmgVfBceAeZg4qXxVaqqPtvWLY',
    authDomain: 'social-banking-uat-bed69.firebaseapp.com',
    databaseURL: 'https://social-banking-uat-bed69.firebaseio.com',
    projectId: 'social-banking-uat-bed69',
    storageBucket: 'social-banking-uat-bed69.appspot.com',
    messagingSenderId: '889414426551',
  },
  liff: {
    crop_image: '2000917811-A9NmWeqd',
    pay_type: '2000917811-nVJ43LwM',
    bill_assign: '2000917811-WY2oPzAj',
    add_account: '2000917811-EDP8Qpa4',
    summary: '2000917811-NrbgxRKn',
    invite_friend: '2000917811-GanqE3W0',
    account_management: '2000917811-vPqjzN7J',
    medal: '2000917811-EZPrVOxj',
    donation: '2000917811-mN9MenNv',
    donation_info: '2000917811-WMxdmBAn',
    rollcall: '2000917811-WMxdmBAn',
    campaign_landing: '2000917811-kPOPnzxX',
    campaign_activity: '2000917811-E5Lr5Jva',
    schedule_create: '2000917811-OkGz9wrA',
    schedule_summary: '2000917811-AQd4vJG7',
    bill_summary: '2000917811-WM49YQEw',
    cancel_info: '2000917811-pZmJDndg',
    multiple_payment_slip: '2000917811-9mr6AXYq',
    demo_slip: '2000917811-pP4WadQV',
    tutorial: '2000917811-jY7qOQBn',
    reward_info: '2000917811-w11mpPb5',
    redeem_location_code: '2000917811-DgVav8OB',
    referrer: '2000917811-9kYwqLgl',
    referree: '2000917811-XlLV70gw',
    create_bill_template: '2000917811-MGRgxDQ2',
    bill_center: '2000917811-69jlpVZo',
    private_bill_summary: '2000917811-Qkby1LA2',
    init_liff_full: '2000917811-OZbdWzPj',
    init_liff_tall: '2000917811-WMxdmBAn',
    robot_login: '2000917811-WMxdmBAn',
  },
  vconsole: {
    enabled: true,
  },
  cypress: {
    enabled: false,
  },
  development: {
    allowedDesktop: true,
    allowedRobot: true,
  },
  publickeyBot: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApASIFXNq7Bmjhbtmh2Qe
  87tN/Kl/hKIJ1FdGB841n0mzXSWcfJLLCqPc0LNgHHmRLxe47WQbMCrxXoUOVK+r
  rUXwYdzQ10yVVU7ODgZW5XoaImf5NJTkgJPjFV4iW4fSqW1tsUbGwbJ3zlBmEo6F
  UfdzChMcGS0UFlUIQczDfCnZVXiTtveeeB2ikCxJj+JQGNtxwC5YHl5BpH2DGcyK
  V4DW/UvuyhfMqDwfqf/vf2cjmDyB/BqOb59xWIhdGbP789K5kQ5uvQKxypRM3A67
  G3l8KBrXyXzVwlziNGr6B6K94MyUDiC0nbgKYAszTrwrBmEUP/l6QjxI/n4QRkN/
  nQIDAQAB`,
  publickeyOcr: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4gMgWdQl/J2Kq3qH3Wpw
  L79EUnoTJvfPvMDS/5XTkGLt+UDBJ5Yz3l++w0DSBZKKXeS1Lg0zhVFp/2NYD/bs
  0SOelE8kOVJgsguB+SDVioSrXKF6YaP5P84QMHhx//MwxK8pIqKT8FbDvZYjyDDK
  /Vqt4IwO3tDpYeTKoR1QKJs27AdxLBzzMbEr+Gn/pmIPUHzRDecNlSkbEDO+YJUI
  rgSbnAFxg46xhfzjWyjdkRJLr82icV5hxJB86E/62X3kqtXBb76Tpt4wGU3DNdGB
  j4CRxQj3oxRZfYe7n/LIIYPJuggdqrdTfki3SFCN3N4OxG0BQgVHQq0/6g66W4n6
  YwIDAQAB`,
  authentication: {
    enabled: true,
    encrypt: true,
  },
  gtm: {
    id: 'GTM-KKXSH5C',
  },
  fb: {
    appId: '2428528883868863',
    version: 'v2.9',
  },
  line: {
    botBasicId: '~@944isdly',
    botBasicIdBypass: '@944isdly',
  },
  bill: {
    defaultExpiredDuration: 7,
    defaultExtendedDuration: 1,
  },
  privateBill: {
    noteExceedLimit: 200,
  },
};

export default config;

