import { observable, toJS } from 'mobx';
import { IFeatureStatusModel } from './FeatureStatusStore.d';
import FeatureStatusApi from 'api/FeatureStatusApi';

export class FeatureStatusStore implements FeatureStatusStore {
  @observable featureStatus: IFeatureStatusModel;

  constructor() {
    this.featureStatus = this.initFeatureStatusSchema();
  }

  getFeatureStatusJs() {
    return toJS(this.featureStatus);
  }

  async getFeatureStatus() {
    try {
      if (!this.featureStatus.id) {
        const result = await FeatureStatusApi.getFeatureStatus();
        this.featureStatus = result.data || this.initFeatureStatusSchema();
      }
    } catch (error) {
      throw error;
    }
  }

  initFeatureStatusSchema() {
    return {
      id: '',
      createBillByRequest: 0,
      createBillByScanRec: 0,
      createBillByUploadRec: 0,
      selectFriend: 0,
      addBankAccount: 0,
      seeDetail: 0,
      pay: 0,
      linkAccountKplus: 0,
      transferSlip: 0,
      payWithCash: 0,
      payWithKplus: 0,
      promptPayPayment: 0,
      donateWithKplus: 0,
      consent: 0,
    };
  }
}
export default new FeatureStatusStore();
