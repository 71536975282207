import { observable, toJS } from 'mobx';
import {
  ICampaignRewardModel,
  ICampaignRewardStore,
} from './CampaignRewardStore.d';
import CampaignApi from 'api/CampaignApi';

export class CampaignRewardStore implements ICampaignRewardStore {
  @observable campaignReward: ICampaignRewardModel;

  constructor() {
    this.campaignReward = this.initCampaignRewardSchema() as any;
  }

  initCampaignRewardSchema() {
    return {
      type: 1,
      members: [
        {
          id: '',
          insertedId: '',
          name: '',
          lineUserId: '',
          image: '',
          isPvtUser: false,
          accounts: [],
          kplusInfo: {},
        },
      ],
      rewards: [
        {
          id: '',
          type: '',
          brand: '',
          title: '',
          couponTitle: '',
          shortDescription: '',
          description: '',
          condition: '',
          startDate: '',
          endDate: '',
          logo1Url: '',
          logo2Url: '',
          backgroudUrl: '',
          bgPrimaryColor: '',
          bgSecondaryColor: '',
          fontColor: '',
          rewardValue: '',
        },
      ],
      campaign: {
        id: '',
        title: '',
        flexUrl: '',
        ImageUrl: '',
        logoUrl: '',
        bannerUrl: '',
        shortDescription: '',
        description: '',
        condition: '',
        status: '',
        rewards: [
          {
            rewardId: '',
            rewardName: '',
            quota: 0,
            remainingQuota: 0,
            quotaType: '',
            periodQuota: 0,
            remainingPeriodQuota: 0,
            resetDate: null,
          },
          {
            rewardId: '',
            rewardName: '',
            quota: 0,
            remainingQuota: 0,
            quotaType: '',
            periodQuota: 0,
            remainingPeriodQuota: 0,
            resetDate: null,
          },
        ],
        triggerEventType: '',
        triggerEventName: '',
        assignRewardTo: '',
        hideCampaign: false,
        startDate: '',
        endDate: '',
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: '',
      },
    };
  }

  getCampaignRewardToJs() {
    return toJS(this.campaignReward);
  }

  async getCampaignReward(lineUserId: string, refno: string) {
    try {
      const response = await CampaignApi.GetCampaignReward(lineUserId, refno);
      this.campaignReward = response.data || this.initCampaignRewardSchema();
    } catch (error) {
      this.campaignReward = this.initCampaignRewardSchema();
    }
  }
}

export default new CampaignRewardStore();
