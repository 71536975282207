import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class PrivateRequestApi {
  async getGroupsByLineUserId() {
    const token = getLineToken();

    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.GroupBill}/groups`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getBillsInGroup() {
    const token = getLineToken();

    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.GroupBill}/groups/bills`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getCompleteBillsInGroup(limit: number, lastId: string) {
    const token = getLineToken();

    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.GroupBill}/groups/bill-history?limit=${limit}${
          lastId ? `&last_id=${lastId}` : ''
        }`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new PrivateRequestApi();
