export const Example = {};

export const TransferLimit = 3000;
export const LimitDonationTotal = 200000;
export const MinimumLineVersion = '10.10.0';
export const DefaultRedeemRadius = 1000;
export const CantGetLineIdRetryTimeout = 5000;
export const CantGetLineIdRetryMaxCount = 10;

export const BillStatus = {
  New: 'NEW',
  Cancel: 'CANCEL',
  Confirmed: 'CONFIRMED',
  Paid: 'PAID',
};

export const BillMemberStatus = {
  Pending: 'PENDING',
  Paid: 'PAID',
  Assigned: 'ASSIGNED',
  Waiting: 'WAITING',
  Unassigned: 'UNASSIGNED',
};

export const BillSource = {
  OCR: 'OCR',
  DF: 'DIALOGFLOW',
  CreateBill: 'CREATEBILL',
  Schedule: 'SCHEDULE',
  LineOA: 'LINE_OA',
  LineOAOCR: 'LINE_OA_OCR',
  KBankLive: 'KBANK_LIVE',
};

export const ScheduleSource = {
  Group: 'Group',
  LineOA: 'LINE_OA',
};

export const URLParamsFrom = {
  QuickReply: 'QUICK_REPLY',
  Carousel: 'CAROUSEL',
  Banner: 'BANNER',
  RichmenuIntro: 'RICHMENU_INTRO',
  RichmenuRegister: 'RICHMENU_REGISTER',
  AddFriend: 'ADD_FRIEND',
  KBankLiveFlex: 'KBANK_LIVE_FLEX',
};

export const BillType = {
  American: 'AMERICAN',
  Dutch: 'DUTCH',
  Adjust: 'ADJUST',
  // for schedule bill
  W1: '1W',
  W2: '2W',
  M1: '1M',
  M2: '2M',
  M3: '3M',
  M6: '6M',
  M12: '12M',
};

export const ScheduleBillType = {
  W1: '1W',
  W2: '2W',
  M1: '1M',
  M2: '2M',
  M3: '3M',
  M6: '6M',
  M12: '12M',
};

export const PaymentMethod = {
  Cash: 'CASH',
  KPlus: 'KPLUS',
  KhunThong: 'KHUNTHONG',
  Transfer: 'TRANSFER',
  Owner: 'OWNER',
};

export const PaymentStatus = {
  Pending: 'PENDING',
  Failed: 'FAILID', // wrong spell on backend
  Paid: 'PAID',
  Owner: 'OWNER',
};

export const DonationPaymentMethods = {
  KPlus: 'KPLUS',
  QRCode: 'QR',
};

export const CommonError = {
  FailToFetch: 'FAIL_TO_FETCH',
  LineProfileNotFound: 'LINE_PROFILE_NOT_FOUND',
  CantGetLineID: 'CANT_GET_LINE_ID',
  LineIDMismatched: 'LINE_ID_MISMATCHED',
};

export const BillError = {
  BillAlreadyExists: 'BILL_ALREADY_EXISTS',
  BillAlreadyComplete: 'BILL_ALREADY_COMPLETE',
  MemberAlreadyPaid: 'MEMBER_ALREADY_PAID',
  ScheduleAlreadyExists: 'SCHEDULE_BILL_ALREADY_EXIST',
  NotOwner: 'NOT_OWNER',
  ScheduleOverLimitInGroup: 'SCHEDULE_BILL_OVER_LIMIT_IN_GROUP',
};

export const PaymentError = {
  PayWithKhunthongOverLimit: 'PAY_WITH_KHUNTHONG_OVER_LIMIT',
};

export const ServiceName = {
  bot: 'BOT',
  ocr: 'OCR',
};

export const KBank = {
  Code: '004',
};

export const OcrResponse = {
  OCR_ERROR: 'OCR_ERROR',
  LOW_CONFIDENCE_ERROR: 'LOW_CONFIDENCE_ERROR',
};

export const StatusStage = {
  BILL_ASSIGN: 'BILL_ASSIGN',
};

export const Environment = {
  LOCAL: 'local',
  LOCALHOST: 'localhost',
  SIT: 'sit',
  UAT: 'uat',
  PROD: 'production',
};

export const MobileOS = {
  IOS: 'iOS',
  Android: 'Android',
  Windows: 'Windows Phone',
};

export const periodQuota = {
  Normal: 'normal',
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
};

export const Origin = {
  Android: 'market://details?id=com.kasikorn.retail.mbanking.wap',
  IOS: 'itms-apps://itunes.apple.com/app/kplus/',
  AndroidUrl: 'market://details?id=com.kasikorn.retail.mbanking.wap',
  IOSUrl: 'itms-apps://itunes.apple.com/app/kplus/id361170631',
};

export const ScheduleType = {
  Manual: 'MANUAL',
  Fix: 'FIX',
};

export const ScheduleCollectType = {
  ByTotal: 'BY_TOTAL',
  PerEach: 'PER_EACH',
};

export const ScheduleStatus = {
  Active: 'ACTIVE',
  Cancel: 'CANCEL',
  Expire: 'EXPIRE',
  Complete: 'COMPLETE',
};

export const BillItemType = {
  ServiceCharge: 'SERVICE_CHARGE',
  VAT: 'VAT',
  Discount: 'DISCOUNT',
  // VAT_SERVICE_DISCOUNT is a special type for UI
  VAT_SERVICE_DISCOUNT: 'VAT_SERVICE_DISCOUNT',
};

export const BillSplitType = {
  Evently: 'EVENTLY',
  Proportionately: 'PROPORTION',
};

export const BillPrioritiesAction = {
  //VerifySlipWaiting: 'VERIFY_SLIP_WAITING', // no matched account (Deplicated)
  PayWithCash: 'PAY_WITH_CASH', // paid cash status=WAITING
  AutoCancel: 'AUTO_CANCEL', // remind that can extened exried date
  MissingAccount: 'BILL_MISSING_ACCOUNT', // status=NEW, add receiving account
  OCR: 'OCR', // status=NEW, Ocr  Member No Assign
  OtherOCR: 'OTHER_OCR',
  OtherBillMissingAccount: 'OTHER_BILL_MISSING_ACCOUNT',

  // Pay by Slip Status=Waiting
  SlipFail: 'SLIP_FAIL',
  SlipNotMatchedAccount: 'SLIP_NOT_MATCHED_ACCOUNT',
};

export const BillPrioritiesTab = {
  BillActionTabBillsSummaryForce: 'bills_summary_force',
  BillActionTabBillsSummary: 'bills_summary',
  BillActionTabBillsPriorities: 'bills_priorities',
};

export const PaymentBySlipAction = {
  SlipFail: 'SLIP_FAIL',
  SlipNotMatchedAccount: 'SLIP_NOT_MATCHED_ACCOUNT',
  SlipNotMatchedAmount: 'SLIP_NOT_MATCHED_AMOUNT',
  OK: 'OK',
};

export const ConfirmSlipErrorType = {
  NotOwner: 'NOT_OWNER',
  SlipConfirmed: 'SLIP_CONFIRMED',
  NotFound: 'NOT_FOUND',
};

export const DurationType = {
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
};

export const UploadSlipError = {
  DuplicateSlip: 'ERROR_DUPLICATE',
  DuplicateKPLUSSlip: 'ERROR_DUPLICATE_KPLUS_SLIP',
  SlipTooOld: 'SLIP_TOO_OLD',
  NoBill: 'NO_BILL',
  NotSlip: 'NOT_SLIP',
  SlipNotEnoughAmount: 'SLIP_NOT_ENOUGH_AMOUNT',
  BillCancel: 'BILL_CANCEL',
  BillPaid: 'BILL_PAID',
  MemberPaid: 'MEMBER_PAID',
  PrivateBillMemberNotFound: 'PRIVATE_BILL_MEMBER_NOT_FOUND',
  PrivateBillCancel: 'PRIVATE_BILL_CANCEL',
  PrivateBillPaid: 'PRIVATE_BILL_PAID',
  MemberCannotPaid: 'MEMBER_CANNOT_PAID',
};

export const ReferralError = {
  MemberAlreadyFriend: 'MEMBER_ALREADY_FRIEND',
  MemberNotFriend: 'MEMBER_NOT_FRIEND',
  ReferrerNotFound: 'REFERRER_NOT_FOUND',
  ReferCodeNotFound: 'REFER_CODE_NOT_FOUND',
  AlreadyReferred: 'ALREADY_REFERRED',
  MemberReferralNotFound: 'MEMBER_REFERRAL_NOT_FOUND',
  MobileNumberAlreadyExist: 'REFERRAL_MOBILE_NUMBER_ALREADY_EXIST',
};

export const ScheduleBillSection = {
  Default: 'DEFAULT',
  Other: 'OTHER',
};

export const TutorialPageName = {
  HowTo: 'HOW_TO',
  FAQ: 'FAQ',
  Privacy: 'PRIVACY',
  StartRegister: 'START_REGISTER',
  Template: 'TEMPLATE',
  Shortkey: 'SHORTKEY',
  SplitAmount: 'SPLIT_AMOUNT',
  ReadReceipt: 'READ_RECEIPT',
  ScheduleBill: 'SCHEDULE_BILL',
  InviteKTH: 'INVITE_KTH',
};

export const Language = {
  TH: 'th',
  EN: 'en',
};
