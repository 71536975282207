import { observable, toJS } from 'mobx';
import PostBackEventApi from 'api/PostBackEventApi';
import set from 'lodash/set';
import {
  IPostBackEventStore,
  IDialogflowHowMuch,
} from './PostBackEventStore.d';

class PostBackEventStore implements IPostBackEventStore {
  @observable
  dialogflowInfo: IDialogflowHowMuch;

  constructor() {
    this.resetAllData();
  }

  resetAllData = () => {
    this.dialogflowInfo = '' as any;
  };

  setDialogflowInfo = (key: string, val: any) => {
    const info = this.getDialogflowInfoJs();
    set(info, key, val);
    this.dialogflowInfo = info;
  };

  getDialogflowInfoJs = () => {
    return toJS(this.dialogflowInfo);
  };

  getPostbackDialogflowInfoById = async (id: string) => {
    try {
      const resp = await PostBackEventApi.getPostBackDialogflowInfoById(id);
      if (resp && resp.data) {
        this.dialogflowInfo = resp.data;
      }
    } catch (err) {
      console.log('error getPostBackDialogflowInfoById:', err);
    }
  };

  deletePostBackDialogflowInfoById = async (id: string) => {
    try {
      await PostBackEventApi.deletePostBackDialogflowInfoById(id);
    } catch (err) {
      console.log('error deletePostbackDialogflowInfoById:', err);
    }
  };
}

export default new PostBackEventStore();
