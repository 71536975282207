import Api from 'api/ReferralApi';
import { observable, toJS } from 'mobx';
import {
  IReferrersModel,
  IReferralStore,
  IReferrersRequestModel,
  IReferralCampaignModel,
  IReferralApplyRequestModel,
} from './ReferralStore.d';

export class ReferralStore implements IReferralStore {
  @observable referrersModel: IReferrersModel;
  @observable referralCampaignModel: IReferralCampaignModel;
  @observable referralPublicCampaignModel: IReferralCampaignModel[];

  async getReferrars(
    referrersRequestModel: IReferrersRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const result = await Api.getReferers(referrersRequestModel, handleRetry);
      this.referrersModel = result.data;
    } catch (error) {
      throw error;
    } finally {
    }
  }

  async createReferrer(
    referrersRequestModel: IReferrersRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    try {
      await Api.createReferrers(referrersRequestModel, handleRetry);
    } catch (error) {
      throw error;
    }
  }

  getReferrersModelJs() {
    return toJS(this.referrersModel);
  }

  async getPublicReferralCampaign() {
    try {
      const result = await Api.getPublicReferralCampaign();
      this.referralPublicCampaignModel = result.data;
    } catch (error) {
      throw error;
    } finally {
    }
  }

  getPublicReferralCampaignJs() {
    return toJS(this.referralPublicCampaignModel);
  }

  async getReferralCampaign(referralCampaignId: string) {
    try {
      const result = await Api.getReferralCampaign(referralCampaignId);
      this.referralCampaignModel = result.data;
    } catch (error) {
      throw error;
    } finally {
    }
  }

  getReferralCampaignJs() {
    return toJS(this.referralCampaignModel);
  }

  async applyReferral(
    referralApplyRequestModel: IReferralApplyRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    try {
      await Api.applyReferral(referralApplyRequestModel, handleRetry);
    } catch (error) {
      throw error;
    }
  }
}

export default new ReferralStore();
