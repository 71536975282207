import example from './ExampleStore';
import bill from './BillStore';
import group from './GroupStore';
import inviteFriend from './InviteFriendStore';
import member from './MemberStore';
import calc from './CalculateStore';
import payType from './PayTypeStore';
import bank from './BankAccountStore';
import paymentTrans from './PaymentTransactionStore';
import bankInfo from './BankStore';
import image from './ImageStore';
import resource from './ResourceStore';
import featureStatus from './FeatureStatusStore';
import medal from './MedalStore';
import firebase from './FirebaseStore';
import donateDestination from './DonateDestination';
import reward from './RewardStore';
import campaign from './CampaignStore';
import campaignReward from './CampaignRewardStore';
import schedule from './ScheduleStore';
import scheduleInfo from './ScheduleInfoStore';
import billSummary from './MultipleBill/BillSummary';
import payBySlip from './MultipleBill/PayBySlipStore';
import postbackVerifySlip from './PostBackVerifySlipStore';
import postbackEvent from './PostBackEventStore';
import redeem from './RedeemStore';
import uiHandler from './UIHandlerStore';
import referral from './ReferralStore';
import abTestingStore from './AbTesting/AbTestingStore';
import frontendLogStore from './FrontendLogStore';
import privateBill from './PrivateBillStore';
import deepLinkGenStore from './DeepLinkGenStore';

export default {
  example,
  bank,
  bill,
  group,
  inviteFriend,
  member,
  payType,
  calc,
  paymentTrans,
  bankInfo,
  image,
  resource,
  featureStatus,
  medal,
  firebase,
  donateDestination,
  reward,
  campaign,
  campaignReward,
  schedule,
  scheduleInfo,
  billSummary,
  payBySlip,
  postbackVerifySlip,
  postbackEvent,
  redeem,
  uiHandler,
  referral,
  abTestingStore,
  frontendLogStore,
  privateBill,
  deepLinkGenStore,
};
