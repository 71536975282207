import { IExampleModel } from './../stores/ExampleStore.d';
import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class ExampleApi {
  async getInfo() {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async createBill(data: any) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateInfo(info: IExampleModel) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(info, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new ExampleApi();
