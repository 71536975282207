import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { decryptAESJSONMessage, encryptAESMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';

export class ScheduleApi {
  async GetScheduleConfigs() {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleConfigs}`,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getScheduleBillById(scheduleBillID: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleBill}/${scheduleBillID}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getScheduleBillSummaryById(scheduleBillID: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleBill}/${scheduleBillID}/summary`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      resp.data.runtimeInfo.latestBillId = ApiManager.applyEmpty(
        resp.data.runtimeInfo.latestBillId,
      );

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async createSchedule(data: any) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleBill}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateSchedule(data: any, scheduleId: string) {
    data._id = data.id;
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleBill}/${scheduleId}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async cancelSchedule(data: any, id: string) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PATCH,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleBill}/${id}/cancel`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async GetScheduleImages() {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleImages}`,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async verifyDuplicateSchedule(linegroupId: string, messageId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.ScheduleBill}?lineGroupId=${linegroupId}&messageId=${messageId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getSchedules(lineGroupId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Groups}/${lineGroupId}/schedule_bills`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new ScheduleApi();
