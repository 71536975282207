import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';
import {
  IDeepLinkRequestModel,
  IPaymentRequestModel,
} from 'stores/PayTypeStore.d';
import { IABTestingDeeplink } from 'stores/AbTesting/AbTestingStore.d';

export class KPlusApi {
  async transferMoney(data: any) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.KPlus}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getDeepLink(
    groupId: string,
    mobileNo: string,
    idCard: string,
    type: string,
    deviceId: string,
    referralCampaignId: string,
    abTestingDeeplink: IABTestingDeeplink,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const data = referralCampaignId
        ? {
            // For the referral campaign flow
            group_id: groupId,
            mobile_no: mobileNo,
            id_card: idCard,
            type,
            device_id: deviceId,
            referral_campaign_id: referralCampaignId,
            ab_testing_deeplink: abTestingDeeplink,
          }
        : {
            // For the generic flow
            group_id: groupId,
            mobile_no: mobileNo,
            id_card: idCard,
            type,
            device_id: deviceId,
            ab_testing_deeplink: abTestingDeeplink,
          };

      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.INP}/deeplink-request`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async inquiryName(acccountNo: string) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        { account_no: acccountNo },
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.INP}/inquiry-name`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async verifyKplusToken(memberId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.INP}/kplus-confirm/${memberId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async transferInquiry(
    data: IPaymentRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    console.log('========= transferInquiry - data: ', data);
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.INP}/transferinquiry`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      console.log('========= transferInquiry - resp: ', resp);
      return resp;
    } catch (error) {
      console.log('========= transferInquiry - error: ', error);
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async transferConfirm(data: IPaymentRequestModel) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.INP}/transferconfirm`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  // KPlus Transfer
  async deeplinkRequest({
    memberId,
    billIds,
    platform,
    handleRetry,
  }: IDeepLinkRequestModel) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        {
          billIds,
          member_id: memberId,
          os_platform: platform,
        },
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.KPlusTransfer}/deeplink_request`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async validateReceivingAccount(data: any) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.KPlus}/validation-receiving-account`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  // KPlus Transfer Donate
  async donateDeeplinkRequest(
    groupId: string,
    donateDestinationId: string,
    memberId: string,
    platform: string,
    amount: number,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        {
          group_id: groupId,
          donate_dest_id: donateDestinationId,
          member_id: memberId,
          os_platform: platform,
          amount,
        },
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.KPlusTransferDonate}/deeplink_request`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new KPlusApi();
