import {
  IUploadPrivateSlipModel,
  IUploadSliptModel,
} from './../stores/MultipleBill/PayBySlipStore.d';
import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';
import {
  IPayBySlipRequestModel,
  IPayBySlipWaitingRequestModel,
} from 'stores/MultipleBill/PayBySlipStore.d';

export class PayBySlipApi {
  async payBySlip(
    requestModel: IPayBySlipRequestModel,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        requestModel,
        ServiceName.bot,
      );

      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PayBySlip}`,
        b: dataEncrypt,
        handleRetry,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async payBySlipWaiting(requestModel: IPayBySlipWaitingRequestModel) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        requestModel,
        ServiceName.bot,
      );

      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PayBySlip}/waiting`,
        b: dataEncrypt,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getSlipAmount(postbackEventId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.SlipAmount}/${postbackEventId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error: any) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });

      resp.status = error.status;
      throw resp;
    }
  }

  async payBySlipReject(postBackId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `pay_by_slip_reject/${postBackId}`,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async payBySlipConfirm(postBackId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `pay_by_slip_confirm/${postBackId}`,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async uploadSlipt(
    data: IUploadSliptModel,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.UploadSlipt}`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async uploadSlipPrivateBill(
    data: IUploadPrivateSlipModel,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PrivateBill}/${Api.UploadSlipt}`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async privateBillPayBySlipReject(postBackId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PrivateBill}/pay_by_slip_reject/${postBackId}`,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async privateBillPayBySlipConfirm(postBackId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PrivateBill}/pay_by_slip_confirm/${postBackId}`,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new PayBySlipApi();
