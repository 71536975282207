export const primaryColor = '#00c700';
export const secondaryColor = '#d8d8d8';
export const primaryThemeColor = '#6dccbf';
export const lightGreyColor = '#dbdbdb';
export const errorColor = '#f44336';

const defaultTheme = {
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Kurious_looped',
      'Sarabun',
      'NotoSansThai',
      'NotoSans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
  },
  palette: {
    fontFamily: [
      'Kurious_looped',
      'Sarabun',
      'NotoSansThai',
      'NotoSans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),

    primary: {
      main: primaryColor,
      dark: primaryColor,
    },

    error: {
      main: errorColor,
    },
  },
};

export default defaultTheme;
