import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';
import { IMemberUpdateModel, IConsents } from 'stores/MemberStore.d';

export class MemberApi {
  async getMember(id: string, handleRetry: (tag: string) => void) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/${id}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getMemberByLineUserId(
    lineUserId: string,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    const dataEncrypt = await encryptAESMessage(
      { lineUserId },
      ServiceName.bot,
    );
    try {
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/lineuserid`,
        b: dataEncrypt,
        handleRetry,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async createMember(data: any) {
    // const token = getLineToken();
    try {
      // const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        // h: {
        //   authorization: `Bearer ${token}`,
        // },
        ep: `${Api.Members}`,
        b: data,
      });

      // const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      return result;
    } catch (error) {
      // const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      throw error;
    }
  }

  async updateMember(data: IMemberUpdateModel, id: string) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/${id}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async deleteMemberKPlusAccount(
    lineUserId: string,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        { line_user_id: lineUserId },
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.DELETE,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/kplus`,
        b: dataEncrypt,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateMemberDeviceId(
    id: string,
    deviceId: string,
    isClearKplus: boolean = false,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        { deviceId, isClearKplus },
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/${id}/deviceid`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getMemberShortDetail(memberId: string, lineGroupId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/${memberId}/groups/${lineGroupId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateConsentsByMemberId(
    memberId: string,
    consents: IConsents,
    handleRetry: (tag: string) => void,
  ) {
    const token = getLineToken();
    const dataEncrypt = await encryptAESMessage(consents, ServiceName.bot);
    try {
      const result = await ApiManager.fetch({
        m: Http.PATCH,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/${memberId}/consents`,
        b: dataEncrypt,
        handleRetry,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateMemberSource(source: string) {
    const token = getLineToken();
    const dataEncrypt = await encryptAESMessage({ source }, ServiceName.bot);
    try {
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Members}/source`,
        b: dataEncrypt,
      });
      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  // async validateReceivingAccount(data: any) {
  //   const token = getLineToken();
  //   try {
  //     const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
  //     const result = await ApiManager.fetch({
  //       m: Http.POST,
  //       h: {
  //         authorization: `Bearer ${token}`,
  //       },
  //       ep: `${Api.Members}/validation-receiving-account`,
  //       b: dataEncrypt,
  //     });

  //     const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
  //       isEncoded64: true,
  //     });
  //     return resp;
  //   } catch (error) {
  //     const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
  //       isEncoded64: true,
  //     });
  //     throw resp;
  //   }
  // }
}

export default new MemberApi();
