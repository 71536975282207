import { observable, toJS } from 'mobx';
import ResourceApi from 'api/ResourceApi';
import { IResourceStore, ITermandconditionModel } from './ResourceStore.d';

export class ResourceStore implements IResourceStore {
  @observable termandcondition: ITermandconditionModel;

  getTermAndConditionJs() {
    return toJS(this.termandcondition);
  }

  async getTermAndCondition(filename?: string) {
    try {
      const result = await ResourceApi.getTermAndCondition(filename);
      this.termandcondition = result;
    } catch (error) {
      console.log('error == > ', error);
    }
  }
}

export default new ResourceStore();
