export const PageName = {
  CropImage: '/cropimage',
  CropImageTutorial: '/cropimage-tutorial',
  CreateBill: '/createbill',
  CreateBillOCR: '/bill-ocr',
  BillAssign: '/bill-assign',
  InviteFriend: '/invite',
  InviteMoreFriend: '/invite-friends',
  PayType: '/paytype',
  PayTypeSelected: '/paytype-selected',
  BillInfo: '/summary',
  Bill: '/bill',
  AddBankAccount: '/addbankaccount',
  ConfirmAddAccount: '/confirmaddaccount',
  SelectBank: '/selectbank',
  PaymentSuccess: '/payment-success',
  PaymentSuccessSlip: '/payment-slip',
  AccountManagement: '/account-management',
  VerifyKPlus: '/verify-kplus',
  VerifyKPlusRegister: '/verify-kplus-register',
  Medal: '/medal',
  TransferSlip: '/transfer-slip',
  Tutorial: '/tutorial',
  PDPA: '/pdpa',
  RedeemLocationCode: '/redeem-location-code',

  // handle err or others
  Tablet: '/tablet',
  Example: '/example',
  Error: '/error',
  LinkKplusComplete: '/linkkpluscomplete',
  IFrameToKplus: '/iframekplus',

  // allow link from external, ex. medal image
  Image: '/images',
  // link from backoffice, ex. when change bg slip
  _demoSlip: '/demo-slip',

  // shared facebook success
  SharedFBSuccess: '/success',

  // donation - covid
  Donation: '/donation',
  DonationDestinationInfo: '/donation-info',
  DonationPaymentQR: '/donation-payment-qr',
  DonationPaymentKplus: '/donation-payment-kplus',

  //campaign
  CampaignActivity: '/campaign-activity',
  CampaignInfo: '/campaign-info',
  CampaignRewardMsg: '/campaign-landing',
  RewardInfo: '/reward-info',
  CampaignRewardCode: '/campaign-reward-code',

  //rollcall
  Rollcall: '/rollcall',

  //schedule
  ScheduleCreate: '/schedule-create',
  ScheduleSetting: '/schedule-setting',
  ScheduleAssign: '/schedule-assign',
  ScheduleSummary: '/schedule-summary',

  //multiple bill
  BillSummary: '/bill-summary',
  PaymentSummary: '/payment-summary',
  MultipleBillPaymentBySlip: '/multiple-payment-slip',
  CancelInfo: '/cancel-info',

  //referral campaign
  ReferralInstruction: '/referral-instruction',
  ReferralSuccess: '/referral-success',
  ReferralSharePreview: '/referral-sharepreview',
  ReferralAccept: '/referral-accept',

  CreateBillTemplate: '/create-bill-template',

  //private request
  GroupSummary: '/group-summary',
  BillCenter: '/bill-center',
  CreatePrivateBill: '/create-private-bill',
  PreviewPrivateBill: '/preview-private-bill',
  PrivateBillSummary: '/private-bill-summary',
  GroupList: '/group-list',
  PreviewCreateBill: '/preview-create-bill',
  PreviewCreateBillOCR: '/preview-create-bill-ocr',
  PrivateBillPaymentSucces: '/private-bill-payment-success',
  DeepLinkCreateBill: '/deeplink-create-bill',

  InitLiffFull: '/init-liff-full',
  InitLiffTall: '/init-liff-tall',
  RobotLogin: '/robot-login',
};
