import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

export interface ITranslateProps {
  t?: any;
  langKey: string;
  ns?: string;
  options?: any;
}

export enum Namespaces {
  Translation = 'translation',
  Common = 'common',
  Termandcondition = 'termandcondition',
}

export function CurrentLanguage() {
  return i18n.language;
}

export function ChangeLanguage(lang: string) {
  i18n.changeLanguage(lang);
}

export function Trans(langKey: string, options?: any, ns?: Namespaces) {
  if (ns) {
    return i18n.t(`${ns}:${langKey}`, options);
  }
  return i18n.t(langKey, options);
}

export class Translate extends React.PureComponent<ITranslateProps> {
  render() {
    const { langKey, options, ns } = this.props;
    if (ns) {
      return this.props.t(`${ns}:${langKey}`, options);
    }
    return this.props.t(langKey, options);
  }
}

export default {
  Translation: withTranslation(Namespaces.Translation)(Translate as any) as any,
  Common: withTranslation(Namespaces.Common)(Translate as any) as any,
  Termandcondition: withTranslation(Namespaces.Termandcondition)(
    Translate as any,
  ) as any,
};
