import fetch from 'isomorphic-unfetch';
import qs from 'qs';
import humps from 'humps';
import config from 'config';
import { isCypressMode } from 'utils/Cypress';
import {
  CantGetLineIdRetryMaxCount,
  CantGetLineIdRetryTimeout,
  CommonError,
  Environment,
  ServiceName,
} from 'definitions/constant';
import { consoleLog, isObjectEmpty } from 'utils/Common';
import { history } from 'utils/History';
import UrlUtils from 'utils/Url';
import { PageName } from 'definitions/pageName';
import { CurrentLanguage } from 'common/components/Translate';
import { decryptAESJSONMessage } from 'crypto/index';

let lineToken;
let sessionOcr;

const requestTimeout = 30000;

export function setLineToken(token: string) {
  lineToken = token;
}

export function getLineToken() {
  return lineToken;
}

export function setSessionOcr(session: string) {
  sessionOcr = session;
}

export function getSessionOcr() {
  return sessionOcr;
}

export interface IFetchOption {
  body?: any;
  headers?: any;
  credentials?: string;
  method: any;
  timeout: number;
}

export enum RequestType {
  form = 1,
  multipart = 2,
}

export const Type = {
  form: 1,
  multipart: 2,
};

export class ApiManager {
  static Type = RequestType;
  debug: boolean = false;
  baseUrl: string;
  apiVersion: string;
  referer: any;
  cookie: any;

  constructor(baseUrl?: string, apiVersion?: string, referer?: any) {
    this.baseUrl = baseUrl || config.api.socialbot;
    this.apiVersion = apiVersion === 'NONE' ? '' : apiVersion || 'v3';
    this.referer = referer;
  }

  createUrl(ep: string, p: string = '', q: any = {}): string | Promise<any> {
    const { path, params } = UrlUtils.extractPathAndParams(ep);
    let url;
    try {
      url = UrlUtils.buildUrl(
        this.baseUrl,
        isCypressMode ? 'tkb' : '',
        this.apiVersion,
        encodeURI(decodeURI(path)),
      );
    } catch (err) {
      consoleLog('Encoding endpoint error :', ep);
      consoleLog(err);
      return Promise.reject({
        message: 'TECHNICAL_ERROR',
      });
    }

    if (ep.startsWith('http')) {
      url = ep;
    }

    const allParams = { ...params, ...q };

    return UrlUtils.buildUrlWithParams(url.concat(p), allParams);
  }

  createFetchOption(
    url: string,
    m: string,
    b: any,
    t: RequestType,
    h: any,
    timeout?: any,
  ): IFetchOption {
    const fetchOption: IFetchOption = {
      method: m,
      timeout: Boolean(timeout) ? timeout : requestTimeout,
    };
    if (b) {
      if (t === RequestType.multipart) {
        const data = new FormData();
        for (const key of Object.keys(b)) {
          data.append(key, b[key]);
        }
        fetchOption.body = data;
      } else {
        if (t === RequestType.form) {
          fetchOption.headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
          };
          fetchOption.body = qs.stringify(b);
        } else {
          fetchOption.body = JSON.stringify(b);
          fetchOption.headers = { 'Content-Type': 'application/json' };
        }

        const { body } = fetchOption;
        fetchOption.headers['Content-Length'] = Buffer.byteLength(body);
      }
    }

    fetchOption.headers = Object.assign({}, fetchOption.headers, h, {
      cookie: this.cookie,
      referer: this.referer,
    });

    if (process.env.ROLE !== 'server') {
      fetchOption.credentials = 'same-origin';
    }
    if (process.env.NODE_ENV !== 'production') {
      if (this.debug) {
        consoleLog('API Call', url, fetchOption);
      }
    }
    return fetchOption;
  }

  fetch(options: {
    m?: string;
    ep?: string;
    p?: string;
    q?: any;
    b?: any;
    t?: any;
    h?: any;
    handleRetry?: (tag: string) => void;
    timeout?: number;
    ignoreError?: boolean;
  }) {
    let requestCount = 0;

    const fetchWorker = () => {
      if (!isObjectEmpty(options.b) && options.t !== RequestType.multipart) {
        options.b = humps.decamelizeKeys(options.b);
      }

      const { m, ep, p, q, b, t, h, handleRetry, timeout, ignoreError } =
        Object.assign({ m: 'GET', q: {} }, options);

      const url = this.createUrl(ep, p, q) as string;
      const fetchOption = this.createFetchOption(url, m, b, t, h, timeout);
      const anyFetchOption = fetchOption as any;

      const controller = new AbortController();
      const signal = controller.signal;

      const thisRequestTimeout = Boolean(timeout) ? timeout : requestTimeout;

      setTimeout(() => {
        controller.abort();
      }, thisRequestTimeout);

      return fetch(url, { ...anyFetchOption, signal }).then(
        (res) => {
          // console.log(`${m.toUpperCase()} ${res.status} /${ep}`);
          // console.log('========= res.status: ', res.status);

          if (res.status === 503) {
            window.location.href = `${config.publicUrl}/maintenance.html`;
            return
          }

          const lang = CurrentLanguage();
          if (
            (res.status === 403 || res.status === 500) &&
            !Boolean(ignoreError)
          ) {
            if (++requestCount >= CantGetLineIdRetryMaxCount) {
              history.replace(
                `${PageName.Error}?code=${res.status}&result=${CommonError.CantGetLineID}&force-lang=${lang}`,
              );

              // Delay this so, don't show the current page before the fully change the page by history.replace
              setTimeout(() => {
                return Promise.reject({});
              }, 1000);
            } else {
              return res.json().then(async (json) => {
                if (
                  Boolean(json) &&
                  CommonError.CantGetLineID === json.message
                ) {
                  if (Boolean(handleRetry))
                    handleRetry(CommonError.CantGetLineID);

                  // Wait for n secs
                  await new Promise((resolve) => {
                    setTimeout(() => {
                      resolve(0);
                    }, CantGetLineIdRetryTimeout);
                  });
                  try {
                    let res = await fetchWorker();
                    return Promise.resolve(res);
                  } catch (e) {
                    return Promise.reject(e);
                  }
                } else {
                  const result = Boolean(json) ? json.message : '';
                  history.replace(
                    `${PageName.Error}?code=${res.status}&result=${result}&force-lang=${lang}`,
                  );
                }
              });
            }
          }
          if (res.status === 204) {
            return null;
          }

          if (res.ok) {
            // no response body
            if (res.status === 204) {
              return Promise.resolve();
            }

            // return res;
            return res.json().then(async (json) => {
              this.logAPIResponse({ m, res, ep, json });

              if (!isObjectEmpty(json)) {
                json = humps.camelizeKeys(json);
              }
              json._q = q;

              return json;
            });
          }

          // error
          // @ts-ignore
          return new Promise((resolve, reject) => {
            const errorResponse: any = { status: res.status };
            res
              .json()
              .then((out) => {
                if (out.errorMessage) {
                  errorResponse.message = out.errorMessage;
                } else if (out.message) {
                  errorResponse.message = out.message;
                } else if (out.data) {
                  errorResponse.data = out.data;
                }
                if (out.error_code) {
                  errorResponse.errorCode = out.error_code;
                }

                errorResponse.iv = out.iv || '';

                this.logError(res, errorResponse);

                reject(errorResponse);
              })
              .catch((err) => {
                console.log('========= API 1 - err: ', err);
                this.logError(res, errorResponse, err);
                errorResponse.message = err;
                reject(errorResponse);
              });
          });
        },
        (err) => {
          console.log('========= API 2 - err: ', err);
          if (
            err.message === 'Failed to fetch' ||
            err.message === 'The Internet connection appears to be offline.'
          ) {
            const errorResponse: any = {};
            errorResponse.iv = CommonError.FailToFetch;
            errorResponse.message = err;
            this.logError({ url }, errorResponse, err);
            return errorResponse;
          }
        },
      );
    };

    return fetchWorker();
  }

  logError(res: any, errorResponse: any, err?: any) {
    consoleLog('[ERROR][ApiManager][REQ]', res.url);
    consoleLog(errorResponse);

    if (err) {
      consoleLog(err);
    }
  }

  async logAPIResponse({ m, res, ep, json }) {
    if (
      config.env === Environment.LOCALHOST ||
      config.env === Environment.UAT
    ) {
      try {
        const resp = await decryptAESJSONMessage(json, ServiceName.bot, {
          isEncoded64: true,
        });
        console.log(
          `%c${m.toUpperCase()}:${res.status} %c/${ep}`,
          'color: green; font-weight:bold;',
          'color: black',
          resp,
        );
      } catch {}
    }
  }

  applyEmpty(value: string) {
    return value === '000000000000000000000000' ? null : value;
  }
}
export default new ApiManager();
