import { observable } from 'mobx';
import Api from 'api/FirebaseApi';
import { IFirebaseStore } from './FirebaseStore.d';
const firebase = (window as any).firebase;

export class FirebaseStore implements IFirebaseStore {
  @observable isLoading: boolean;

  constructor() {
    this.isLoading = false;
  }

  initSchema() {
    return {
      username: '',
      password: '',
    };
  }

  getFirebaseAuth = async (lineUserId: string, groupId: string) => {
    this.isLoading = true;
    let firebaseAuth = this.initSchema() as any;
    try {
      const result = await Api.getAuth(lineUserId, groupId);
      firebaseAuth = result.data || this.initSchema();
    } catch (error) {
      firebaseAuth = this.initSchema();
    } finally {
      this.isLoading = false;
    }
    return firebaseAuth;
  };

  getFirebaseInstance = async (lineUserId: string, groupId: string) => {
    try {
      const currentUser = await firebase.auth().currentUser;

      if (!!currentUser) {
        console.log('Firebase user logged in, return the instance');
        console.log('Has current user', !!currentUser);
        return firebase;
      } else {
        console.log('Firebase user seems not logged in, log it in!');
        const firebaseAuth = await this.getFirebaseAuth(lineUserId, groupId);
        const token = firebaseAuth;

        try {
          // await firebase
          //   .auth(firebase)
          //   .signInWithEmailAndPassword(
          //     firebaseAuth.username,
          //     firebaseAuth.password,
          //   );
          await firebase.auth(firebase).signInWithCustomToken(token);

          const currentUser = await firebase.auth().currentUser;
          const hasCurrentUser = Boolean(currentUser);
          if (!hasCurrentUser) {
            throw new Error('No current user');
          }
          console.log('Firebase login success!');
          console.log('Has current user', hasCurrentUser);
        } catch (error) {
          console.log('Error firebase login:', error);
          // Temporarily allow to continue when login error, should strict it but later.
          //throw new Error('Firebase Login Error');
        }

        const hasCurrentUser = Boolean(await firebase.auth().currentUser);
        console.log('Has current user', hasCurrentUser);

        return firebase;
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

export default new FirebaseStore();
