import { observable, toJS } from 'mobx';
import set from 'lodash/set';
import { IBankAccountStore, IBankAccountModel } from './BankAccountStore.d';

export class BankAccountStore implements IBankAccountStore {
  @observable bankInfo: IBankAccountModel;
  @observable bankListInfo: IBankAccountModel[];

  constructor() {
    this.initSchema();
  }

  initSchema() {
    this.bankInfo = {
      bankId: 0,
      name: '',
      bankName: '',
      bankNameEn: '',
      bankLogo: '',
      bankNo: '',
      bankCode: '',
      isAccountPromptpay: false,
      accountNoLength: '',
    };
    this.bankListInfo = [];
  }

  getBankInfoJs() {
    return toJS(this.bankInfo);
  }

  resetInfo() {
    this.bankInfo = {} as any;
  }

  setInfo(key: string, val: any) {
    const info = this.getBankInfoJs();
    set(info, key, val);
    this.bankInfo = info;
  }
}

export default new BankAccountStore();
