import PrivateBillApi from 'api/PrivateBillApi';
import { isArrayEmpty } from 'utils/Common';
import { PaymentStatus } from './../definitions/constant';
import { observable, toJS } from 'mobx';
import {
  IGetBillPaymentTrans,
  IPaymentTransactionModel,
} from './PaymentTransactionStore.d';
import { PaymentMethod } from 'definitions/constant';
import PaymentTransApi from 'api/PaymentTransApi';
import BillApi from 'api/BillApi';

export class PaymentTransactionStore implements PaymentTransactionStore {
  @observable paymentTrans: IPaymentTransactionModel;
  @observable paymentTransaction: IPaymentTransactionModel[];
  @observable paymentBillTransaction: IPaymentTransactionModel[];

  constructor() {
    this.resetAllData();
  }

  initPaymentTransSchema() {
    return {
      id: '',
      billIds: [],
      memberId: '',
      amount: undefined,
      paymentMethod: '',
      paymentStatus: '',
      txnId: '',
      updatedDate: undefined,
      inpRefNo: '',
      paymentDescription: '',
    };
  }

  resetAllData() {
    this.paymentTransaction = [this.initPaymentTransSchema()];
    this.paymentTrans = this.initPaymentTransSchema();
    this.paymentBillTransaction = [this.initPaymentTransSchema()];
  }

  getPaymentTransJs() {
    return toJS(this.paymentTrans);
  }

  resetPaymentTransJs() {
    this.paymentTrans = this.initPaymentTransSchema();
  }

  getPaymentTransactionJs() {
    return toJS(this.paymentTransaction);
  }

  getPaymentBillTransactionJs() {
    return toJS(this.paymentBillTransaction);
  }

  async getSlipImages(paymentTransaction: IPaymentTransactionModel[]) {
    await Promise.all(
      paymentTransaction.map(async (pt) => {
        if (pt.paymentMethod === PaymentMethod.Transfer) {
          if (pt.imageUrl) {
            pt.slipImg = pt.imageUrl;
          } else if (pt.slipMessageId) {
            const res: any = await BillApi.getOriginalImageBill(
              pt.slipMessageId,
            );
            if (res.data) {
              pt.slipImg = res.data;
            }
          }
        }
      }),
    );

    return paymentTransaction;
  }

  async getBillPaymentTransByMember({
    billID,
    memberID,
    imgSlip,
    isPrivateBill,
    lineUserId,
  }: IGetBillPaymentTrans) {
    try {
      const result = isPrivateBill
        ? await PrivateBillApi.getPaymentTransByMember(
            billID,
            lineUserId,
            memberID,
          )
        : await PaymentTransApi.getPaymentTransByMember(billID, memberID);
      if (imgSlip) {
        this.paymentTransaction = await this.getSlipImages(result.data);
      } else {
        this.paymentTransaction = result.data;
      }
      return true;
    } catch (error) {
      console.log('getBillPaymentTransByMember error == > ', error);
      this.paymentTransaction = [this.initPaymentTransSchema()];
      return false;
    }
  }

  async getBillPaymentTransByBill(billID: string, imgSlip?: boolean) {
    try {
      const result = await PaymentTransApi.getPaymentTransByBill(billID);
      if (imgSlip) {
        this.paymentBillTransaction = await this.getSlipImages(result.data);
      } else {
        this.paymentBillTransaction = result.data;
      }
      return true;
    } catch (error) {
      console.log('getBillPaymentTransByBill error == > ', error);
      this.paymentBillTransaction = [this.initPaymentTransSchema()];
      return false;
    }
  }

  async getBillPaymentTransByID(paymentTransId: string) {
    try {
      const result = await PaymentTransApi.getPaymentTransByID(paymentTransId);
      this.paymentTrans = result.data;
      return true;
    } catch (error) {
      console.log('getBillPaymentTransByID error == > ', error);
      this.paymentTrans = this.initPaymentTransSchema();
      return false;
    }
  }

  getPaymentTransactionOnlyPaid(
    paymentTransaction: IPaymentTransactionModel[],
  ) {
    if (!isArrayEmpty(paymentTransaction)) {
      const paymentTransactionOnlyPaid = paymentTransaction.filter(
        (paymentTrans) => {
          if (
            paymentTrans.paymentMethod === PaymentMethod.KhunThong &&
            paymentTrans.paymentStatus !== PaymentStatus.Paid
          ) {
            return false;
          }
          return true;
        },
      );
      return paymentTransactionOnlyPaid;
    }
    return [];
  }

  // mock
  mockPaymentTransJs() {
    return toJS({
      id: '5daeadff70f2e6000169f41b',
      billId: '5dad6d8f0a2d95160a3a82ad',
      memberId: '5d89acda0a2d9565a0f6fdf4',
      amount: 40,
      paymentMethod: 'KHUNTHONG',
      paymentStatus: 'PAID',
      txnId: '25705_20191022_2520745505',
      updatedDate: undefined, // '2019-10-22T07:21:36.094+0000',
      inpRefNo: '22102019|072136|0000000089|W|1262|INP20000|20191022072118900',
      paymentDescription: '',
    });
  }
}
export default new PaymentTransactionStore();
