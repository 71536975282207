// import { getConfig } from 'utils/Configuration';
import IPayBySlipStore, {
  IUploadSliptModel,
  IPayBySlipRequestModel,
  IPayBySlipModel,
  ISlipAmount,
  IPayBySlipWaitingRequestModel,
  IConfirmationInfoModel,
  IUploadPrivateSlipModel,
} from './PayBySlipStore.d';
import PayBySlipApi from 'api/PayBySlipApi';
import { observable, toJS } from 'mobx';
import { Trans } from 'common/components/Translate';
import Swal from 'common/components/Swal';
import { getConfig } from 'utils/Configuration';

export class PayBySlipStore implements IPayBySlipStore {
  @observable
  payBySlipModel: IPayBySlipModel;

  @observable
  slipAmount: ISlipAmount;

  @observable
  confirmationInfo: IConfirmationInfoModel;

  @observable imageUploadSlip: any;

  constructor() {
    this.imageUploadSlip = '';
  }

  payBySlip = async (
    requestModel: IPayBySlipRequestModel,
    handleRetry: (tag: string) => void,
  ) => {
    const res = await PayBySlipApi.payBySlip(requestModel, handleRetry);
    const isSuccess = !!(res && res.data);
    if (isSuccess) {
      this.payBySlipModel = res.data as IPayBySlipModel;
    }
    return isSuccess;
  };

  payBySlipWaiting = async (requestModel: IPayBySlipWaitingRequestModel) => {
    const res = await PayBySlipApi.payBySlipWaiting(requestModel);
    const isSuccess = !!(res && res.data);
    if (isSuccess) {
      this.payBySlipModel = res.data as IPayBySlipModel;
    }
    return isSuccess;
  };

  getPayBySlipModelJs() {
    return toJS(this.payBySlipModel);
  }

  getSlipAmountJs() {
    return toJS(this.slipAmount);
  }

  async getSlipAmount(postbackEventId: string) {
    try {
      const result = await PayBySlipApi.getSlipAmount(postbackEventId);

      this.slipAmount = result.data || undefined;
    } catch (error) {
      this.slipAmount = undefined;
      throw error;
    } finally {
    }
  }

  setConfirmationInfoModel(model: IConfirmationInfoModel) {
    this.confirmationInfo = model;
  }

  getConfirmationInfoModelJs() {
    return toJS(this.confirmationInfo);
  }
  payBySlipReject = async (postBackId: string) => {
    let isSuccess = false;
    try {
      const res = await PayBySlipApi.payBySlipReject(postBackId);
      isSuccess = !!(res && res.data);
    } catch (error) {}
    return isSuccess;
  };

  payBySlipConfirm = async (postBackId: string) => {
    let isSuccess = false;
    try {
      const res = await PayBySlipApi.payBySlipConfirm(postBackId);
      isSuccess = !!(res && res.data);
    } catch (error) {}
    return isSuccess;
  };

  setImageUploadSlip(image: any) {
    this.imageUploadSlip = image;
  }

  getImageUploadSlip() {
    return this.imageUploadSlip;
  }

  async uploadSlipt(
    data: IUploadSliptModel,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const result = await PayBySlipApi.uploadSlipt(data, handleRetry);
      this.payBySlipModel = result.data;
      console.log('result.data', result.data);
      return '';
    } catch (error: any) {
      return error.message;
    }
  }

  async uploadPrivateBillSlip(
    data: IUploadPrivateSlipModel,
    handleRetry: (tag: string) => void,
  ) {
    try {
      const result = await PayBySlipApi.uploadSlipPrivateBill(
        data,
        handleRetry,
      );
      this.payBySlipModel = result.data;
      console.log('result.data', result.data);
      return '';
    } catch (e) {
      const error = e as Error;
      return error.message;
    }
  }

  processAndSetImage = (file) => {
    const configuration = getConfig();
    const maxSizeMB = configuration?.goQr.imageSizeLimit || 1;

    if (!/image/i.test(file.type)) {
      console.log('file', file);
      console.log('file.type', file.type);
      Swal.error({
        showIconKhunThong: true,
        showIconKey: 'KhunThongErrIcon',
        html: Trans('payTypePage.wrongImageType'),
        confirmButtonText: Trans('message.common.btnOkay'),
      });
      return false;
    }

    const MBSize = file.size / 1024 / 1024;
    if (MBSize >= maxSizeMB) {
      Swal.error({
        showIconKhunThong: true,
        showIconKey: 'KhunThongErrIcon',
        html: Trans('payTypePage.overImageSize'),
        confirmButtonText: Trans('message.common.btnOkay'),
      });
      return false;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.addEventListener('load', () => {
      if (reader.result) {
        const blob = new Blob([reader.result as any]);
        const blobURL = window.URL.createObjectURL(blob);

        const image = new Image();
        image.src = blobURL;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const width = image.width;
          const height = image.height;

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);
          this.setImageUploadSlip(canvas.toDataURL('image/jpeg', 1));
        };
      }
    });
    return true;
  };

  privateBillPayBySlipReject = async (postBackId: string) => {
    let isSuccess = false;
    try {
      const res = await PayBySlipApi.privateBillPayBySlipReject(postBackId);
      isSuccess = !!(res && res.data);
    } catch (error) {}
    return isSuccess;
  };

  privateBillPayBySlipConfirm = async (postBackId: string) => {
    let isSuccess = false;
    try {
      const res = await PayBySlipApi.privateBillPayBySlipConfirm(postBackId);
      isSuccess = !!(res && res.data);
    } catch (error) {}
    return isSuccess;
  };
}

export default new PayBySlipStore();
