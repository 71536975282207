import { observable, toJS } from 'mobx';
import Api from 'api/BankApi';
import config from 'config';
import { IBankStore, IBankModel } from './BankStore.d';

export class BankStore implements IBankStore {
  @observable bankInfo: IBankModel;
  @observable bankList: IBankModel[];

  getBankListJs() {
    return this.bankList;
  }

  getBankJs() {
    return toJS(this.bankInfo || this.initSchema());
  }

  async getBankList() {
    try {
      if (!this.bankList) {
        const result = await Api.getBanks();
        this.bankList = result.data || [this.initSchema()];
      }
    } catch (error) {
      this.bankList = [this.initSchema()];
      throw error;
    } finally {
    }
  }

  initSchema() {
    return {
      id: '',
      hexCode: '',
      logo: `${config.api.s3}/resources/kasikorn.png`, // 'https://uatkhunthong.kasikornbank.com/resources/kasikorn.png',
      swiftCode: '',
      officialName: { th: '', en: '' },
      shortName: { th: '', en: '' },
      bankCode: '',
      accountNoLength: '',
    };
  }
}

export default new BankStore();
