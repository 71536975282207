import ApiManager from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';

export class FeatureStatusApi {
  async getFeatureStatus() {
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        ep: `${Api.FeatureStatus}`,
      });

      return result;
    } catch (error) {
      throw error;
    }
  }  
}

export default new FeatureStatusApi();
