import { IBillMemberModel } from 'stores/BillStore.d';
import { BillStatus, BillType, BillSource } from 'definitions/constant';
import { IMemberModel, IAccountModel } from './MemberStore.d';

export enum BillCenterTab {
  BillInGroupTab = 'group-bill',
  BillPrivateTab = 'private-bill',
}

export enum CreatePrivateBillTab {
  RequestOne,
  RequestMany,
}

export enum CreatePrivateBillError {
  FEATURE_OFF = 'FEATURE_OFF',
  TOO_MANY_BILLS = 'TOO_MANY_BILLS',
  REACH_AMOUNT_LIMIT = 'REACH_AMOUNT_LIMIT',
  TOO_MANY_PAYER = 'TOO_MANY_PAYER',
  REACH_LIMIT_AMOUNT_PER_BILL = 'INVALID_AMOUNT',
  INVALID_NUMBER_OF_PAYER = 'INVALID_NUMBER_OF_PAYER',
  NOTE_EXCEED_LIMIT = 'NOTE_EXCEED_LIMIT',
}

export enum AddPrivateBillMemberError {
  TooManyPayer = 'TOO_MANY_PAYER',
  BillNotActive = 'BILL_NOT_ACTIVE',
  PrivateBillMemberNotFound = 'PRIVATE_BILL_MEMBER_NOT_FOUND',
}

export interface IPrivateBillModel {
  id: string;
  name: string;
  billImage: string;
  total: number;
  numberOfPayer: number;
  isIncludeOwner: boolean;
  ownerLineUserId: string;
  note: string;
  status: BillStatus;
  ownerLineUserId: string;
  account: IAccountModel;
  ownerMember?: IMemberModel;
  members: IBillMemberModel[];
  expireDate: string;
  createdDate: string;
  amountForEachPayer: number;
  source: string;
  deeplinkId?: string;
}

export interface IPrivateBillCreateModel {
  id: string;
  name: string;
  billImage: string;
  total: number;
  numberOfPayer: number;
  isIncludeOwner: boolean;
  ownerLineUserId: string;
  expireDate: string;
  account: IAccountModel;
  note: string;
  amountForEachPayer: number;
  source: string;
  deeplinkId: string;
}

export interface IPrivateBillMemberModel {
  id?: string;
  memberId: string;
  total: number;
  profile?: IMemberModel;
  acknowledgeToJoin: boolean;
}

export interface IPrivateBillFlex {
  name: string;
  total: string;
  billImage: string;
  bankName: string;
  bankNo: string;
  ownerName: string;
  note?: string;
  billId: string;
  bankLogo: string;
}

export interface IPrivateGroupModel {
  groupPicture: string;
  groupName: string;
  lineGroupId: string;
  members: IGroupMemberModel[];
}

export enum PrivateBillMode {
  Payer = 'payer',
  Owner = 'owner',
  All = 'all',
}

export interface IGetPrivateBillsCondition {
  name?: string;
  status: BillStatus[];
  mode: PrivateBillMode;
  from?: string;
  to?: string;
  lineUserId: string;
  lastId?: string;
  limit: number;
}

export interface IBillHistory {
  id: string;
  name: string;
  lineGroupId: string;
  groupName: string;
  groupPicture: string;
  account: IAccountModel;
  members: IBillMemberModel[];
  source: string;
  type: string;
  status: string;
  ownerMemberId: string;
  ownerMember: IMemberModel;
  createdDate: string;
  expiredDate: string;
  total: number;
}

export interface IActionPrivateBillMember {
  billId: string;
  lineUserId: string;
  payerLineUserId?: string;
}

export enum ActionPrivateBillMember {
  AddMember,
  RemoveMember,
  MarkAsPaid,
  ConfirmCash,
  RejectCash,
}

export interface IGroupBill {
  billOwners: IBillsInGroup[];
  billPayers: IBillsInGroup[];
}

export interface IBillsInGroup {
  bills: IBillInGroup;
  botStatus: string;
  createdDate: Date;
  groupName: string;
  groupPicture: string;
  isMultiBillEnable: boolean;
  language: string;
  lineGroupId: string;
  members: IBillMemberModel[];
  ownerMemberInfo: IBillMemberModel;
  type: string;
  updatedDate: Date;
  id: string;
}

export interface IBillInGroup {
  account: IAccountModel;
  createdDate: string;
  expiredDate: string;
  lineGroupId: string;
  members: IBillMemberModel[];
  name: string;
  ownerMemberId: string;
  requestTotal: number;
  scheduleImage1Url: string;
  scheduleImage2Url: string;
  scheduleImage3Url: string;
  source: BillSource;
  status: BillStatus;
  total: number;
  type: BillType;
  updatedDate: string;
  id: string;
}

export interface IPrivateBillStore {
  currentTab: BillCenterTab;
  createBillTab: CreatePrivateBillTab;
  privateBillsConfirmed: IPrivateBillModel[];
  privateBillsPaid: IPrivateBillModel[];
  privateBill: IPrivateBillModel;
  privateBillCreate: IPrivateBillModel;
  billsInGroup: IGroupBill;
  selectedGroup: IPrivateGroupModel;
  completeBillsInGroup: IBillHistory[];
  lastBillsInGroupPaidId: string;
  lastPrivateBillsPaidId: string;
  privateBillCreateSaved: {
    numberOfPayer: number;
    isIncludeOwner: boolean;
  };

  setPrivateBillCreate: (key: string, value: any) => void;
  resetPrivateBillCreate: () => void;

  getPrivateBillsConfirmed: (data: IGetPrivateBillsCondition) => Promise<void>;
  getPrivateBillsConfirmedJs: () => IPrivateBillModel[];
  getPrivateBillsPaid: (data: IGetPrivateBillsCondition) => Promise<void>;
  getPrivateBillsPaidJs: () => IPrivateBillModel[];
  getPrivateBillsOwner: (data: IGetPrivateBillsCondition) => Promise<void>;
  getPrivateBillsOwnerJs: () => IPrivateBillModel[];

  getPrivateBillJs: () => IPrivateBillModel;
  getPrivateBillCreateJs: () => IPrivateBillModel;
  getPrivateBillById: (billId: string, lineUserId: string) => Promise<void>;
  resetPrivateBill: () => void;

  getGroups: () => Promise<void>;
  getGroupsJs: () => IPrivateGroupModel[];

  getBillsInGroup: () => Promise<void>;
  getBillsInGroupJs: () => IGroupBill;
  getCompleteBillsInGroup: (limit: number) => Promise<void>;
  getCompleteBillsInGroupJs: () => any[];

  getSelectedGroupJs: () => IPrivateGroupModel;
  setSelectedGroup: (value: IPrivateGroupModel) => void;

  createPrivateBill: () => Promise<{
    isSuccess: boolean;
    message?: string;
    billId?: string;
  }>;
  cancelPrivateBill: (data: IActionPrivateBillMember) => Promise<T>;

  resetPagination: () => void;

  addPrivateBillMember: (data: IActionPrivateBillMember) => Promise<T>;
  removePrivateBillMember: (data: IActionPrivateBillMember) => Promise<T>;
  leavePrivateBillMember: (data: IActionPrivateBillMember) => Promise<T>;

  markAsPaid: (data: IActionPrivateBillMember) => Promise<T>;
  confirmCash: (data: IActionPrivateBillMember) => Promise<T>;
  rejectCash: (data: IActionPrivateBillMember) => Promise<T>;
  acknowledgeJoinBill: (data: IActionPrivateBillMember) => Promise<T>;

  payCashPrivateBill: (data: IActionPrivateBillMember) => Promise<T>;

  saveNumberOfPayer: (numberOfPayer: number) => void;
  saveIsIncludeOwner: (isIncludeOwner: boolean) => void;
}
