import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import { encryptAESMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';
import { IFrontendLogModel } from '../stores/FrontendLogStore.d';
import { generateUUID } from 'utils/Common';
export class FrontendLogApi {
  sessionId: string;

  async LogFrontend(body: IFrontendLogModel) {
    // Lazy generate sessionId
    if (!this.sessionId) {
      this.sessionId = generateUUID();
    }

    // Attach client timestamp to body
    const bodyWithClientTimeStamp = {
      ...body,
      sessionId: this.sessionId,
      clientTimestamp: new Date().toISOString(),
    };
    //console.log('bodyWithClientTimeStamp', bodyWithClientTimeStamp);

    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        bodyWithClientTimeStamp,
        ServiceName.bot,
      );
      //console.log('dataEncrypt', dataEncrypt);

      await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.LogFrontend}`,
        b: dataEncrypt,
        ignoreError: true,
      });

      // const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      // return resp;
      return true;
    } catch (error) {
      // const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
      //   isEncoded64: true,
      // });
      //throw resp;

      // This log will be rised unintentionally, due to backend does NOT return response, just let it be specs
      //console.log(`LogFrontend: ${error}`);
      return false;
    }
  }
}

export default new FrontendLogApi();
