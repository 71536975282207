import {
  IBillSummaryQS,
  IBillSummaryStore,
  IBillsSummarise,
  IBillsPriorityList,
  IBillsPayment,
  IBillSummarise,
  IBillsPriority,
} from './BillSummary.d';
import { observable, toJS } from 'mobx';
import Api from 'api/BillSummaryApi';
import BillApi from 'api/BillApi';
import { BillStatus, BillPrioritiesAction } from 'definitions/constant';
import { isArrayEmpty } from 'utils/Common';

export class BillSummaryStore implements IBillSummaryStore {
  @observable
  billsSummary: IBillsSummarise[];
  billSummaryQS: IBillSummaryQS;

  @observable
  billPriority: IBillsPriorityList;

  @observable
  billsPayment: IBillsPayment;

  @observable
  billsPaymentIdCheckedList: string[];

  @observable
  billOCRStatusNiew: [];

  currentTab: boolean;

  constructor() {
    this.resetAllData();
  }

  resetAllData() {
    this.billsSummary = [];
    this.billPriority = this.initBillPriority();
    this.billsPayment = this.initBillsPayment();
    this.billSummaryQS = this.initIBillSummaryQS();
    this.billsPaymentIdCheckedList = [];
    this.currentTab = true;
    this.billOCRStatusNiew = [];
  }

  initIBillSummaryQS() {
    return {
      tab: '',
      groupId: '',
      action: '',
    };
  }

  initBillPriority() {
    return {
      priorityBills: [],
      ownerBills: [],
      otherBills: [],
      otherOcrBillStatusNew: [],
    };
  }

  initBillsPayment() {
    return {
      ownerName: '',
      bills: [],
    };
  }

  addSavedPaymentCheckList(billId: string) {
    this.billsPaymentIdCheckedList.push(billId);
  }

  removeSavedPaymentCheckList(billId: string) {
    this.billsPaymentIdCheckedList = this.billsPaymentIdCheckedList.filter(
      (id) => id !== billId,
    );
  }

  getSavedPaymentCheckList() {
    return this.billsPaymentIdCheckedList;
  }

  setBillsPayment(ownerName: string, bills: IBillSummarise[]) {
    this.billsPayment.ownerName = ownerName;
    this.billsPayment.bills = bills;
  }

  getBillsPaymentJs() {
    return toJS(this.billsPayment);
  }

  async getBillsSummary(
    lineGroupId: string,
    memberId: string,
    postbackEventID?: string,
  ) {
    try {
      const result = await Api.getBillsSummary(
        lineGroupId,
        memberId,
        postbackEventID,
      );
      this.billsSummary = result.data || [];
    } catch (error) {
      this.billsSummary = [];
    } finally {
    }
  }

  async remindBill(billId: string) {
    try {
      await Api.remindBill(billId);
      return true;
    } catch (error) {
      return false;
    }
  }

  async cancelBill(billId: string) {
    try {
      await BillApi.updateStatusBill({ Status: BillStatus.Cancel }, billId);
      return true;
    } catch (ex: any) {
      console.log('update bill error: ', ex.message);
      return false;
    }
  }

  getBillsSummaryJs() {
    return toJS(this.billsSummary);
  }

  async getSlipImages(priorityBills: IBillsPriority[]) {
    await Promise.all(
      priorityBills.map(async (bill) => {
        if (bill.type === BillPrioritiesAction.SlipFail) {
          if (bill.imageUrl) {
            bill.slipImg = bill.imageUrl;
          } else if (bill.imageId) {
            const res: any = await BillApi.getOriginalImageBill(bill.imageId);
            if (res.data) {
              bill.slipImg = res.data;
            }
          }
        }
      }),
    );
  }

  async getAllBillInBillSummary(
    lineGroupId: string,
    lineUserId: string,
    action?: string,
  ) {
    try {
      const result = await Api.getAllBillInBillSummary(
        lineGroupId,
        lineUserId,
        action,
      );

      if (result.data && !isArrayEmpty(result.data.priorityBills)) {
        await this.getSlipImages(result.data.priorityBills);
      }
      this.billPriority = result.data || this.initBillPriority();
    } catch (error) {
      this.billPriority = this.initBillPriority();
    } finally {
    }
  }

  getAllBillInBillSummaryJs() {
    return toJS(this.billPriority);
  }

  setCurrentTab(isActivePriorityTab: boolean) {
    this.currentTab = isActivePriorityTab;
  }

  getCurrentTab() {
    return this.currentTab;
  }

  async getBillOCRStatusNew(lineGroupId: string, lineUserId: string) {
    try {
      const result = await Api.getBillOCRStatusNew(lineGroupId, lineUserId);
      this.billOCRStatusNiew = result.data;
    } catch (err: any) {
      console.log('update bill error: ', err.message);
      this.billOCRStatusNiew = undefined;
    }
  }

  getBillOCRStatusNewJS() {
    return toJS(this.billOCRStatusNiew);
  }
}

export default new BillSummaryStore();
