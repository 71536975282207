import ApiManager, { getLineToken } from 'api/ApiManager';
import { Http } from 'definitions/httpMethod';
import { Api } from 'definitions/apiName';
import {
  IBillItemMemberModel,
  IBillItemModel,
  IBillModel,
} from 'stores/BillStore.d';
import { encryptAESMessage, decryptAESJSONMessage } from '../crypto/index';
import { ServiceName } from 'definitions/constant';
import { IPaymentRequestModel } from 'stores/PayTypeStore.d';

export class BillApi {
  async getBillById(id: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${id}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async createBill(data: any) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.POST,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateBill(data: any, billId: string) {
    delete data.createdDate;
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${billId}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateStatusBill(data: any, id: string) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${id}/status`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateBillItem(
    billId: string,
    itemId: string,
    billItem: IBillItemModel,
  ) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(billItem, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${billId}/item/${itemId}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateBillItemMember(
    billId: string,
    itemId: string,
    memberId: string,
    billItemMember: IBillItemMemberModel,
  ) {
    delete billItemMember.createdDate;
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(
        billItemMember,
        ServiceName.bot,
      );
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${billId}/item/${itemId}/member/${memberId}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async updateBillItemsMembers(billId: string, billModel: IBillModel) {
    delete billModel.createdDate;

    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(billModel, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${billId}/items-members`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async getOriginalImageBill(msgId: string) {
    const token = getLineToken();
    return ApiManager.fetch({
      m: Http.GET,
      h: {
        authorization: `Bearer ${token}`,
      },
      ep: `line/content/${msgId}`,
    });
  }

  async verifyDuplicateBill(linegroupId: string, messageId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.GET,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}?lineGroupId=${linegroupId}&messageId=${messageId}`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async PayWithCash(data: IPaymentRequestModel) {
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PayWitchCash}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async payWithCashConfirm(billId: string, memberId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PayWitchCash}/${billId}/member/${memberId}/confirm`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async payWithCashReject(billId: string, memberId: string) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PayWitchCash}/${billId}/member/${memberId}/reject`,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async payWithCashByOwner(data: any, billId: string) {
    delete data.createdDate;
    const token = getLineToken();
    try {
      const dataEncrypt = await encryptAESMessage(data, ServiceName.bot);
      const result = await ApiManager.fetch({
        m: Http.PUT,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.PayWitchCash}/${billId}`,
        b: dataEncrypt,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });
      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }

  async confrimStillUsed(billId: string, handleRetry: (tag: string) => void) {
    const token = getLineToken();
    try {
      const result = await ApiManager.fetch({
        m: Http.PATCH,
        h: {
          authorization: `Bearer ${token}`,
        },
        ep: `${Api.Bills}/${billId}/stilluse`,
        handleRetry,
      });

      const resp = await decryptAESJSONMessage(result, ServiceName.bot, {
        isEncoded64: true,
      });

      return resp;
    } catch (error) {
      const resp = await decryptAESJSONMessage(error, ServiceName.bot, {
        isEncoded64: true,
      });
      throw resp;
    }
  }
}

export default new BillApi();
