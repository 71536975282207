import Api from 'api/SessionApi';
import ApiOcr from 'api/OCRApi';
import { setLineToken, getLineToken, setSessionOcr } from 'api/ApiManager';
import { exchangeKeypair, setSharedKey } from '../crypto/index';

import { isObjectEmpty, arrayBufferToString } from 'utils/Common';

import lineHelper from 'utils/LineHelper';
import { CommonError, ServiceName } from 'definitions/constant';

export async function createSession(handleRetry: (tag: string) => void) {
  let msg = '';

  try {
    const isLineToken = await getLineToken();
    if (!isLineToken) {
      const { token, dataAESEncrypted } = await exchangeKeypair(
        ServiceName.bot,
      );
      const lineToken = await lineHelper.getAccessToken();
      const result = await Api.createSession(
        lineToken,
        dataAESEncrypted,
        btoa(arrayBufferToString(token)),
        handleRetry,
      );
      setSharedKey(window.atob(result.data), ServiceName.bot);
      setLineToken(lineToken);
    }
  } catch (error: any) {
    console.log('catch on createSession => ', error);
    if (!isObjectEmpty(error.error)) {
      msg = error.error;
    } else if (
      error &&
      error.message &&
      error.message === CommonError.LineProfileNotFound
    ) {
      msg = CommonError.LineProfileNotFound;
    }
  } finally {
  }

  return msg;
}

export async function createSessionOCR() {
  let msg = '';
  try {
    const { token, dataAESEncrypted } = await exchangeKeypair(ServiceName.ocr);
    const lineToken = await lineHelper.getAccessToken();
    const result = await ApiOcr.createSessionOcr(
      lineToken,
      dataAESEncrypted,
      btoa(arrayBufferToString(token)),
    );
    setSharedKey(window.atob(result.data), ServiceName.ocr);
    setSessionOcr(result.session);
    setLineToken(lineToken);
  } catch (error: any) {
    if (!isObjectEmpty(error.error)) {
      msg = error.error;
    }
  } finally {
  }

  return msg;
}

let errorStatus = '';

export function getErrorStatus() {
  return errorStatus;
}

export function setErrorStatus(status: string) {
  errorStatus = status;
}
