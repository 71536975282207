import React, { PureComponent } from 'react';
import moment from 'moment';
import { Language } from 'definitions/constant';
import { getDateSuffix, shortMonth, shortYear, strTime } from 'utils/DateTime';
import { CurrentLanguage } from 'common/components/Translate';

export interface IDateTimeProps {
  datetime: Date | string;
  showTime?: boolean;
  noYear?: boolean;
  noSecond?: boolean;
  noSuffix?: boolean;
}

export interface IDateTimeState {}

class DateTime extends PureComponent<IDateTimeProps, IDateTimeState> {
  // state = { :  }
  render() {
    const { datetime, showTime, noSecond, noYear, noSuffix } = this.props;
    const lang = CurrentLanguage();
    const isEn = lang === Language.EN;
    const isEnWithSuffix = isEn && !noSuffix;

    const year = moment(datetime).format('YYYY');
    const month = moment(datetime).format('MM');
    const date = moment(datetime).format(isEnWithSuffix ? 'D' : 'DD');
    const dateSuffix = isEnWithSuffix ? getDateSuffix(date) : '';
    const time = strTime(datetime, noSecond);

    return (
      <>
        {datetime && (
          <>
            <span>{date}</span>
            {dateSuffix && <sup>{dateSuffix}</sup>}
            <span> {shortMonth(+month)}</span>
            {!noYear && <span> {shortYear(+year)}</span>}
            {showTime && <span>, {time}</span>}
          </>
        )}
      </>
    );
  }
}

export default DateTime;
