import { observable, toJS } from 'mobx';
import {
  IInviteFriendStore,
  IInviteFriendModel,
  IInviteFriendMemberModel,
  IImageChildenModel,
} from './InviteFriendStore.d';
import set from 'lodash/set';
import { isArrayEmpty, isObjectEmpty } from 'utils/Common';
import config from 'config';

export class InviteFriendStore implements IInviteFriendStore {
  @observable info: IInviteFriendModel;
  @observable currentParent: IInviteFriendMemberModel;
  @observable avatarImages: IImageChildenModel[];

  constructor() {
    this.resetInfo();
  }

  resetInfo() {
    this.info = { checked: [] } as any;
    this.currentParent = undefined;
    this.avatarImages = undefined;
  }

  setInfo(key: string, val: any) {
    const info = this.getInfoJs();
    set(info, key, val);
    this.info = info;
  }

  getInfoJs() {
    return toJS(this.info);
  }

  getMembersJs() {
    return toJS(this.info.members);
  }

  getFilterMembersJs() {
    return toJS(this.info.filterMembers);
  }

  getSelectMemberJs() {
    return toJS(this.info.selectMembers);
  }

  setMembersChecked(memberIds: string[]) {
    const { checked } = this.getInfoJs();
    try {
      const newChecked = [...checked];
      memberIds.map((id) => {
        newChecked.push(id);
      });
      this.setInfo('checked', Array.from(new Set(newChecked)));
      return true;
    } catch (error) {
      return false;
    }
  }

  selectMember(value: string) {
    const info = this.getInfoJs();

    let currentIndex = info.checked.indexOf(value);
    let newChecked = [...info.checked];
    let selectMembers = [];

    if (!isObjectEmpty(info) && !isArrayEmpty(info.selectMembers)) {
      selectMembers = info.selectMembers;
    }

    // let parentMember: IInviteFriendMemberModel[];
    let childenMember: IInviteFriendMemberModel[];
    let childenMembers: IInviteFriendMemberModel[];
    if (!isObjectEmpty(info) && !isArrayEmpty(info.members)) {
      // parentMember = info.members.filter((m) => m.id === value);
      childenMember = info.members.filter(
        (m) => m.id === value && m.parentMemberId,
      );
      childenMembers = info.members.filter((m) => m.parentMemberId === value);
    }

    if (info.ownerMemberId === value) {
      return;
    }

    // have not checked
    if (currentIndex === -1) {
      if (value === 'All') {
        this.selectAll('checked');
      } else {
        newChecked.push(value);

        const members = info.members;
        const member = members.find((mem) => mem.id === value);
        if (!isObjectEmpty(member)) {
          selectMembers.push(member);
          this.setInfo('selectMembers', selectMembers);
        }
        if (
          selectMembers.length ===
          members.filter((mem) => mem.id !== 'All').length
        ) {
          newChecked.unshift('All');
        }

        this.setInfo('checked', newChecked);

        // Check Child, So check parent also
        if (!isArrayEmpty(childenMember)) {
          const { parentMemberId } = childenMember[0];
          const currentParent = members.find(
            (mem) => mem.id === parentMemberId,
          );
          if (!isObjectEmpty(member)) {
            this.setMembersChecked([parentMemberId]);
            selectMembers.push(currentParent);
            selectMembers = Object.values(
              selectMembers.reduce(
                (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
                {},
              ),
            );

            this.setInfo('selectMembers', selectMembers);
            if (
              selectMembers.length ===
              members.filter((mem) => mem.id !== 'All').length
            ) {
              this.selectAll('checked');
            }
          }
        }
      }
    } else {
      if (value === 'All') {
        this.selectAll('unUhecked');
      } else {
        newChecked.splice(currentIndex, 1);
        selectMembers.splice(
          selectMembers.findIndex((mem) => mem.id === value),
          1,
        );

        currentIndex = info.checked.indexOf('All');
        if (currentIndex !== -1) {
          newChecked.splice(currentIndex, 1);
        }
        this.setInfo('selectMembers', selectMembers);
        this.setInfo('checked', newChecked);

        //UnCheck parent => uncheck childen also
        if (!isArrayEmpty(childenMembers)) {
          childenMembers.map((m) => {
            const { checked } = this.getInfoJs();
            const index = checked.indexOf(m.id);
            if (index !== -1) {
              checked.splice(index, 1);
              this.setInfo('checked', checked);
            }
          });

          const newSelectMembers = selectMembers.filter(
            (m) => m.parentMemberId !== value,
          );

          this.setInfo('selectMembers', newSelectMembers);

          const members = info.members;
          if (
            selectMembers.length ===
            members.filter((mem) => mem.id !== 'All').length
          ) {
            this.selectAll('checked');
          }
        }
      }
    }
  }

  selectAll(type: string) {
    const info = this.getInfoJs();
    if (type === 'checked') {
      const members = info.members;
      const newChecked = members.map((member) => {
        return member.id;
      });
      members.map((member, index) => {
        if (member.id === 'All') {
          members.splice(index, 1);
        }
      });
      this.setInfo('selectMembers', members);
      this.setInfo('checked', newChecked);
    } else if (info.ownerMemberId) {
      const member = info.members.find((mem) => mem.id === info.ownerMemberId);
      this.setInfo('selectMembers', [member]);
      this.setInfo('checked', [info.ownerMemberId]);
    } else {
      this.setInfo('selectMembers', []);
      this.setInfo('checked', []);
    }
  }

  setCurrentParent(data: IInviteFriendMemberModel) {
    this.currentParent = data;
  }

  getCurrentParent() {
    return toJS(this.currentParent);
  }

  setKTAvatarImages() {
    this.avatarImages = [
      {
        key: '01',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_01.png`,
        isUsed: false,
      },
      {
        key: '02',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_02.png`,
        isUsed: false,
      },
      {
        key: '03',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_03.png`,
        isUsed: false,
      },
      {
        key: '04',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_04.png`,
        isUsed: false,
      },
      {
        key: '05',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_05.png`,
        isUsed: false,
      },
      {
        key: '06',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_06.png`,
        isUsed: false,
      },
      {
        key: '07',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_07.png`,
        isUsed: false,
      },
      {
        key: '08',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_08.png`,
        isUsed: false,
      },
      {
        key: '09',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_09.png`,
        isUsed: false,
      },
      {
        key: '10',
        image: `${config.api.s3}/static/images/khunthong_avatar/kth_child_10.png`,
        isUsed: false,
      },
    ];
  }

  setUsedAvatarImage(index: number, isUsed: boolean) {
    try {
      const images = this.getKTAvatarImages();
      images[index].isUsed = isUsed;
      this.avatarImages = images;
    } catch (error) {
      return;
    }
  }

  getKTAvatarImages() {
    return toJS(this.avatarImages);
  }
}

export default new InviteFriendStore();
