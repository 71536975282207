import ConfigurationApi from 'api/ConfigurationApi';
import { isObjectEmpty } from 'utils/Common';

export interface IAbTestingModel {
  accountManagementId: string;
}

export interface IPrivateBillConfig {
  isActive: boolean;
  maxAmountPerBill: number;
  maxBillPerUser: number;
  maxDuration: number;
  maxPayerPerBill: number;
}

export interface IGoQRConfig {
  imageSizeLimit: number;
}

export interface IConfigurationModel {
  termNameEn?: string;
  termNameTh?: string;
  backgroundSlip?: string;
  billTotalLimit?: number;
  maxBillsInGroup?: number;
  abTesting?: IAbTestingModel;
  privateBill?: IPrivateBillConfig;
  goQr: IGoQRConfig;
  maxScheduleBillsInGroup?: number;
}

let config: IConfigurationModel;
let os: 'ios' | 'android' | 'web';
const defaultOS = 'web';

export async function getConfiguration() {
  let msg = '';
  try {
    if (!config) {
      const result = await ConfigurationApi.getConfiguration();
      config = result.data;
    }
  } catch (error: any) {
    if (!isObjectEmpty(error.error)) {
      msg = error.error;
    }
  }
  return msg;
}

export function setOS(val: any) {
  os = val;
}

export function getOS() {
  return os || defaultOS;
}

export function getConfig() {
  return config;
}
