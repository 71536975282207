const config = {
	env: 'uat',
	publicUrl: '',
	publicWeb: 'https://uatkhunthong.kasikornbank.com',
	api: {
		ocr: 'https://uatkhunthong.kasikornbank.com/ocr/api',
		socialbot: 'https://uatkhunthong.kasikornbank.com/api',
		s3: 'https://uatkhunthong.kasikornbank.com',
	},
	kplus: {
		url: 'https://appuat.kplus.link',
	},
	firebaseConfig: {
		apiKey: 'AIzaSyDg_CRn3AmgVfBceAeZg4qXxVaqqPtvWLY',
		authDomain: 'social-banking-uat-bed69.firebaseapp.com',
		databaseURL: 'https://social-banking-uat-bed69.firebaseio.com',
		projectId: 'social-banking-uat-bed69',
		storageBucket: 'social-banking-uat-bed69.appspot.com',
		messagingSenderId: '889414426551',
	},
	liff: {
		demo_slip: '1653842381-6GMGMVpP',
		crop_image: '1653842381-0prVr4je',
		pay_type: '1653842381-lrMaMp51',
		bill_assign: '1653842381-KwkWkPd6',
		add_account: '1653842381-JVAQAmW7',
		summary: '1653842381-Vpyrym9L',
		invite_friend: '1653842381-nxAEAoXV',
		account_management: '1653842381-0y5z5xbq',
		medal: '1653842381-RkYGYZ4M',
		donation: '1653842381-Yv7L7rg2',
		donation_info: '1653842381-wD797ODq',
		rollcall: '1653842381-mkyjyqMg',
		campaign_landing: '1653842381-alO0OAk6',
		campaign_activity: '1653842381-yJVnVJax',
		schedule_create: '1653842381-19m2mB8b',
		schedule_summary: '1653842381-bvpNpMz3',
		bill_summary: '1653842381-A6dadJGY',
		cancel_info: '1653842381-KppVpQ1x',
		multiple_payment_slip: '1653842381-zGP0PdqD',
		tutorial: '1653842381-vy9p9kbq',
		reward_info: '1653842381-JoZKZE2n',
		redeem_location_code: '1653842381-yOJBJOWX',
		referrer: '1653842381-knnmnKxN',
		referree: '1653842381-ynmRmOoz',
		create_bill_template: '1653842381-2VD4D0Jq',
		bill_center: '1653842381-4BpRpGdo',
		private_bill_summary: '1653842381-VwGqGkMz',
		init_liff_full: '1653842381-Lvjpj7dz',
		init_liff_tall: '1653842381-o7D8D2db',
		robot_login: '1653842381-gR6D6X7r',
	},
	vconsole: {
		enabled: true,
	},
	cypress: {
		enabled: true,
	},
	development: {
		allowedDesktop: false,
		allowedRobot: true,
	},
	publickeyBot: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApASIFXNq7Bmjhbtmh2Qe
  87tN/Kl/hKIJ1FdGB841n0mzXSWcfJLLCqPc0LNgHHmRLxe47WQbMCrxXoUOVK+r
  rUXwYdzQ10yVVU7ODgZW5XoaImf5NJTkgJPjFV4iW4fSqW1tsUbGwbJ3zlBmEo6F
  UfdzChMcGS0UFlUIQczDfCnZVXiTtveeeB2ikCxJj+JQGNtxwC5YHl5BpH2DGcyK
  V4DW/UvuyhfMqDwfqf/vf2cjmDyB/BqOb59xWIhdGbP789K5kQ5uvQKxypRM3A67
  G3l8KBrXyXzVwlziNGr6B6K94MyUDiC0nbgKYAszTrwrBmEUP/l6QjxI/n4QRkN/
  nQIDAQAB`,
	publickeyOcr: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4gMgWdQl/J2Kq3qH3Wpw
  L79EUnoTJvfPvMDS/5XTkGLt+UDBJ5Yz3l++w0DSBZKKXeS1Lg0zhVFp/2NYD/bs
  0SOelE8kOVJgsguB+SDVioSrXKF6YaP5P84QMHhx//MwxK8pIqKT8FbDvZYjyDDK
  /Vqt4IwO3tDpYeTKoR1QKJs27AdxLBzzMbEr+Gn/pmIPUHzRDecNlSkbEDO+YJUI
  rgSbnAFxg46xhfzjWyjdkRJLr82icV5hxJB86E/62X3kqtXBb76Tpt4wGU3DNdGB
  j4CRxQj3oxRZfYe7n/LIIYPJuggdqrdTfki3SFCN3N4OxG0BQgVHQq0/6g66W4n6
  YwIDAQAB`,
	authentication: {
		enabled: true,
		encrypt: true,
	},
	gtm: {
		id: 'GTM-KKXSH5C',
	},
	fb: {
		appId: '2428528883868863',
		version: 'v2.9',
	},
	line: {
		botBasicId: '~@944isdly',
		botBasicIdBypass: '@944isdly',
	},
	bill: {
		defaultExpiredDuration: 7,
		defaultExtendedDuration: 1,
	},
	privateBill: {
		noteExceedLimit: 200,
	},
};

export default config;
