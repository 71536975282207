import { ILogUserActionsRequestBody } from 'stores/AbTesting/AbTestingStore.d';
import { ActionKey, ActionType, ActionValue } from 'stores/AbTesting/constant';
import { observable, toJS } from 'mobx';
import {
  AbTestingConst,
  ElementId,
  RequestGetExperimentFieldName,
} from './constant';
import Api from 'api/AbTestingApi';
import { getCurrentDayOfWeek } from 'common/functions/DateCommon';

import {
  IAbTestingStore,
  IButtonElementModel,
  IExperimentModel,
  IExperimentPayloadModel,
  ILogUserActionData,
  IRequestGetExperimentPayload,
  IRequestGetExperimentPayloadContext,
  IScreenSizeModel,
  ITextElementModel,
} from './AbTestingStore.d';
import { getOS } from 'utils/Configuration';
import { getConfig } from 'utils/Configuration';
import { generateUUID } from 'utils/Common';

const isFixedToExperiment2 = true;

export class AbTestingStore implements IAbTestingStore {
  @observable sessionId: string;
  @observable experimentModel: IExperimentModel;

  async lazyGetExperimentById(id: string, lineUserId: string) {
    if (isFixedToExperiment2) {
      this.experimentModel = this.createSchemaTreatment2();
      return;
    }

    try {
      if (!this.sessionId) {
        this.sessionId = generateUUID();

        const payload = {
          userId: lineUserId,
          sessionId: this.sessionId,
          clientTimestamp: new Date().toISOString(),
          context: [
            {
              field: RequestGetExperimentFieldName.Os,
              value: getOS(),
            },
            {
              field: RequestGetExperimentFieldName.UserAgent,
              value: navigator.userAgent.toLowerCase(),
            },
            {
              field: RequestGetExperimentFieldName.Day,
              value: getCurrentDayOfWeek(),
            },
            {
              field: RequestGetExperimentFieldName.PhoneSize,
              value: {
                w: window.screen.width,
                h: window.screen.height,
              } as IScreenSizeModel,
            },
          ] as IRequestGetExperimentPayloadContext[],
        } as IRequestGetExperimentPayload;

        if (Boolean(id)) {
          const result = await Api.getExperimentById(
            id,
            AbTestingConst.GetVariantTimeout,
            payload,
          );

          if (Boolean(result) && Boolean(result.data)) {
            this.experimentModel = result.data;
            console.log('result experimentModel', result.data);
          } else {
            // Set a default variant, if data is invalid
            this.experimentModel = this.createDefaultVariant();
          }
        } else {
          console.log('No experiment ID');
          // Set a default variant, if data is invalid
          this.experimentModel = this.createDefaultVariant();
        }
      }
    } catch (error) {
      // Set default, if either error or timeout
      this.experimentModel = this.createDefaultVariant();
    } finally {
    }
  }

  getExperimentJs() {
    return toJS(this.experimentModel);
  }

  createDefaultVariant() {
    const experimentModel = {
      payload: [] as IExperimentPayloadModel[],
    } as IExperimentModel;
    return experimentModel;
  }

  getElementValueByIdJs(id: string) {
    const element =
      this.experimentModel &&
      this.experimentModel.payload &&
      this.experimentModel.payload.find((elem) => elem.elementId === id);
    return toJS(element && element.elementVal);
  }

  // this API on A/B testing no need to wait and check error
  async logUserActions(
    data = {
      userId: '',
      pageUrl: '',
      pathUrl: '',
      actionType: '',
      elementId: '',
      actions: [],
      context: [],
      bankCode: '',
      errorCode: '',
      errorMessage: '',
    } as ILogUserActionData,
  ) {
    try {
      if (
        this.sessionId &&
        this.experimentModel &&
        this.experimentModel.expVer
      ) {
        console.log('logUserActions:', data.actionType);
        const { userId, pageUrl } = data;
        const actionType = data.actionType;
        let actions = data.actions;
        const config = getConfig();
        const expId =
          Boolean(config) && Boolean(config.abTesting)
            ? config.abTesting.accountManagementId
            : '';
        const expVer = this.experimentModel.expVer;

        const body = {
          userId: userId,
          sessionId: this.sessionId,
          variantName: this.experimentModel.variantName,
          pageUrl: pageUrl,
          clientTimestamp: new Date().toISOString(),
        } as ILogUserActionsRequestBody;

        if (actionType) {
          const elementId = data.elementId;
          const pathUrl = data.pathUrl;
          const bankCode = data.bankCode;
          const errorCode = data.errorCode;
          const errorMessage = data.errorMessage;

          switch (actionType) {
            case ActionType.Start:
              actions = [
                {
                  field: ActionKey.State,
                  value: ActionValue.Enter,
                },
                {
                  field: ActionKey.PathUrl,
                  value: pathUrl,
                },
              ];
              body.context = data && data.context;
              break;
            case ActionType.Enter:
              actions = [
                {
                  field: ActionKey.State,
                  value: ActionValue.Enter,
                },
                {
                  field: ActionKey.PathUrl,
                  value: pathUrl,
                },
              ];
              break;
            case ActionType.Next:
              actions = [
                {
                  field: elementId,
                  value: ActionValue.Clicked,
                },
                {
                  field: ActionKey.State,
                  value: ActionValue.Next,
                },
                {
                  field: ActionKey.PathUrl,
                  value: pathUrl,
                },
              ];
              break;
            case ActionType.Leave:
              actions = [
                {
                  field: elementId,
                  value: ActionValue.Clicked,
                },
                {
                  field: ActionKey.State,
                  value: ActionValue.Leave,
                },
                {
                  field: ActionKey.PathUrl,
                  value: pathUrl,
                },
              ];
              break;
            case ActionType.Complete:
              actions = [
                {
                  field: pathUrl,
                  value: ActionValue.Success,
                },
                {
                  field: ActionKey.BankCode,
                  value: Boolean(bankCode) ? bankCode : ActionValue.PromptPay,
                },
              ];
              break;
            case ActionType.Error:
              actions = [
                {
                  field: elementId,
                  value: ActionValue.Clicked,
                },
                {
                  field: ActionKey.State,
                  value: ActionValue.Error,
                },
                {
                  field: ActionKey.PathUrl,
                  value: pathUrl,
                },
                {
                  field: ActionKey.ErrorCode,
                  value: Boolean(errorCode) ? errorCode : 'unknown',
                },
                {
                  field: ActionKey.ErrorMessage,
                  value: Boolean(errorMessage) ? errorMessage : 'unknown',
                },
              ];
              break;
          }
        }

        body.actions = actions;
        console.log(data.actionType, JSON.stringify(body));

        const result = await Api.logUserActions(expId, expVer, body);

        if (Boolean(result) && Boolean(result.data)) {
          console.log('result.data', result.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  getAbTestingDeeplink() {
    const experimentModel = this.experimentModel;
    const config = getConfig();

    if (this.sessionId && this.experimentModel && this.experimentModel.expVer) {
      return {
        variantName: Boolean(experimentModel)
          ? experimentModel.variantName
          : '',
        expVer: Boolean(experimentModel) ? experimentModel.expVer : '',
        expId:
          Boolean(config) && Boolean(config.abTesting)
            ? config.abTesting.accountManagementId
            : '',
        sessionId: this.sessionId,
        clientTimestamp: new Date().toISOString(),
      };
    } else {
      return null;
    }
  }

  //#region Mock up functions

  createMockupSchemaControl() {
    const experimentModel = {
      payload: [] as IExperimentPayloadModel[],
    } as IExperimentModel;

    return experimentModel;
  }

  createMockupSchemaTreatment1() {
    const experimentModel = {
      payload: [] as IExperimentPayloadModel[],
    } as IExperimentModel;

    experimentModel.payload.push({
      elementId: ElementId.KplusAccount,
      elementVal: {
        //bankLogoUrl: 'static/images/khunthong.png',
        bankLogoUrl:
          'static/images/schedule/emoji_withbg/apple_withbg_20210622.png',
        backgroundColor: '#00A03E',
        borderColor: '#00A03E',
        buttonText: {
          title: {
            text: 'เชื่อมต่อบัญชีกสิกรไทย',
            color: '#FFFFFF',
          } as ITextElementModel,
          description: {
            text: 'เฉพาะลูกค้าที่มี K PLUS',
            color: '#FFFFFFB2',
          } as ITextElementModel,
        },
        navIconColor: '#FFFFFF',
      } as IButtonElementModel,
    });

    return experimentModel;
  }

  createSchemaTreatment2() {
    const experimentModel = {
      payload: [] as IExperimentPayloadModel[],
    } as IExperimentModel;

    experimentModel.payload.push({
      elementId: ElementId.ButtonOrder,
      elementVal: [ElementId.ReceivingAccount, ElementId.KplusAccount],
    });

    return experimentModel;
  }

  createMockupSchemaTreatmentTest() {
    const experimentModel = {
      payload: [] as IExperimentPayloadModel[],
    } as IExperimentModel;

    experimentModel.payload.push({
      elementId: ElementId.KplusAccount,
      elementVal: {
        bankLogoUrl: 'static/images/khunthong.png',
        backgroundColor: 'red',
        borderColor: 'blue',
        buttonText: {
          title: {
            text: 'เชื่อมต่อบัญชีกสิกรไทย',
            color: 'green',
          } as ITextElementModel,
          description: {
            text: 'เฉพาะลูกค้าที่มี K PLUS',
            color: 'white',
          } as ITextElementModel,
        },
        navIconColor: 'yellow',
      } as IButtonElementModel,
    });

    experimentModel.payload.push({
      elementId: ElementId.ReceivingAccount,
      elementVal: {
        bankLogoUrl: 'static/images/khunthong.png',
        backgroundColor: 'red',
        borderColor: 'blue',
        buttonText: {
          title: {
            text: 'ทดสอบนะ',
            color: 'green',
          } as ITextElementModel,
          description: {
            text: 'เปลี่ยนเหอะ',
            color: 'white',
          } as ITextElementModel,
        },
      } as IButtonElementModel,
    });

    experimentModel.payload.push({
      elementId: ElementId.ButtonOrder,
      elementVal: [ElementId.ReceivingAccount, ElementId.KplusAccount],
    });

    return experimentModel;
  }
  //#endregion **** Mock up functions - End ****

  //#region
  // **** Warning **** This function is generic and NOT been tested properly.
  // **** DON'T use it if doesn't have unit testing
  // **** This is just work !
  replaceLang(model: any, lang: string) {
    const localizedModel = { ...model };

    // Max depth in JSON
    const maxDepth = 10;
    let depth = 0;

    // Use queue instead of recursive function
    let objectQueue = [];
    objectQueue.push(localizedModel);

    // Breadth-first search, but consider the last node first
    while (objectQueue.length > 0 && depth++ < maxDepth) {
      const len = objectQueue.length;
      const last = objectQueue[len - 1];
      objectQueue.pop();

      if (Boolean(last)) {
        if (last.constructor === Object) {
          const keys = Object.keys(last);
          Boolean(keys) &&
            keys.forEach((k) => {
              // This is lang node
              if (
                Boolean(last[k].text) &&
                Boolean(last[k].lang) &&
                Boolean(last[k].lang[lang])
              ) {
                // Replace text if found the target lang
                last[k].text = last[k].lang[lang];
              } else if (last[k]) {
                objectQueue.push(last[k]);
              }
            });
        } else if (Array.isArray(last)) {
          last.forEach((elem) => {
            objectQueue.push(elem);
          });
        } else {
          // Leaf case
        }
      }
    }

    return localizedModel;
  }

  convertExperimentWithLocalizedText(model: any) {
    //return this.replaceLang(model, CurrentLanguage());
    //return this.replaceLang(model, 'en');
    //return this.replaceLang(model, 'kr');
  }
  //#endregion **** Warning End ****
}

export default new AbTestingStore();
