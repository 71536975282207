import React, { PureComponent } from 'react';
import css from './error.module.scss';
import ErrorDefaultImg from '../../../images/IconKhunthong/iconClose.png';
import { setErrorStatus } from 'utils/Session';
import cs from 'classnames';
import BottomBtn, { IBottonProp } from '../BottomBtn';

export interface IErrorPageProps {
  message?: string;
  messageHTML?: any;
  message2?: string;
  errorCode?: string;
  imagePath?: string;
  imageComponent?: any;
  errorStyle?: any;
  pageStyle?: any;
  isHtml?: boolean;
  deviceInfo?: string;
  mainBtn?: IBottonProp;
}

class ErrorPage extends PureComponent<IErrorPageProps> {
  render() {
    const {
      message,
      messageHTML,
      message2,
      imagePath,
      imageComponent,
      errorCode,
      errorStyle,
      pageStyle,
      isHtml,
      deviceInfo,
      mainBtn,
    } = this.props;
    setErrorStatus('error');

    const errorMsgClass = cs({
      [css.errmsg]: true,
      [errorStyle]: true,
    });

    const errorClass = cs({
      [css.error]: true,
      [pageStyle]: true,
    });

    return (
      <div className={errorClass}>
        {mainBtn && <BottomBtn classes={css.bottomButton} mainBtn={mainBtn} />}

        <div className={css.content}>
          {imageComponent && imageComponent}
          {!imageComponent && (
            <img
              src={imagePath && imagePath !== '' ? imagePath : ErrorDefaultImg}
            />
          )}
          <br />
          {errorCode && (
            <div className={css.errcode}>
              <span>{errorCode}</span>
            </div>
          )}
          <div className={errorMsgClass}>
            {!isHtml && <span>{message}</span>}
            {isHtml && (
              <span dangerouslySetInnerHTML={{ __html: message }}></span>
            )}
            {messageHTML && messageHTML}
          </div>

          <div className={css.deviceInfo}>
            <span>{deviceInfo}</span>
          </div>

          {message2 && (
            <div className={errorMsgClass}>
              <span>{message2}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ErrorPage;
