import { IFrontendLogModel, IFrontendLogStore } from './FrontendLogStore.d';
import Api from 'api/FrontendLogApi';

export class FrontendLogStore implements IFrontendLogStore {
  async writeFrontendLog(data: IFrontendLogModel) {
    try {
      console.log('writeFrontendLog', data);
      await Api.LogFrontend(data);
    } catch (error) {
      console.log('writeFrontendLog', error);
    } finally {
    }
  }
}

export default new FrontendLogStore();
