import { observable, toJS } from 'mobx';
import {
  IDonateDestinationModel,
  IDonateDestinationStore,
} from './DonateDestination.d';
import DonateDestinationApi from 'api/DonateDestinationApi';

export class DonateDestinationStore implements IDonateDestinationStore {
  @observable destination: IDonateDestinationModel;
  @observable destinations: IDonateDestinationModel[];
  @observable destinationsFlex: IDonateDestinationModel[];

  constructor() {
    this.destination = this.initDestinationSchema();
    this.destinations = [this.initDestinationSchema()];
    this.destinationsFlex = [this.initDestinationSchema()];
  }

  initDestinationSchema() {
    return {
      id: '',
      type: '',
      name: '',
      shortName: '',
      eDonation: '',
      bankCode: '',
      bankName: '',
      accountNo: '',
      accountName: '',
      logoImageUrl: '',
      logoXlImageUrl: '',
      qrImageUrl: '',
      flexImageUrl:
        'https://uatkhunthong.kasikornbank.com/static/images/donation/flex/openning_flex.jpg',
      posterImageUrl: '',
      note: '',
      detail: '',
      taxDeduction: undefined,
      link: '',
      isActive: undefined,
      isFlex: undefined,
      flexOrder: undefined,
      createdDate: undefined,
      updatedDate: undefined,
    };
  }

  resetDestination() {
    this.destination = this.initDestinationSchema();
  }

  resetDestinations() {
    this.destinations = [this.initDestinationSchema()];
  }

  getDestinationJs() {
    return toJS(this.destination);
  }

  getDestinationsJs() {
    return toJS(this.destinations);
  }

  getDestinationsFlexJs() {
    return toJS(this.destinationsFlex);
  }

  resetInfo() {
    this.initDestinationSchema();
  }

  async getDestinationById(id: string) {
    try {
      if (this.destination.id !== id) {
        this.resetDestination();
        const response = await DonateDestinationApi.getDestination(id);
        this.destination = response.data || this.initDestinationSchema();
      }
    } catch (error) {
      this.destination = this.initDestinationSchema();
    }
  }

  async getDestinationList() {
    try {
      if (this.destinations.length <= 1) {
        const response = await DonateDestinationApi.getDestinations();
        this.destinations = response.data || [];
      }
    } catch (error) {
      this.destinations = [];
    }
  }

  async getDestinationFlex() {
    try {
      if (this.destinationsFlex.length <= 1) {
        const response = await DonateDestinationApi.getDestinationFlex();
        this.destinationsFlex = response.data || [];
      }
    } catch (error) {
      this.destinationsFlex = [];
    }
  }
}

export default new DonateDestinationStore();
