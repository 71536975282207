/* tslint:disable */
import storage from 'store';
import { getConfiguration, setOS } from 'utils/Configuration';
import config from 'config';
import { isCypressMode } from 'utils/Cypress';
import {
  Environment,
  MinimumLineVersion,
  MobileOS,
} from 'definitions/constant';
import { history } from './History';
import { PageName } from 'definitions/pageName';
import { getOSAndVersion } from './Common';

const { allowedDesktop } = config.development;
let isInit = false;
let profile: LineProfile = {};

export class LineProfile {
  userId?: string;
  displayName?: string;
  pictureUrl?: string;
  statusMessage?: string;

  constructor(_userId) {
    this.userId = _userId;
  }
}

export class ILineContext {
  type?: string;
  utouId?: string;
  userId?: string;
  viewType?: string;
  accessTokenHash?: string;
}

export interface IShareTargetPickerStatus {
  isShared: boolean;
  status: string; // success | cancel | undefined
  lineData?: any; // Data from line
}

class LineHelper {
  async init(liffId) {
    return new Promise(async (resolve, reject) => {
      if (!isInit) {
        (window as any).liff.init(
          { liffId },
          async () => {
            isInit = true;
            if (
              !(
                (window as any).liff.isInClient() ||
                isCypressMode ||
                allowedDesktop
              )
            ) {
              this.handlePageError('init');
            }
            await this.initConfiguration();
            resolve('');
          },
          (err) => {
            console.log('Fail to init LIFF, please run inside LINE only');
            Boolean(err) && console.log(err.code, err.message);
            //reject(err);
            this.handlePageError('swap_internet');
          },
        );
      } else {
        await this.initConfiguration();
        resolve('');
      }
    });
  }

  async initConfiguration() {
    await getConfiguration();
    const os = await this.getOS();
    setOS(os);
  }

  async getProfile(): Promise<LineProfile> {
    return new Promise((resolve, reject) => {
      if (config.env === Environment.LOCAL) {
        const isAutomate = storage.get('is_automate');
        resolve({
          userId: isAutomate
            ? 'MOCKLineIDForTestAutomate'
            : 'U07bb54f2b6d16e4b26e6442021cb9e5a',
          displayName: isAutomate ? '' : 'Anna',
          pictureUrl: isAutomate
            ? 'https://azecomsa99.blob.core.windows.net/develop/undefined/20190309/2019030906131170583.png'
            : 'https://i.pinimg.com/474x/33/b8/69/33b869f90619e81763dbf1fccc896d8d--lion-logo-modern-logo.jpg',
          statusMessage: '',
        });
      } else if (!isInit) {
        reject(new Error('LIFF_NOT_INIT'));
      } else if (!profile.userId) {
        if ((window as any).liff.isInClient()) {
          (window as any).liff
            .getProfile()
            .then((pf) => {
              profile = pf;
              resolve(profile);
            })
            .catch((err) => {
              console.log('Catch when Line getProfile', err);
              reject(err);
            });
        } else {
          if (isCypressMode || allowedDesktop) {
            console.log('liff.isLoggedIn()', (window as any).liff.isLoggedIn());
            if ((window as any).liff.isLoggedIn()) {
              (window as any).liff
                .getProfile()
                .then((pf) => {
                  profile = pf;
                  resolve(profile);
                })
                .catch((err) => {
                  console.log('liff', (window as any).liff);
                  console.log('Catch when Line getProfile', err);
                  reject(err);
                });
            } else {
              (window as any).liff.login();
            }
          } else {
            // reject();
            this.handlePageError('getprofile');
          }
        }
      } else {
        resolve(profile);
      }
    });
  }

  getPageName = () => {
    const href = window.location.href;
    let pageName = '';

    try {
      if (href) {
        const token = href.split('/');
        if (token && token.length > 0) {
          const last = token[token.length - 1];
          if (last) {
            const cleanLast = last.split('?');
            if (cleanLast && cleanLast.length > 0) {
              pageName = cleanLast[0];
            }
          }
        }
      }
    } catch (err) {
      console.log('getPageNameError', err);
    }

    return pageName;
  };

  handlePageError = (tag: string) => {
    const { os, version } = getOSAndVersion();
    const lineVersion = (window as any).liff.getLineVersion();
    const pageName = this.getPageName();

    const isMobile = os === MobileOS.Android || os === MobileOS.IOS;

    const params = [
      `os=${os}`,
      `os-version=${version}`,
      `line-version=${lineVersion}`,
      `page-name=${pageName}`,
      `tag=${tag}`,
    ];

    const userAgentParms = params.join('&');

    if (isMobile && 'swap_internet' === tag) {
      history.replace(`${PageName.Error}?code=swap_internet&${userAgentParms}`);
    } else if (isMobile && !this.validateLineVersion(MinimumLineVersion)) {
      history.replace(`${PageName.Error}?code=update_line&${userAgentParms}`);
    } else if (isMobile) {
      history.replace(
        `${PageName.Error}?code=mobile_not_support&${userAgentParms}`,
      );
    } else {
      console.log('env ', config.env);
      if (config.env !== Environment.LOCAL) {
        history.replace(`${PageName.Error}?code=406&${userAgentParms}`);
      }
    }
  };

  getPrettyLineVersion = (lineVersion: string) => {
    let major;
    let minor;
    let build;

    if (lineVersion) {
      const lineVersionToken = lineVersion.split('.');
      major = lineVersionToken.length >= 1 ? Number(lineVersionToken[0]) : 0;
      minor = lineVersionToken.length >= 2 ? Number(lineVersionToken[1]) : 0;
      build = lineVersionToken.length >= 3 ? Number(lineVersionToken[2]) : 0;
    }

    return {
      major: major ? major : 0,
      minor: minor ? minor : 0,
      build: build ? build : 0,
    };
  };

  validateLineVersion = (minVersion: string) => {
    const lineVersion = (window as any).liff.getLineVersion();

    const linePrettyVersion = this.getPrettyLineVersion(lineVersion);
    const minPrettyVersion = this.getPrettyLineVersion(minVersion);

    if (linePrettyVersion.major < minPrettyVersion.major) {
      return false;
    } else if (linePrettyVersion.major === minPrettyVersion.major) {
      if (linePrettyVersion.minor < minPrettyVersion.minor) {
        return false;
      } else if (linePrettyVersion.minor === minPrettyVersion.minor) {
        if (linePrettyVersion.build < minPrettyVersion.build) {
          return false;
        }
      }
    }

    return true;
  };

  closeWindow() {
    (window as any).liff.closeWindow();
  }

  openWindow(url, external) {
    (window as any).liff.openWindow({ url, external });
  }

  async getAccessToken() {
    return await (window as any).liff.getAccessToken();
  }

  async sendMessages(messages) {
    let result = false;
    const messagesToSend = Array.isArray(messages) ? messages : [messages];
    (window as any).liff
      .sendMessages(messagesToSend)
      .then(() => {
        result = true;
      })
      .catch((err) => {
        result = false;
      });
    return result;
  }

  async getContext() {
    return await (window as any).liff.getContext();
  }

  // Support line app v. 10.3 up
  async shareTargetPicker(messages: any) {
    const targetPickerPromise = new Promise<IShareTargetPickerStatus>(
      (resolve, reject) => {
        const messagesToSend = Array.isArray(messages) ? messages : [messages];

        const successObj: IShareTargetPickerStatus = {
          isShared: false,
          status: '',
        };

        (window as any).liff
          .shareTargetPicker(messagesToSend)
          .then((res) => {
            console.log('share target picker res =>', res);
            if (res) {
              successObj.isShared = true;
              successObj.status = 'success';
              successObj.lineData = res;
              resolve(successObj);
            } else {
              const [majorVer, minorVer] = (
                (window as any).liff.getLineVersion() || ''
              ).split('.');
              if (parseInt(majorVer) === 10 && parseInt(minorVer) < 11) {
                console.log('LINE 10.3.0 - 10.10.0 => Send undefined');
                successObj.isShared = false;
                successObj.status = 'undefined';
                resolve(successObj);
              } else {
                console.log('LINE 10.11.0 => Send cancel');
                successObj.isShared = false;
                successObj.status = 'canceled';
                resolve(successObj);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      },
    );

    return await targetPickerPromise;
  }

  async getOS() {
    return await (window as any).liff.getOS();
  }

  async isApiAvailable(api?: string) {
    return await (window as any).liff.isApiAvailable(
      api || 'shareTargetPicker',
    );
  }
}

export const lineHelper = new LineHelper();
export default lineHelper;
